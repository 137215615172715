import * as React from 'react';
import { Loading } from '../Loading/Loading';
import { FComponent } from '../../../types/common';
import { classNames, setCSSVar } from '../../../utils';

type ButtonProps = {
  secondary?: boolean; // default is primary
  tertiary?: boolean;
  text?: boolean;
  icon?: JSX.Element;
  responsive?: boolean; // default is non-responsive
  disabled?: boolean;
  isWaiting?: boolean;
  type?: 'button' | 'reset' | 'submit';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
  postfix?: boolean;
};

const Button: FComponent<ButtonProps> = ({
  className,
  secondary,
  tertiary,
  text,
  icon,
  responsive,
  onClick,
  disabled,
  isWaiting = false,
  type = 'button',
  children,
  ariaLabel,
  postfix = false
}) => {
  // Responsiveness is handled in scss with a var state switch
  const style = responsive ? undefined : setCSSVar('--non-responsive', 'flex');
  let buttonClass = getButtonClass(secondary, tertiary, text);

  if (!children && icon) {
    buttonClass += '-icon-only';
  }

  return (
    <button
      className={classNames(className, buttonClass, postfix ? 'postfix' : '')}
      style={style}
      type={type}
      onClick={onClick}
      disabled={disabled || isWaiting}
      aria-label={ariaLabel}>
      {postfix ? (
        <>
          {children ? <span>{isWaiting ? <Loading /> : children}</span> : null}
          {icon}
        </>
      ) : (
        <>
          {icon ? <div className="button-icon">{icon}</div> : null}
          {children ? <span>{isWaiting ? <Loading /> : children}</span> : null}
        </>
      )}
    </button>
  );
};

const getButtonClass = (secondary: boolean, tertiary: boolean, text: boolean) => {
  if (secondary) {
    return 'secondary-button';
  } else if (tertiary) {
    return 'tertiary-button';
  } else if (text) {
    return 'text-button';
  } else {
    return 'primary-button';
  }
};

export { Button };
