import * as React from 'react';
import Appsignal from '@appsignal/javascript';

export type Props = {
  instance: Appsignal;
  action?: string;
  children: React.ReactNode;
  fallback?: () => JSX.Element;
  tags?: { [key: string]: string };
};

export type State = {
  error?: Error;
};

export class ErrorBoundary extends React.Component<Props, State> {
  state: { error: Error } = { error: undefined };

  static defaultProps = {
    action: ''
  };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error): void {
    const { instance: appsignal, tags } = this.props;
    let { action } = this.props;
    const span = appsignal.createSpan();

    // After extensively hunting these down unsuccessfully, we're just aggregating them into one pile.
    // Until we find a reproducible case, there's nothing more we can do.
    if (
      ['ProfileCard', 'Profile'].includes(action) &&
      (error.message.includes("Failed to execute 'insertBefore' on 'Node'") ||
        error.message.includes("Failed to execute 'removeChild' on 'Node'") ||
        error.message.includes('The object can not be found here'))
    ) {
      tags['component'] = action;
      action = 'DOM';
    }

    span.setError(error).setTags({
      framework: 'React',
      source: 'boundary',
      locale: navigator.language || 'unknown',
      ...tags
    });

    if (action && action !== '') {
      span.setAction(action);
    }
    appsignal.send(span);

    if (!this.state.error) this.setState({ error });
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      return this.props.fallback ? this.props.fallback() : null;
    }

    return this.props.children;
  }
}
