import * as React from 'react';
import { useTimerSwitch } from '../../hooks/useTimerSwitch';
import { classNames, getRandomNumberInRange } from '../../utils';
import { Reaptcha } from './Reaptcha';
import { useReaptcha } from '../../hooks/useReaptcha';
import AccessibilityIcon from '../common/Icons/AccessibilityIcon';
import ArrowRightIcon from '../common/Icons/ArrowRightIcon';
import RefreshIcon from '../common/Icons/RefreshIcon';
import { useToggle } from '../../hooks/useToggle';
import { useLogger } from '../../hooks/useLogger';
import type { FComponent } from '../../types/common';
import RobotImg from '../../../assets/images/captcha_robot.png';
import { buildUrl, client } from '../../lib/api/index';
import { api } from '../../lib/api';
require('eventlistener-polyfill');

export type CaptchaToken =
  | {
      'g-recaptcha-response': string;
    }
  | {
      'slider-captcha-token': string;
    };

export type VerifyCaptchaResponse = {
  token: string;
};

type SliderState = {
  originX: number;
  originY: number;
  moveX: number;
  trail: number[];
  isMouseDown: boolean;
  errorCount: number;
  targetX: number;
  targetY: number;
  img: HTMLImageElement;
  initialized: boolean;
};

const OPTIONS = {
  puzzlePieceWidth: 42,
  puzzlePieceRadius: 9,
  offsetTolerance: 13,
  effectivePuzzlePieceWidth: 42 + 9 * 2 + 3,
  width: 280,
  height: 155,
  maxErrors: 3
};

const getResetPositions = (): Partial<SliderState> => ({
  originX: 0,
  originY: 0,
  moveX: 0,
  trail: [],
  isMouseDown: false,
  errorCount: 0
});

const getInitialSliderState = () => ({
  targetX: 0,
  targetY: 0,
  img: null as HTMLImageElement | null,
  initialized: false,
  ...getResetPositions()
});

const getRandomImage = () => `/captcha/pic${Math.round(Math.random() * 50)}.jpg`;

// Draws a jigzaw puzzle piece (as the piece or a missing piece) on an existing canvas
const drawImage = (
  context: CanvasRenderingContext2D,
  operation: 'fill' | 'clip',
  x: number,
  y: number
) => {
  const { puzzlePieceWidth: l, puzzlePieceRadius: r } = OPTIONS;
  context.beginPath();
  context.moveTo(x, y);
  context.arc(x + l / 2, y - r + 2, r, 0.72 * Math.PI, 2.26 * Math.PI);
  context.lineTo(x + l, y);
  context.arc(x + l + r - 2, y + l / 2, r, 1.21 * Math.PI, 2.78 * Math.PI);
  context.lineTo(x + l, y + l);
  context.lineTo(x, y + l);
  context.arc(x + r - 2, y + l / 2, r + 0.4, 2.76 * Math.PI, 1.24 * Math.PI, true);
  context.lineTo(x, y);
  context.lineWidth = 2;
  context.fillStyle = 'rgba(255, 255, 255, 1)';
  context.strokeStyle = 'rgba(255, 255, 255, 0.7)';
  context.stroke();
  context[operation]();
  context.globalCompositeOperation = 'destination-over';
};

// Sets up Image objects to handle the drawing of board and missing piece when an image is set as the source
const initializeImage = (
  canvasContext: CanvasRenderingContext2D,
  puzzlePieceContext: CanvasRenderingContext2D,
  puzzlePieceRef: React.MutableRefObject<HTMLCanvasElement>,
  setState: React.Dispatch<React.SetStateAction<SliderState>>
) => {
  const img = new Image();
  let loadCount = 0;

  img.onload = () => {
    const targetX = getRandomNumberInRange(
      OPTIONS.effectivePuzzlePieceWidth + 10,
      OPTIONS.width - (OPTIONS.effectivePuzzlePieceWidth + 10)
    );
    const targetY = getRandomNumberInRange(
      10 + OPTIONS.puzzlePieceRadius * 2,
      OPTIONS.height - (OPTIONS.effectivePuzzlePieceWidth + 10)
    );
    drawImage(canvasContext, 'fill', targetX, targetY);
    drawImage(puzzlePieceContext, 'clip', targetX, targetY);

    canvasContext.drawImage(img, 0, 0, OPTIONS.width - 2, OPTIONS.height);
    puzzlePieceContext.drawImage(img, 0, 0, OPTIONS.width - 2, OPTIONS.height);
    const yPosition = targetY - OPTIONS.puzzlePieceRadius * 2 - 1;
    const ImageData = puzzlePieceContext.getImageData(
      targetX - 3,
      yPosition,
      OPTIONS.effectivePuzzlePieceWidth,
      OPTIONS.effectivePuzzlePieceWidth
    );
    puzzlePieceRef.current.width = OPTIONS.effectivePuzzlePieceWidth;
    puzzlePieceContext.putImageData(ImageData, 0, yPosition + 1);
    setState(prev => ({ ...prev, targetX, targetY }));
  };

  img.onerror = () => {
    loadCount++;
    if (loadCount >= 3) {
      setState(prev => ({ ...prev, errorCount: OPTIONS.maxErrors }));
      return;
    }
  };

  loadCount = 0;
  img.src = getRandomImage();
  setState(prev => ({ ...prev, img }));
};

const verifyCaptcha = (path: number[]): Promise<VerifyCaptchaResponse> =>
  client({
    method: 'POST',
    endpoint: buildUrl(api.unauthenticated.verifyCaptcha[0]),
    data: { datas: JSON.stringify(path) },
    responseContentType: 'application/json'
  });

// Check for correctness locally then check for bots on server.
const verifySliderCaptcha = async (moveX: number, trail: number[], targetX: number) => {
  if (Math.abs(moveX - targetX) >= OPTIONS.offsetTolerance) {
    throw new Error();
  }
  const verified = await verifyCaptcha(trail);
  return verified;
};

type SliderCaptchaProps = {
  onSuccess: (token: CaptchaToken) => void;
  onFailure: (message: string) => void;
  gcaptchaKey: string;
};

const SliderCaptcha: FComponent<SliderCaptchaProps> = ({
  onSuccess,
  onFailure,
  gcaptchaKey
}) => {
  const log = useLogger('captcha');
  const canvasRef = React.useRef<HTMLCanvasElement>();
  const puzzlePieceRef = React.useRef<HTMLCanvasElement>();
  const knobRef = React.useRef<HTMLDivElement>();
  const [hideSliderCaptcha, toggleSliderCaptcha] = useToggle(false);
  const [state, setState] = React.useState(getInitialSliderState());
  const { isOn: showErrorMessage, turnOn: toggleErrorMessage } = useTimerSwitch();
  const { captchaRef, reaptchaProps } = useReaptcha();

  const handleSuccess = React.useCallback(
    (token: CaptchaToken) => {
      toggleSliderCaptcha();
      onSuccess(token);
    },
    [onSuccess, toggleSliderCaptcha]
  );

  const switchToGoogleCaptcha = React.useCallback(() => {
    captchaRef.current.renderExplicitly();
    toggleSliderCaptcha();
  }, [captchaRef, toggleSliderCaptcha]);

  const handleFailure = React.useCallback(() => {
    if (state.errorCount + 1 >= OPTIONS.maxErrors) {
      // When we reach the error limit, we fall back to google captcha
      switchToGoogleCaptcha();
    } else {
      setState(prev => ({
        ...prev,
        ...getResetPositions(),
        errorCount: prev.errorCount + 1
      }));
    }
  }, [state.errorCount, switchToGoogleCaptcha]);

  // For manual image reset and reset after a failure
  const reload = React.useCallback(() => {
    if (!canvasRef.current || !puzzlePieceRef.current) {
      switchToGoogleCaptcha();
    } else {
      const canvasContext = canvasRef.current.getContext('2d');
      const puzzlePieceContext = puzzlePieceRef.current.getContext('2d');
      canvasContext.clearRect(0, 0, OPTIONS.width, OPTIONS.height);
      puzzlePieceContext.clearRect(0, 0, OPTIONS.width, OPTIONS.height);
      puzzlePieceRef.current.width = OPTIONS.width;
      state.img.src = getRandomImage();
    }
  }, [state.img, switchToGoogleCaptcha]);

  React.useEffect(() => {
    if (knobRef.current) {
      const handleDragStart = (event: TouchEvent | MouseEvent) => {
        let originX: number;
        let originY: number;
        if ('clientX' in event) {
          originX = event.clientX;
          originY = event.clientY;
        } else {
          originX = event.touches[0].clientX;
          originY = event.touches[0].clientY;
        }
        setState(prev => ({ ...prev, originX, originY, trail: [], isMouseDown: true }));
      };
      const knob = knobRef.current;
      knob.addEventListener('mousedown', handleDragStart, { passive: true });
      knob.addEventListener('touchstart', handleDragStart, { passive: true });
      return () => {
        knob.removeEventListener('mousedown', handleDragStart);
        knob.removeEventListener('touchstart', handleDragStart);
      };
    }
  }, []);

  React.useEffect(() => {
    const handleDragEnd = (event: TouchEvent | MouseEvent) => {
      if (!state.isMouseDown) return false;
      setState(prev => ({ ...prev, isMouseDown: false }));
      const currentX =
        'clientX' in event ? event.clientX : event.changedTouches[0].clientX;
      if (currentX === state.originX) return false;
      verifySliderCaptcha(state.moveX, state.trail, state.targetX).then(
        result => handleSuccess({ 'slider-captcha-token': result.token }),
        () => handleFailure()
      );
    };
    document.addEventListener('mouseup', handleDragEnd, { passive: true });
    document.addEventListener('touchend', handleDragEnd, { passive: true });
    return () => {
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchend', handleDragEnd);
    };
  }, [
    handleFailure,
    handleSuccess,
    state.isMouseDown,
    state.moveX,
    state.originX,
    state.targetX,
    state.trail
  ]);

  React.useEffect(() => {
    const handleDragMove = (event: MouseEvent | TouchEvent) => {
      if (!state.isMouseDown) return false;
      let currentX: number;
      let currentY: number;
      if ('clientX' in event) {
        currentX = event.clientX;
        currentY = event.clientY;
      } else {
        currentX = event.touches[0].clientX;
        currentY = event.touches[0].clientY;
      }
      const moveX = currentX - state.originX;
      const moveY = currentY - state.originY;
      if (moveX < 0 || moveX + 40 > OPTIONS.width) return false;
      setState(prev => ({ ...prev, moveX, trail: [...prev.trail, Math.round(moveY)] }));
    };
    document.addEventListener('mousemove', handleDragMove, { passive: true });
    document.addEventListener('touchmove', handleDragMove, { passive: true });
    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('touchmove', handleDragMove);
    };
  }, [state.isMouseDown, state.originX, state.originY]);

  React.useEffect(() => {
    if (!state.initialized && canvasRef.current && puzzlePieceRef.current) {
      const canvasContext = canvasRef.current.getContext('2d');
      const puzzlePieceContext = puzzlePieceRef.current.getContext('2d');
      initializeImage(canvasContext, puzzlePieceContext, puzzlePieceRef, setState);
      setState(prev => ({ ...prev, initialized: true }));
    }
  }, [state.targetX, state.targetY, state.initialized]);

  React.useEffect(() => {
    if (state.errorCount) {
      toggleErrorMessage();
      reload();
    }
  }, [reload, state.errorCount, toggleErrorMessage]);

  const handleGcaptchaError = () => {
    log.error('Google captcha threw a network error');
    onFailure('Google recaptcha error, try again.');
  };

  const onReaptchaLoad = () => {
    if (!window.CanvasRenderingContext2D) {
      switchToGoogleCaptcha();
    }
  };

  const exchangeToken = (token: string): Promise<void> =>
    client<VerifyCaptchaResponse>({
      method: 'POST',
      endpoint: buildUrl(api.unauthenticated.exchangeCaptchaToken[0]),
      data: { 'g-recaptcha-response': token },
      responseContentType: 'application/json'
    }).then(({ token }) => handleSuccess({ 'slider-captcha-token': token }));

  return (
    <div className="overlay">
      <div className="container">
        <div className="header">
          <img className="robot" src={RobotImg} alt="robot" />
        </div>
        <Reaptcha
          className={classNames('reaptcha', hideSliderCaptcha ? '' : 'hidden')}
          ref={captchaRef}
          {...reaptchaProps}
          onVerify={exchangeToken}
          onLoad={onReaptchaLoad}
          explicit
          sitekey={gcaptchaKey}
          onError={handleGcaptchaError}
        />
        {hideSliderCaptcha ? null : (
          <div className="slider-captcha">
            <div className="body">
              <canvas ref={canvasRef} width={OPTIONS.width} height={OPTIONS.height} />
              <canvas
                className="puzzle-piece"
                ref={puzzlePieceRef}
                style={{
                  left: `${state.moveX}px`
                }}
              />
            </div>
            <div className="slider-container">
              <div className="text-instruction no-select">
                {showErrorMessage ? 'Failed: try again' : 'Slide to solve puzzle'}
              </div>
              <div
                ref={knobRef}
                style={{ left: `${state.moveX}px` }}
                className="slider-knob">
                <ArrowRightIcon className="arrow" />
              </div>
            </div>
          </div>
        )}
      </div>
      {hideSliderCaptcha ? null : (
        <div className="bottom-bar">
          <button aria-label="refresh image" onClick={reload}>
            <RefreshIcon />
          </button>
          <button
            aria-label="open accessible captcha option"
            onClick={switchToGoogleCaptcha}>
            <AccessibilityIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export { SliderCaptcha };
