import * as React from 'react';

type FieldErrorsProps = {
  className?: string;
  visited?: boolean;
  errors?: string[];
};

const FieldErrors = ({
  className = 'field-errors',
  errors,
  visited
}: FieldErrorsProps): JSX.Element => {
  if (errors && visited) {
    return <div className={className}>{errors[0]}</div>;
  } else {
    return null;
  }
};

export { FieldErrors };
