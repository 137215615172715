import * as React from 'react';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { FComponent } from '../../../types/common';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import { classNames } from '../../../utils';

export type RadioGroupOptionProps = {
  type?: 'button' | 'simple' | 'tab' | 'tab-rounded';
  value: string;
  label: string;
  description?: string;
  icon?: React.ReactNode;
};

const RadioGroupOption: FComponent<RadioGroupOptionProps> = ({
  type = 'simple',
  value,
  label,
  description,
  icon
}) => {
  return (
    <HeadlessRadioGroup.Option value={value} className="radiogroup-option">
      {({ active, checked }) => (
        <div
          className={classNames(
            active && 'radiogroup-option-active',
            checked && 'radiogroup-option-checked',
            type === 'simple'
              ? 'radiogroup-option-simple'
              : type.includes('tab')
                ? `radiogroup-option-${type}`
                : 'radiogroup-option-button'
          )}>
          {type === 'simple' ? <div className="radio-button" /> : null}
          {type === 'button' && icon ? (
            <div className="radio-button-icon">{icon}</div>
          ) : null}
          <div className="radio-option-details">
            <HeadlessRadioGroup.Label as="p" className="option-label">
              {label}
            </HeadlessRadioGroup.Label>
            {description ? (
              <HeadlessRadioGroup.Description as="div" className="option-description">
                {description}
              </HeadlessRadioGroup.Description>
            ) : null}
          </div>
          {type === 'button' ? <CheckmarkIcon className="check-mark" /> : null}
        </div>
      )}
    </HeadlessRadioGroup.Option>
  );
};

export { RadioGroupOption };
