import * as React from 'react';
import { FComponent } from '../../../types/common';

const Loading: FComponent = () => (
  <div className="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export { Loading };
