import * as React from 'react';
import { useForm } from '../../hooks/useForm';
import { Validators } from 'tiny-validation';
import { isEmail } from '../../utils/validators';
import type { FComponent, RecursiveToCamel } from '../../types/common';
import { noop } from '../../utils';
import { Button } from '../common/Button';
import { Input } from '../common/Form';
import { api, DOMAIN } from '../../lib/api';
import type { CaptchaToken } from './SliderCaptcha';
import { Alert } from '../common/Alert';
import { paths } from '../../utils/constants';
import {
  containsLowercaseLetter,
  containsNumber,
  containsUppercaseLetter
} from '../../utils/validators';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { useToggle } from '../../hooks/useToggle';
import { SliderCaptcha } from './SliderCaptcha';
import { Modal } from '../common/Modal';
import { useApiRequest } from '../../hooks/useApiRequest';
const { isPresent, equals, minChars } = Validators;

type UserType = 'buyer' | 'seller';

export type RequestPasswordResetRequest = RecursiveToCamel<
  {
    [key in UserType]?: {
      email: string;
    };
  } & CaptchaToken
>;
export type RequestPasswordResetResponse = Record<string, unknown>;
export type ResetPasswordRequest = RecursiveToCamel<{
  [key in UserType]?: {
    password: string;
    password_confirmation: string;
    reset_password_token?: string;
  };
}>;
export type ResetPasswordData = ResetPasswordRequest[UserType];
export type ResetPasswordResponse = { redirect: string };

type ResetPasswordProps = {
  userType: UserType;
  gcaptchaKey?: string;
};

const ResetPassword: FComponent<ResetPasswordProps> = ({ userType, gcaptchaKey }) => {
  const params = new URLSearchParams(document.location.search);
  const token = params.get('reset_password_token');

  return (
    <div className="reset-password app-card-md">
      {token ? (
        <SetPassword userType={userType} token={token} />
      ) : (
        <RequestPasswordReset userType={userType} gcaptchaKey={gcaptchaKey} />
      )}
    </div>
  );
};

type RequestPasswordResetProps = {
  userType: UserType;
  gcaptchaKey: string;
};

const RequestPasswordReset: FComponent<RequestPasswordResetProps> = ({
  userType,
  gcaptchaKey
}) => {
  const { request: requestPasswordReset } = useApiRequest<
    RequestPasswordResetRequest,
    RequestPasswordResetResponse
  >({ method: 'POST', endpoint: api[userType].password });
  const [emailSent, setEmailSent] = React.useState(false);
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const captchaTokenRef = React.useRef<CaptchaToken>(null);

  const submit = async (
    email: string,
    setErrors: (errors: Record<string, string[]>) => void
  ) => {
    return requestPasswordReset(
      {
        data: { [userType]: { email }, ...captchaTokenRef.current }
      },
      setErrors
    )
      .then(() => setEmailSent(true))
      .catch(noop);
  };

  const {
    handleSubmit,
    handleFieldChange,
    isDisabled,
    values,
    isFieldVisited,
    errors,
    setErrors
  } = useForm({
    onSubmit: ({ email }): Promise<void> => submit(email, setErrors),
    schema: requestResetFormSchema,
    initialValues: {
      email: ''
    }
  });

  const onCancel = () => {
    location.href = `${DOMAIN}/${userType}s${paths.login}${document.location.search}`;
  };

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setErrors({ base: [message] });
  };

  if (emailSent) return <ResetSent userType={userType} />;

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault();
          toggleCaptcha();
        }}>
        <h1 className="title">Reset your password</h1>
        <div className="subtitle">
          Enter your email and we&apos;ll send you a link to reset your password
        </div>
        {errors?.base && <Alert type="warning">{errors.base}</Alert>}

        <Input
          type="email"
          name="email"
          label="Email"
          onChange={handleFieldChange}
          value={values['email']}
          placeholder="peterparker@bugle.net"
          autoCapitalize={false}
          visited={isFieldVisited('email')}
          errors={errors['email']}
        />
        <div className="button-bar">
          <Button secondary onClick={onCancel}>
            Back
          </Button>
          <Button type="submit" disabled={isDisabled} className="login-button">
            Send
          </Button>
        </div>
      </form>
      <Modal
        className="dialog-custom"
        title=""
        isOpen={isCaptchaOpen}
        toggle={toggleCaptcha}
        isAnimated={false}>
        <SliderCaptcha
          onSuccess={handleCaptchaSuccess}
          onFailure={handleCaptchaFailure}
          gcaptchaKey={gcaptchaKey}
        />
      </Modal>
    </>
  );
};

const ResetSent: FComponent<{ userType: UserType }> = ({ userType }) => (
  <div className="success-card">
    <h1 className="title">Reset Email Requested</h1>
    <div className="subtitle">
      If the email address you entered is associated with an account, you will receive an
      email with instructions for resetting your password within a few minutes.
    </div>
    <div className="single-button button-bar">
      <a
        className="primary-button"
        href={`${DOMAIN}/${userType}s${paths.login}${document.location.search}`}>
        Login
      </a>
    </div>
  </div>
);

type SetPasswordProps = {
  userType: UserType;
  token: string;
};

const SetPassword: FComponent<SetPasswordProps> = ({ userType, token }) => {
  const { request: resetPassword } = useApiRequest<
    ResetPasswordRequest,
    ResetPasswordResponse
  >({ method: 'PUT', endpoint: api[userType].password });
  const [isWaiting, setIsWaiting] = React.useState(false);
  const submit = async (
    formValues: ResetPasswordData,
    setErrors: (errors: Record<string, string[]>) => void
  ) => {
    setIsWaiting(true);
    return resetPassword(
      { data: { [userType]: { ...formValues, resetPasswordToken: token } } },
      setErrors
    )
      .then(({ redirect }) => {
        location.href = `${DOMAIN}${redirect}`;
      })
      .catch(() => {
        setIsWaiting(false);
      });
  };

  const {
    handleSubmit,
    handleFieldChange,
    isDisabled,
    values,
    isFieldVisited,
    errors,
    setErrors
  } = useForm({
    onSubmit: (formValues): Promise<void> => submit(formValues, setErrors),
    schema: resetFormSchema,
    initialValues: {
      password: '',
      passwordConfirmation: ''
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="title">Reset Password</h1>
      <div className="subtitle">Enter your new password and we&apos;ll log you in</div>
      {errors?.base && <Alert type="warning">{errors.base}</Alert>}

      <div className="form-fields">
        <Input
          type="password"
          name="password"
          label="Password"
          onChange={handleFieldChange}
          value={values['password']}
          visited={isFieldVisited('password')}
          errors={errors['password']}
          peekPassword
        />
        <Input
          type="password"
          label="Password Confirmation"
          name="passwordConfirmation"
          onChange={handleFieldChange}
          value={values['passwordConfirmation']}
          visited={isFieldVisited('passwordConfirmation')}
          errors={errors['passwordConfirmation']}
          peekPassword
        />
      </div>
      <div className="single-button button-bar">
        <Button
          type="submit"
          disabled={isDisabled}
          isWaiting={isWaiting}
          className="login-button">
          Send
        </Button>
      </div>
    </form>
  );
};

export default withErrorBoundary(ResetPassword, 'ResetPassword');

const requestResetFormSchema = {
  email: [isPresent('Enter your email'), isEmail()]
};

const resetFormSchema = {
  password: [
    isPresent('Enter a password'),
    minChars(8, 'Must have at least 8 characters'),
    containsUppercaseLetter,
    containsNumber,
    containsLowercaseLetter
  ],
  passwordConfirmation: [equals('password')]
};
