import * as React from 'react';
import { FComponent } from '../../../types/common';
import { Button } from './Button';

const LoadingButton: FComponent = () => {
  return (
    <Button disabled>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Button>
  );
};

export { LoadingButton };
