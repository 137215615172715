import * as React from 'react';
import { FComponent } from '../../../types/common';
import { classNames, setCSSVar } from '../../../utils';
import { feedbackIcons } from '../Icons';
import { motion } from 'framer-motion';

export type AlertProps = {
  type: 'warning' | 'help' | 'info' | 'success';
  className?: string;
};

const Alert: FComponent<AlertProps> = ({ type, children, className }) => {
  return (
    <motion.div
      exit={{
        opacity: 0,
        transition: {
          duration: 0.3
        }
      }}
      style={setCSSVar('--alert-color', `var(--colors-alert-${type})`)}
      className={classNames('alert', className)}>
      <div className="alert-content">
        {feedbackIcons[type]}
        <span className="children-content">{children}</span>
      </div>
    </motion.div>
  );
};

export { Alert };
