import * as React from 'react';
import { useForm } from '../../hooks/useForm';
import { Validators } from 'tiny-validation';
import { isEmail } from '../../utils/validators';
import type { FComponent, UserType } from '../../types/common';
import { classNames } from '../../utils';
import { Button } from '../common/Button';
import { Input } from '../common/Form';
import { Tab } from '@headlessui/react';
import { Alert } from '../common/Alert';
import { paths } from '../../utils/constants';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { SliderCaptcha } from './SliderCaptcha';
import type { CaptchaToken } from './SliderCaptcha';
import { Modal } from '../common/Modal';
import { useToggle } from '../../hooks/useToggle';
import { useApiRequest } from '../../hooks/useApiRequest';
import { DOMAIN } from '../../lib/api';
import { api } from '../../lib/api';
import { UAParser, IResult } from 'ua-parser-js';
const { isPresent } = Validators;

export type LoginRequest = {
  [key in UserType]?: {
    email: string;
    password: string;
    ua: IResult;
  };
} & CaptchaToken;
export type LoginResponse = {
  redirect: string;
};
type LoginUserType = 'buyer' | 'seller';
type LoginFormData = LoginRequest[LoginUserType];
type LoginProps = {
  initialUserType: LoginUserType;
  flash: [string, string][];
  gcaptchaKey: string;
};

const Login: FComponent<LoginProps> = ({ initialUserType, flash, gcaptchaKey }) => {
  const [userType, setUserType] = React.useState<LoginUserType>(initialUserType);
  const switchTab = (index: number) => {
    setUserType(index === 0 ? 'seller' : 'buyer');
    history.pushState(
      {},
      null,
      `${DOMAIN}/${index === 0 ? 'sellers' : 'buyers'}${paths.login}${
        document.location.search
      }`
    );
  };

  return (
    <div className="login">
      <div className="app-card-md">
        <Tab.Group defaultIndex={userType === 'buyer' ? 1 : 0} onChange={switchTab}>
          <div className="form-header">
            <Tab.List className="panel-tabs">
              <Tab
                className={({ selected }) =>
                  classNames('tab-button', selected ? 'selected' : '')
                }>
                Sellers
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames('tab-button', selected ? 'selected' : '')
                }>
                Buyers
              </Tab>
            </Tab.List>
          </div>

          <Tab.Panels>
            <Tab.Panel className="tab-panel">
              <LoginForm userType="seller" flash={flash} gcaptchaKey={gcaptchaKey} />
            </Tab.Panel>
            <Tab.Panel className="tab-panel">
              <LoginForm userType="buyer" flash={flash} gcaptchaKey={gcaptchaKey} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="registration">
        <span>
          New to TARTLE?{' '}
          <a href={`${paths.registration[userType]}${document.location.search}`}>
            Register Now.
          </a>
        </span>
      </div>
    </div>
  );
};

type LoginFormProps = Omit<LoginProps, 'initialUserType'> & { userType: UserType };

export const LoginForm: FComponent<LoginFormProps> = ({
  userType,
  flash,
  gcaptchaKey
}) => {
  const { request: login, isLoading } = useApiRequest<LoginRequest, LoginResponse>({
    method: 'POST',
    endpoint: api[userType].login
  });

  const captchaTokenRef = React.useRef<CaptchaToken>(null);
  const [flashMessages, setFlashMessages] = React.useState(flash);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const uaParser = new UAParser();

  const submit = async (
    loginData: LoginFormData,
    setErrors: (errors: Record<string, string[]>) => void
  ) => {
    setIsWaiting(true);
    return login(
      {
        data: {
          [userType]: loginData,
          ...captchaTokenRef.current
        }
      },
      setErrors
    )
      .then(({ redirect }) => {
        location.href = `${DOMAIN}${redirect}`;
      })
      .catch(() => {
        setIsWaiting(false);
        setFlashMessages([]);
      });
  };

  const {
    handleSubmit,
    handleFieldChange,
    isDisabled,
    values,
    isFieldVisited,
    errors,
    setErrors
  } = useForm({
    onSubmit: (formValues: LoginFormData): Promise<void> => submit(formValues, setErrors),
    schema: loginFormSchema,
    initialValues: {
      email: '',
      password: '',
      ua: uaParser.setUA(window.navigator.userAgent).getResult()
    }
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    toggleCaptcha();
  };

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setErrors({ base: [message] });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        {flashMessages.map(([type, message]) => (
          <Alert key={message} type={type === 'notice' ? 'info' : 'warning'}>
            {message}
          </Alert>
        ))}
        {errors?.base && (
          <Alert type="warning">
            {errors.base[0].includes('confirm') ? (
              <>
                {errors.base[0]}&nbsp;&nbsp;&nbsp;
                <a href={`/${userType}s${paths.confirmEmail}${document.location.search}`}>
                  Resend.
                </a>
              </>
            ) : (
              errors.base
            )}
          </Alert>
        )}

        <Input
          type="email"
          name="email"
          label="Email"
          onChange={handleFieldChange}
          value={values['email']}
          placeholder="peterparker@bugle.net"
          visited={isFieldVisited('email')}
          autoCapitalize={false}
          errors={errors['email']}
        />
        <Input
          type="password"
          label="Password"
          name="password"
          onChange={handleFieldChange}
          value={values['password']}
          visited={isFieldVisited('password')}
          errors={errors['password']}
          peekPassword
        />
        {userType !== 'moderator' ? (
          <a
            className="forgot-pwd"
            href={`${DOMAIN}/${userType}s${paths.forgotPassword}${document.location.search}`}>
            Forgot your password?
          </a>
        ) : null}
        <Button
          type="submit"
          disabled={isDisabled || isLoading}
          isWaiting={isWaiting}
          className="login-button">
          Log In
        </Button>
      </form>
      <Modal
        className="dialog-custom"
        title=""
        isOpen={isCaptchaOpen}
        toggle={toggleCaptcha}
        isAnimated={false}>
        <SliderCaptcha
          onSuccess={handleCaptchaSuccess}
          onFailure={handleCaptchaFailure}
          gcaptchaKey={gcaptchaKey}
        />
      </Modal>
    </>
  );
};

export default withErrorBoundary(Login, 'Login');

const loginFormSchema = {
  email: [isPresent('Enter your email'), isEmail()],
  password: [isPresent('Enter your password')]
};
