import * as React from 'react';
import { FComponent } from '../../types/common';
import { Tooltip } from './Tooltip';
import InfoIcon from './Icons/InfoIcon';

export type DataDisclosure = {
  safety?: string;
  usage?: string;
  worth?: string;
};

type DataDisclosureTooltipProps = {
  disclosure: DataDisclosure;
};

export const DataDisclosureTooltip: FComponent<DataDisclosureTooltipProps> = ({
  disclosure
}) => (
  <div className="tooltip-popup tooltip-vertical">
    <Tooltip
      touch
      placement="bottom"
      textClassName="disclosure-tooltip"
      text={<InfoIcon />}>
      <div className="disclosure-text">
        <b>How will this data be used?</b>
        <p>{disclosure.usage}</p>
        <b>How will you keep this data safe?</b>
        <p>{disclosure.safety}</p>
        <b>What is this data worth?</b>
        <p>{disclosure.worth}</p>
      </div>
    </Tooltip>
  </div>
);
