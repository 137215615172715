import * as React from 'react';
import { FComponent } from '../../../types/common';
import { Switch } from '@headlessui/react';
import { FieldErrors } from './FieldErrors';
import { classNames } from '../../../utils';

type SwitchProps = {
  name: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  visited?: boolean;
  errors?: string[];
  label?: string;
  labelPosition?: 'left' | 'right';
  small?: boolean;
  value?: boolean;
  disabled?: boolean;
  className?: string;
};

const ToggleSwitch: FComponent<SwitchProps> = ({
  name,
  onChange,
  visited,
  errors,
  small = false,
  label,
  labelPosition = 'right',
  value,
  disabled = false,
  className = ''
}) => {
  const handleChange = (value: boolean) => {
    const event: React.ChangeEvent<HTMLInputElement> = {
      target: {
        name,
        value
      }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  return (
    <div
      className={classNames(
        disabled ? 'not-allowed' : '',
        'toggle-component',
        className
      )}>
      <Switch.Group>
        {labelPosition === 'left' && label ? (
          <Switch.Label className={`${small ? 'label-sm' : ''} left`}>
            {label}
          </Switch.Label>
        ) : null}
        <Switch
          disabled={disabled}
          checked={value}
          onChange={handleChange}
          name={name}
          className={`${value ? 'on' : 'off'} ${
            small ? 'switch-button-sm' : 'switch-button'
          }`}>
          <span
            aria-hidden="true"
            className={`${
              value ? 'switch-on' : 'switch-off'
            } switch-ball transition-toggle`}
          />
        </Switch>
        {labelPosition === 'right' && label ? (
          <Switch.Label className={`${small ? 'label-sm' : ''} right`}>
            {label}
          </Switch.Label>
        ) : null}
      </Switch.Group>
      <FieldErrors visited={visited} errors={errors} />
    </div>
  );
};

export { ToggleSwitch };
