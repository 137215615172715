import { toSnakeCase } from '../utils';
import { store } from './store';

const globalObj = window.tartleGlobal;

type PacketArgs = {
  packetId: string;
  isFrictionless?: boolean;
};
type ShareArgs = {
  shareType: string;
};
type SignupArgs = { isFrictionless: boolean };
type EventArgs = Record<string, string | number | boolean>;

type ShareEvents = 'shareModalOpen' | 'shareButtonClicked' | 'shareToasterShown';
type PacketEvents = 'packetView' | 'packetPublish' | 'packetSave';

export const pixelEvents = {
  snapchat: {
    pageView: () => globalObj?.snaptrk?.('track', 'PAGE_VIEW'),
    signup: () => globalObj?.snaptrk?.('track', 'SIGN_UP'),
    packetView: (): void => null,
    packetPublish: (): void => null,
    packetSave: (): void => null,
    shareModalOpen: (): void => null,
    shareButtonClicked: (): void => null,
    shareToasterShown: (): void => null,
    event: (_: string, __: Record<string, string | number | boolean>): void => null
  },
  tiktok: {
    pageView: () => globalObj?.tiktrk?.('page'),
    signup: () => globalObj?.tiktrk?.('CompleteRegistration'),
    packetView: (): void => null,
    packetPublish: (): void => null,
    packetSave: (): void => null,
    shareModalOpen: (): void => null,
    shareButtonClicked: (): void => null,
    shareToasterShown: (): void => null,
    event: (_: string, __: Record<string, string | number | boolean>): void => null
  },
  facebook: {
    pageView: () => globalObj?.fbtrk?.('track', 'PageView'),
    signup: () => globalObj?.fbtrk?.('track', 'CompleteRegistration'),
    packetView: (): void => null,
    packetPublish: (): void => null,
    packetSave: (): void => null,
    shareModalOpen: (): void => null,
    shareButtonClicked: (): void => null,
    shareToasterShown: (): void => null,
    event: (_: string, __: Record<string, string | number | boolean>): void => null
  },
  google: {
    pageView: (): void => null,
    signup: () => {
      globalObj?.gatrack?.('event', 'tartle_sign_up');
    },
    packetView: (): void => null,
    packetPublish: (): void => null,
    packetSave: (): void => null,
    shareModalOpen: (): void => null,
    shareButtonClicked: (): void => null,
    shareToasterShown: (): void => null,
    event: (_: string, __: Record<string, string | number | boolean>): void => null
  },
  tartle: {
    pageView: (): void => null,
    signup: (args: SignupArgs) => {
      window.plausible?.('signup', getProps(args));
    },
    packetView: (args: PacketArgs) => {
      window.plausible?.('packetView', getProps(args));
    },
    packetPublish: (args: PacketArgs) => {
      window.plausible?.('packetPublish', getProps(args));
    },
    packetSave: (args: PacketArgs) => {
      window.plausible?.('packetSave', getProps(args));
    },
    shareModalOpen: (args: ShareArgs) => {
      window.plausible?.('shareModalOpen', getProps(args));
    },
    shareButtonClicked: (args: ShareArgs) => {
      window.plausible?.('shareButtonClicked', getProps(args));
    },
    shareToasterShown: (args: ShareArgs) => {
      window.plausible?.('shareToasterShown', getProps(args));
    },
    event: (eventName: string, args: Record<string, string | number | boolean>) => {
      window.plausible?.(eventName, getProps(args));
    }
  }
};

type PixelPlatform = keyof typeof pixelEvents;
const serviceList: PixelPlatform[] = [
  'tiktok',
  'snapchat',
  'facebook',
  'google',
  'tartle'
];

export const track = (
  eventName: 'pageView' | 'signup' | PacketEvents | ShareEvents | 'event',
  services: PixelPlatform[] | 'all' = serviceList,
  args?: PacketArgs | ShareArgs | EventArgs
): void => {
  const isShareEvent =
    eventName === 'shareModalOpen' ||
    eventName === 'shareButtonClicked' ||
    eventName === 'shareToasterShown';

  if (services === 'all') {
    services = serviceList;
  }

  try {
    services
      .map(service => pixelEvents[service])
      .forEach(platform => {
        if (eventName in platform) {
          if (isShareEvent) {
            platform[eventName]?.(args as ShareArgs);
          } else if (eventName === 'signup') {
            platform[eventName]?.(args as SignupArgs);
          } else if (eventName === 'event') {
            const { name, ...restArgs } = args as EventArgs;
            platform[eventName]?.(name as string, restArgs);
          } else {
            platform[eventName]?.(args as PacketArgs | undefined);
          }
        }
      });
  } catch {
    // do nothing, analytics errors are not critical
  }
};

const getProps = (args: Record<string, string | number | boolean>) => ({
  props: toSnakeCase({ ...args, isNewUser: isNewUser() })
});

// Check if this user signed up in the last 24hrs
const isNewUser = (): boolean => {
  try {
    const joinedAt = store.getItem('joinedAt');
    if (!joinedAt) {
      return false;
    }
    if (Date.now() - parseInt(joinedAt, 10) < 1000 * 60 * 60 * 24) {
      return true;
    } else {
      store.removeItem('joinedAt');
    }
  } catch {
    // do nothing
  }
  return false;
};
