import dayjs from 'dayjs';
import * as React from 'react';
import { isPresent, minChars, equals } from 'tiny-validation/build/main/lib/validators';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { useApiRequest } from '../../hooks/useApiRequest';
import { useForm } from '../../hooks/useForm';
import { useUrlParamState } from '../../hooks/useUrlParamState';
import { api, DOMAIN } from '../../lib/api';
import { FComponent, RecursiveToCamel } from '../../types/common';
import { noop, redirect } from '../../utils';
import {
  containsLowercaseLetter,
  containsNumber,
  containsUppercaseLetter,
  isAfterDate,
  isBeforeDate,
  isValidDate
} from '../../utils/validators';
import { Button } from '../common/Button';
import { DateInput, Input } from '../common/Form';
import { ErrorMessage } from '../common/Form/ErrorMessage';
import TartleCombinationLogo from '../common/Icons/TartleCombinationLogo';
import { Loading } from '../common/Loading/Loading';
import { track } from '../../lib/analytics';

type CompleteRegistrationRequest = RecursiveToCamel<{
  password: string;
  password_confirmation: string;
  date_of_birth: Date;
  resetPasswordToken: string;
  uid: string;
  registration_token: string;
}>;
type CompleteRegistrationFormValues = Omit<
  CompleteRegistrationRequest,
  'resetPasswordToken'
>;

export type CompleteRegistrationResponse = {
  redirect: string;
};

const CompleteRegistration: FComponent = () => {
  const [urlParams] = useUrlParamState();
  const resetPasswordToken = urlParams['token'];
  const registrationToken = urlParams['registrationToken'];
  const uid = urlParams['uid'];
  const cancelRef = React.useRef<boolean>(false);

  const {
    error: completeRegistrationError,
    isError: isCompleteRegistrationError,
    isSuccess: isCompleteRegistrationSuccess,
    data: successResponse,
    request: completeRegistration
  } = useApiRequest<CompleteRegistrationRequest, CompleteRegistrationResponse>({
    method: 'PUT',
    endpoint: api.seller.completeRegistration
  });

  React.useEffect(() => {
    const listener = function (event: MessageEvent) {
      if (
        event.origin === location.origin &&
        event.data.type === 'finished_registration'
      ) {
        cancelRef.current = true;
        redirect(successResponse.redirect);
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [successResponse]);

  const {
    handleSubmit,
    handleFieldChange,
    isDisabled,
    values,
    isFieldVisited,
    isSubmitting,
    errors,
    setErrors
  } = useForm<CompleteRegistrationFormValues>({
    onSubmit: (formValues): Promise<void> =>
      completeRegistration(
        { data: { ...formValues, resetPasswordToken, uid, registrationToken } },
        setErrors
      )
        .then(({ redirect: redirectUrl }) => {
          // We wait a while to allow the iframe to load and send the tracking info.
          // This is only a failsafe in case postMessage from the iframe fails.
          localStorage?.setItem('joinedAt', Date.now().toString());
          track('event', ['tartle'], { name: 'accountClaimed' });
          setTimeout(() => {
            if (!cancelRef.current) {
              redirect(`${DOMAIN}${redirectUrl}`);
            }
          }, 5000);
        })
        .catch(noop),
    schema: completeRegistrationSchema,
    initialValues: {
      password: '',
      passwordConfirmation: '',
      dateOfBirth: null,
      uid: '',
      registrationToken: ''
    },
    beforeUnload: true
  });

  return (
    <div className="reset-password app-card-md">
      {isCompleteRegistrationSuccess ? (
        <div className="login-interstitial">
          <iframe
            title="successFrame"
            src={`${DOMAIN}/frictionless/after_registration?fb=true`}
            width="1"
            height="1"
          />
          <TartleCombinationLogo />
          <div>
            <Loading />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h1 className="title">Welcome back!</h1>
          <div className="subtitle">
            Complete your registration with TARTLE to start earning.
          </div>
          <ErrorMessage
            includeFieldNames
            errors={completeRegistrationError}
            isShowing={isCompleteRegistrationError}
            keysToFilter={['date_of_birth']}
          />

          <div className="form-fields">
            <DateInput
              name="dateOfBirth"
              label="Date of birth"
              onChange={handleFieldChange}
              value={values['dateOfBirth']}
              placeholder="Choose a date"
              visited={isFieldVisited('dateOfBirth')}
              errors={errors['dateOfBirth']}
              captionLayout={true}
              fromDate={dayjs().subtract(100, 'year').toDate()}
              toDate={dayjs().subtract(13, 'year').toDate()}
            />
            <Input
              type="password"
              name="password"
              label="Password"
              onChange={handleFieldChange}
              value={values['password']}
              visited={isFieldVisited('password')}
              errors={errors['password']}
              peekPassword
            />
            <Input
              type="password"
              label="Password Confirmation"
              name="passwordConfirmation"
              onChange={handleFieldChange}
              value={values['passwordConfirmation']}
              visited={isFieldVisited('passwordConfirmation')}
              errors={errors['passwordConfirmation']}
              peekPassword
            />
          </div>
          <div className="button-bar">
            <Button
              type="submit"
              disabled={isDisabled}
              isWaiting={isSubmitting}
              className="login-button">
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default withErrorBoundary(CompleteRegistration, 'CompleteRegistration');

const completeRegistrationSchema = {
  password: [
    isPresent('Enter a password'),
    minChars(8, 'Must have at least 8 characters'),
    containsUppercaseLetter,
    containsNumber,
    containsLowercaseLetter
  ],
  passwordConfirmation: [equals('password')],
  dateOfBirth: [
    isPresent("Can't be blank"),
    isValidDate,
    isBeforeDate(dayjs().subtract(13, 'year').toDate()),
    isAfterDate(dayjs().subtract(100, 'year').toDate())
  ]
};
