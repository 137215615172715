import * as React from 'react';
import Reaptcha from 'reaptcha';

type UseReaptchaResult = {
  captchaState: {
    loaded: boolean;
    verified: boolean;
    rendered: boolean;
    expired: boolean;
  };
  reset: () => void;
  verify: (recaptchaResponse: string) => void;
  captchaRef: React.MutableRefObject<Reaptcha>;
  responseRef: React.MutableRefObject<string>;
  reaptchaProps: {
    onLoad: () => void;
    onExpire: () => void;
    onRender: () => void;
    ref: React.MutableRefObject<Reaptcha>;
  };
};

const useReaptcha = (): UseReaptchaResult => {
  const captchaRef = React.useRef<null | Reaptcha>(null);
  const responseRef = React.useRef<string | null>(null);
  const [state, setState] = React.useState({
    loaded: false,
    verified: false,
    rendered: false,
    expired: false
  });

  React.useEffect(() => {
    if (state.expired) {
      setState(prev => ({ ...prev, verified: false }));
    }
  }, [state.expired]);

  const reset = React.useCallback(() => {
    captchaRef.current?.reset();
    setState(prev => ({ ...prev, expired: false, verified: false }));
  }, []);

  const verify = React.useCallback((recaptchaResponse: string) => {
    setState(prev => ({ ...prev, verified: true, expired: false }));
    responseRef.current = recaptchaResponse;
  }, []);

  return {
    captchaState: state,
    reset,
    verify,
    captchaRef,
    responseRef,
    reaptchaProps: {
      onLoad: () => setState(prev => ({ ...prev, loaded: true })),
      onExpire: () => setState(prev => ({ ...prev, expired: true })),
      onRender: () => setState(prev => ({ ...prev, rendered: true })),
      ref: captchaRef
    }
  };
};

export { useReaptcha };
