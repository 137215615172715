import * as React from 'react';
import { Checkbox, Input } from '../common/Form';
import { Modal } from '../common/Modal';
import { Button } from '../common/Button';
import EnvelopeIcon from '../common/Icons/EnvelopeIcon';
import { Validators } from 'tiny-validation';
import { useForm } from '../../hooks/useForm';
import { paths } from '../../utils/constants';
import type { FComponent, RecursiveToCamel } from '../../types/common';
import { SliderCaptcha } from './SliderCaptcha';
import { useLogger } from '../../hooks/useLogger';
import { useToggle } from '../../hooks/useToggle';
import { useTimerSwitch } from '../../hooks/useTimerSwitch';
import { passwordValidators, isValueInSet } from '../../utils/validators';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { DOMAIN, api } from '../../lib/api';
import type { CaptchaToken } from '../unauthenticated/SliderCaptcha';
import { ErrorMessage } from '../common/Form/ErrorMessage';
import { handleApiErrors } from '../../utils';
import { track } from '../../lib/analytics';
import { useApiRequest } from '../../hooks/useApiRequest';
import type {
  RequestEmailConfirmationRequest,
  RequestEmailConfirmationResponse
} from './SellerSignup';
import ChevronLeftMiniIcon from '../common/Icons/ChevronLeftMiniIcon';
import TartleLogoMark from '../common/Icons/TartleLogoMark';
import { ChangeNoticeState, LATEST_CHANGE } from '../common/ChangeNotices';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

const { equals, isEmail, isPresent } = Validators;

export type BuyerSignupRequest = RecursiveToCamel<
  {
    buyer: {
      email: string;
      password: string;
      password_confirmation: string;
      tos_accepted: string;
      referral_code?: string;
    };
  } & CaptchaToken
>;
export type BuyerSignupData = BuyerSignupRequest['buyer'];
export type BuyerSignupResponse = { success: boolean };

type BuyerSignupProps = {
  gcaptchaKey: string;
};

const BuyerSignup: FComponent<BuyerSignupProps> = ({ gcaptchaKey }) => {
  const params = new URLSearchParams(document.location.search);
  const referralCode = params.get('referral_code') ?? undefined;

  const [isWaiting, setIsWaiting] = React.useState(false);
  const [captchaError, setCaptchaError] = React.useState<string | null>(null);
  const captchaTokenRef = React.useRef<CaptchaToken>(null);
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const [isThankYouScreenOpen, toggleThankYouScreen] = useToggle(false);
  const [, setChangeNotice] = useLocalStorageState<ChangeNoticeState>('changeNotice');

  const {
    request: signup,
    error,
    isError,
    isLoading
  } = useApiRequest<BuyerSignupRequest, BuyerSignupResponse>({
    method: 'POST',
    endpoint: api.buyer.signup
  });

  const submit = async (formValues: BuyerSignupData) => {
    setIsWaiting(true);
    return signup({
      data: { buyer: { ...formValues, referralCode }, ...captchaTokenRef.current }
    })
      .then(() => {
        setChangeNotice({
          currentVersion: LATEST_CHANGE.version,
          firstSeen: Date.now(),
          dismissed: true
        });
        track('signup', ['google', 'snapchat', 'tartle'], { isFrictionless: false });
        toggleThankYouScreen();
      })
      .catch(() => {
        setIsWaiting(false);
      });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    toggleCaptcha();
  };

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setCaptchaError(message);
  };

  const { handleSubmit, handleFieldChange, isDisabled, values, errors, isFieldVisited } =
    useForm({
      onSubmit: (values: BuyerSignupData): Promise<void> => submit(values),
      schema: signupFormSchema,
      initialValues: {
        password: '',
        passwordConfirmation: '',
        email: '',
        tosAccepted: 'false'
      },
      beforeUnload: true
    });

  return (
    <>
      <div className="app-card-drawer-mobile signup">
        <div className="signup-header">
          <a href={`${DOMAIN}/sellers${paths.login}`} title="back to login">
            <ChevronLeftMiniIcon />
          </a>
          <TartleLogoMark className="sheet-tartle-logo" />
        </div>
        <div className="signup-sheet">
          {isThankYouScreenOpen ? (
            <BuyerThankYouScreen email={values['email']} />
          ) : (
            <>
              <form className="signup-step" onSubmit={onSubmit}>
                <div className="top-alert">
                  This portal is strictly intended for purchasing data from consumers. If
                  you are a Data Seller, you should visit the{' '}
                  <a
                    href={`${DOMAIN}${paths.registration.seller}${document.location.search}`}>
                    seller registration page.
                  </a>
                </div>
                <div>
                  <div className="step-title">Create an Account</div>
                </div>

                <div className="grid-form password-form">
                  <Input
                    type="email"
                    label="Company Email"
                    name="email"
                    onChange={handleFieldChange}
                    value={values['email']}
                    errors={errors['email']}
                    visited={isFieldVisited('email')}
                    placeholder="employee@company.com"
                    autoCapitalize={false}
                  />
                  <Input
                    type="password"
                    label="Password"
                    name="password"
                    onChange={handleFieldChange}
                    value={values['password']}
                    visited={isFieldVisited('password')}
                    errors={errors['password']}
                    peekPassword
                  />
                  <Input
                    type="password"
                    label="Confirm password"
                    name="passwordConfirmation"
                    onChange={handleFieldChange}
                    value={values['passwordConfirmation']}
                    visited={isFieldVisited('passwordConfirmation')}
                    errors={errors['passwordConfirmation']}
                    peekPassword
                  />
                  <div className="tos-section">
                    <div>
                      Please read and accept the TARTLE terms of service and privacy
                      policy before creating your account.
                    </div>
                    <div className="tos-documents">
                      <a href="/terms_of_service" target="_blank" rel="noreferrer">
                        Terms of Service
                      </a>
                      <a href="/privacy_policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <Checkbox
                    name="tosAccepted"
                    label={
                      <div className="tos-label">
                        I accept TARTLE&apos;s terms of service and privacy policy
                      </div>
                    }
                    checked={values['tosAccepted'] == 'true'}
                    onChange={handleFieldChange}
                    visited={isFieldVisited('tosAccepted')}
                    errors={errors['tosAccepted']}
                  />
                </div>

                <ErrorMessage
                  errors={error || [captchaError]}
                  isShowing={isError || !!captchaError}
                  defaultError="Could not register user, please try again"
                />

                <div className="form-button-bar push-to-bottom">
                  <Button
                    type="submit"
                    disabled={isDisabled || isLoading}
                    isWaiting={isWaiting}>
                    <span>Create account</span>
                  </Button>
                </div>
                <Modal
                  className="dialog-custom"
                  title=""
                  isOpen={isCaptchaOpen}
                  toggle={toggleCaptcha}
                  isAnimated={false}>
                  <SliderCaptcha
                    onSuccess={handleCaptchaSuccess}
                    onFailure={handleCaptchaFailure}
                    gcaptchaKey={gcaptchaKey}
                  />
                </Modal>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="login-link">
        <span>
          Already have a TARTLE account?{' '}
          <a href={`${DOMAIN}/buyers${paths.login}${document.location.search}`}>Login.</a>
        </span>
      </div>
    </>
  );
};

const BuyerThankYouScreen: FComponent<Partial<BuyerSignupData>> = ({ email }) => {
  const log = useLogger('buyersignupthankyou');
  const { request: requestEmailConfirmation } = useApiRequest<
    RequestEmailConfirmationRequest,
    RequestEmailConfirmationResponse
  >({ method: 'POST', endpoint: api.buyer.confirmation });
  const { isOn: isSent, turnOn: toggleSentMessage } = useTimerSwitch(5000);

  const resend = () =>
    requestEmailConfirmation({ data: { buyer: { email } } })
      .then(() => {
        toggleSentMessage();
      })
      .catch(handleApiErrors(log));

  return (
    <div className="signup-step">
      <div className="post-registration">
        <EnvelopeIcon />
        <div className="step-title">Thank you!</div>
        <div className="step-title">Check your email.</div>
        <div className="thanks-text">
          Click on the link we sent to {email} to verify your account so you can start
          earning with TARTLE.
        </div>

        <div className="resend-text">
          {isSent ? (
            'Email sent'
          ) : (
            <>
              Did not receive an email?
              <button
                className="text-button"
                onClick={resend}
                aria-label="Resend confirmation email">
                Resend
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(BuyerSignup, 'BuyerSignup');

const signupFormSchema = {
  email: [isPresent('Enter your email'), isEmail()],
  password: passwordValidators,
  passwordConfirmation: [equals('password', "Passwords don't match")],
  tosAccepted: [
    isValueInSet(['true'], 'You must agree to the Terms of Service to continue')
  ]
};
