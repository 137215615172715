import dayjs from 'dayjs';
import * as React from 'react';
import { Button } from '../Button';
import classNames from 'classnames';
import CalendarIcon from '../Icons/CalendarIcon';
// import 'react-day-picker/dist/style.css';
import { usePopper } from 'react-popper';
import { FieldErrors } from './FieldErrors';
import { Popover } from '@headlessui/react';
import { FComponent } from '../../../types/common';
import { DateRange, DayPicker } from 'react-day-picker';
import {
  createChangeEvent,
  CustomTarget,
  SimulatedChangeEvent
} from '../../../hooks/useForm';

export interface DateRangePickerTarget extends CustomTarget {
  name: string;
  type: 'daterangepicker';
  value: DateRange;
}

type DateRangeInputProps = {
  name: string;
  label?: string;
  onChange: (event: SimulatedChangeEvent<DateRangePickerTarget>) => void;
  value?: DateRange;
  startRange?: 'lastOneWeek' | 'lastOneMonth' | 'lastOneYear';
  placeholder?: string;
  showIcon?: boolean;
  visited?: boolean;
  errors?: string[];
  className?: string;
  disabled?: boolean;
  fromYear?: number;
  toYear?: number;
  fromDate?: Date;
  ariaLabel?: string;
  toDate?: Date;
};

const DateRangeInput: FComponent<DateRangeInputProps> = ({
  name,
  label,
  onChange,
  value = undefined,
  startRange,
  placeholder = 'Select date range',
  showIcon = true,
  visited,
  errors,
  className = '',
  disabled = false,
  fromYear = null,
  toYear = null,
  fromDate = null,
  toDate = null,
  ariaLabel
}) => {
  if (!ariaLabel && !label) {
    throw new Error("DateRangeInput: 'ariaLabel' or 'label' is required");
  }
  const [referenceElement, setReferenceElement] = React.useState<HTMLButtonElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement>();
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement);
  const [selectedRange, setSelectedRange] = React.useState<string | null>(startRange);

  const handleDateChange = (dates: DateRange) => {
    if (dates) {
      onChange(
        createChangeEvent({
          type: 'daterangepicker',
          name,
          value: dates
        })
      );
    }
    setSelectedRange(null);
  };

  const selectDateRange = (date: string, close: () => void) => {
    handleDateChange(dateRanges[date]);
    setSelectedRange(date);
    close();
  };

  const dateRanges: Record<string, DateRange> = {
    lastOneWeek: {
      from: dayjs().subtract(7, 'day').toDate(),
      to: dayjs().toDate()
    },
    lastOneMonth: {
      from: dayjs().subtract(1, 'month').toDate(),
      to: dayjs().toDate()
    },
    lastOneYear: {
      from: dayjs().subtract(1, 'year').toDate(),
      to: dayjs().toDate()
    }
  };

  return (
    <div className={classNames('date-range-picker', className)}>
      {label ? (
        <label htmlFor={name} className="input-label">
          {label}
        </label>
      ) : null}

      <Popover>
        <Popover.Button
          id={name}
          ref={setReferenceElement}
          className="date-button"
          disabled={disabled}
          aria-label={ariaLabel}>
          {showIcon ? <CalendarIcon /> : null}
          <span>
            {value && value.from && value.to
              ? `${dayjs(value.from).format('MMM DD, YYYY')} - ${dayjs(value.to).format(
                  'MMM DD, YYYY'
                )}`
              : placeholder}
          </span>
        </Popover.Button>
        <FieldErrors visited={visited} errors={errors} />

        <Popover.Panel
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
          className="date-panel">
          {({ close }) => (
            <>
              <DayPicker
                mode="range"
                numberOfMonths={2}
                onSelect={handleDateChange}
                defaultMonth={dayjs().subtract(1, 'month').toDate()}
                fromYear={fromYear}
                toYear={toYear}
                selected={value}
                fromDate={fromDate}
                toDate={toDate}
              />
              <div className="date-range-footer">
                <div className="date-range-btn-row">
                  <button
                    className={`${
                      selectedRange === 'lastOneWeek' ? 'range-selected' : ''
                    }`}
                    onClick={() => selectDateRange('lastOneWeek', close)}>
                    Last 1 week
                  </button>
                  <button
                    className={`${
                      selectedRange === 'lastOneMonth' ? 'range-selected' : ''
                    }`}
                    onClick={() => selectDateRange('lastOneMonth', close)}>
                    Last 1 month
                  </button>
                  <button
                    className={`${
                      selectedRange === 'lastOneYear' ? 'range-selected' : ''
                    }`}
                    onClick={() => selectDateRange('lastOneYear', close)}>
                    Last 1 year
                  </button>
                </div>
                <Button onClick={() => close()}>OK</Button>
              </div>
            </>
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export { DateRangeInput };
