import { useState, useEffect, useCallback } from 'react';

type UseTimerSwitch = (
  initialDuration?: number,
  isInitiallyOn?: boolean
) => {
  isOn: boolean;
  turnOn: () => void;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
};

// returns a boolean that when switched on automatically switches off after an interval.
const useTimerSwitch: UseTimerSwitch = (
  initialDuration = 1500,
  isInitiallyOn = false
) => {
  const [isOn, setIsOn] = useState(isInitiallyOn);
  const [duration, setDuration] = useState(initialDuration);

  const turnOn = useCallback(() => setIsOn(true), []);

  useEffect(() => {
    if (isOn) {
      const id = setTimeout(() => setIsOn(false), duration);

      return () => clearTimeout(id);
    }
  }, [duration, isOn]);

  return { isOn, turnOn, setDuration };
};

export { useTimerSwitch };
