import * as React from 'react';
import { ByComparator } from '@headlessui/react/dist/types';

export type UseComboboxResult<T extends ComboboxOption> = {
  query: string;
  options: T[];
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  optionEquals: (a: T, b: T) => boolean;
  getOptionLabel: (option: T) => string;
  isLoadingOptions?: boolean; // used mostly for useasync-based options (see multicombobox)
  totalOptions?: number; // for used for paginated records,
  isApiError?: boolean;
};

export type ComboboxOption = string | Record<string, unknown>;

type UseComboboxProps<T extends ComboboxOption> = {
  initialOptions?: T[];
  setOptionsOnQuery: (
    query: string,
    setOptions: React.Dispatch<React.SetStateAction<T[]>>
  ) => void;
  compareBy: ByComparator<T>;
  getOptionLabel: (option: T) => string;
};

type OptionFetcher<T extends ComboboxOption> = UseComboboxProps<T>['setOptionsOnQuery'];

const useCombobox = <T extends ComboboxOption>({
  initialOptions = [],
  setOptionsOnQuery,
  compareBy,
  getOptionLabel
}: UseComboboxProps<T>): UseComboboxResult<T> => {
  const [query, setQuery] = React.useState('');
  const [options, setOptions] = React.useState<T[]>(initialOptions);

  React.useEffect(() => {
    setOptionsOnQuery('', setOptions);
    // the only time I've had to turn this off. Making setOptionsOnQuery stable would create a mess.
    // We need to do an initial loading of options to have something on the list.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setOptionsOnQuery(event.target.value, setOptions);
  };

  const optionEquals = expandComparisonFunction(compareBy);

  return {
    query,
    options,
    onQueryChange,
    optionEquals,
    getOptionLabel
  };
};

// Convenience wrapper for simple usecases when options are a predefined static list.
type UseSimpleComboboxProps<T> = {
  options: T[];
  compareBy?: ByComparator<T>; // string | ((a: T, b: T) => boolean);
  getOptionLabel: (option: T) => string;
  filterBy: (query: string) => (input: T) => boolean;
};

const useSimpleCombobox = <T extends ComboboxOption>({
  options,
  compareBy,
  getOptionLabel,
  filterBy
}: UseSimpleComboboxProps<T>): UseComboboxResult<T> => {
  const setOptionsOnQuery: OptionFetcher<T> = (query, setOptions) =>
    setOptions(query === '' ? options : options.filter(filterBy(query)));

  return useCombobox({
    initialOptions: options,
    setOptionsOnQuery,
    compareBy,
    getOptionLabel
  });
};

export { useCombobox, useSimpleCombobox };

// Convenience filters for simple comboboxes
export const filterStrings = (query: string) => (option: string) =>
  option.includes(query);

export const filterRecordsByKey =
  <T extends Record<string, unknown>>(key: keyof T) =>
  (query: string) =>
  (option: T) =>
    (option[key] as string).includes(query);
// Can't find a way to avoid the 'as' cast here.

const expandComparisonFunction = <T extends ComboboxOption>(
  compareBy: ByComparator<T>
) => {
  if (!compareBy) {
    return (a: T, b: T) => a === b;
  } else if (typeof compareBy === 'string') {
    return (a: T, b: T) => a?.[compareBy as keyof T] === b?.[compareBy as keyof T];
  }
  return compareBy;
};
