import * as React from 'react';
import { classNames } from '../../../utils';
import Tippy from '@tippyjs/react/headless';
import { FComponent } from '../../../types/common';
import { followCursor, Placement } from 'tippy.js';

// https://atomiks.github.io/tippyjs/v6/all-props/

export type TooltipProps = {
  text: string | React.ReactNode;
  children: string | React.ReactNode;
  className?: string;
  textClassName?: string;
  styleType?: 'success' | 'warning' | 'info' | 'error' | 'dark' | 'default';
  placement?: Placement;
  showArrow?: boolean;
  trigger?: 'click' | 'mouseenter focus' | 'focusin' | 'mouseenter click';
  delay?: [number, number] | number;
  interactiveBorder?: number;
  disabled?: boolean;
  cursor?: boolean | 'horizontal' | 'vertical' | 'initial';
  interactive?: boolean;
  touch?: boolean | 'hold' | ['hold', number];
};

const Tooltip: FComponent<TooltipProps> = ({
  text,
  children,
  className = '',
  textClassName = null,
  styleType = 'default',
  placement = 'right',
  showArrow = true,
  trigger = 'mouseenter focus',
  delay = [0, 100],
  interactiveBorder = 5,
  disabled = false,
  cursor = false,
  interactive = false,
  touch = ['hold', 300]
}) => {
  return (
    <Tippy
      disabled={disabled}
      interactiveBorder={interactiveBorder}
      interactive={interactive}
      appendTo={() => document.body}
      touch={touch}
      delay={delay}
      trigger={trigger}
      placement={placement}
      followCursor={cursor}
      plugins={[followCursor]}
      render={attrs => {
        return (
          <div
            className={classNames(styleType, 'tooltip-container', className)}
            tabIndex={-1}
            {...attrs}>
            {children}
            {showArrow ? (
              <div
                className="tooltip-arrow"
                id="tooltip-arrow"
                data-popper-arrow=""></div>
            ) : null}
          </div>
        );
      }}>
      <span className={textClassName}>{text}</span>
    </Tippy>
  );
};

export { Tooltip };
