import * as React from 'react';
import { Modal } from '../common/Modal';
import { Button } from '../common/Button';
import { Validators } from 'tiny-validation';
import { useForm } from '../../hooks/useForm';
import { noop } from '../../utils';
import type { FComponent, RecursiveToCamel, UserType } from '../../types/common';
import { SliderCaptcha } from './SliderCaptcha';
import { useToggle } from '../../hooks/useToggle';
import { ErrorMessage } from '../common/Form/ErrorMessage';
import EnvelopeIcon from '../common/Icons/EnvelopeIcon';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { useTimerSwitch } from '../../hooks/useTimerSwitch';
import { countryNames, dataDisclosures, paths } from '../../utils/constants';
import { Checkbox, Input } from '../common/Form';
import { useAutofocusRef } from '../../hooks/useAutofocusRef';
import type { StepProps } from '../common/MultiStepFrame';
import { isValueInSet } from '../../utils/validators';
import { DOMAIN, api } from '../../lib/api';
import type { CaptchaToken } from './SliderCaptcha';
import { track } from '../../lib/analytics';
import { useApiRequest } from '../../hooks/useApiRequest';
import { useCombobox } from '../../hooks/useCombobox';
import { Combobox } from '../common/Form/Combobox';
import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import TartleLogoMark from '../common/Icons/TartleLogoMark';
import ChevronLeftMiniIcon from '../common/Icons/ChevronLeftMiniIcon';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { ChangeNoticeState, LATEST_CHANGE } from '../common/ChangeNotices';
const { isPresent, isEmail } = Validators;

export type SellerSignupRequest = RecursiveToCamel<
  {
    seller: {
      email: string;
      country: string;
      tos_accepted: boolean;
      referral_code?: string;
      email_tracking_consent: boolean;
    };
  } & CaptchaToken
>;
export type SellerSignupData = SellerSignupRequest['seller'];
export type SellerSignupResponse = { success: boolean };

export type RequestEmailConfirmationRequest = {
  [key in UserType]?: {
    email: string;
  };
};
export type RequestEmailConfirmationResponse = { redirect: string };

type SignupProps = {
  gcaptchaKey: string;
};

const SellerSignup: FComponent<SignupProps> = ({ gcaptchaKey }) => {
  const params = new URLSearchParams(document.location.search);
  const referralCode = params.get('referral_code') ?? undefined;
  const [email, setEmail] = React.useState<string>();
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const onSuccess = async (formValues: Partial<SellerSignupData>) => {
    setEmail(formValues.email);
  };

  useBeforeUnload(React.useCallback(() => isFormDirty, [isFormDirty]));

  return (
    <div>
      <div className="app-card-drawer-mobile signup">
        <div className="signup-header">
          <a href={`${DOMAIN}/sellers${paths.login}`} title="back to login">
            <ChevronLeftMiniIcon />
          </a>
          <TartleLogoMark className="sheet-tartle-logo" />
        </div>
        <div className="signup-sheet">
          {email ? (
            <SellerThankYouScreen email={email} />
          ) : (
            <SellerCredentialsForm
              onSuccess={onSuccess}
              gcaptchaKey={gcaptchaKey}
              referralCode={referralCode}
              setIsFormDirty={setIsFormDirty}
            />
          )}
        </div>
      </div>
      <div className="login-link">
        <span>
          Already have a TARTLE account?{' '}
          <a href={`sellers${paths.login}${document.location.search}`}>Login.</a>
        </span>
      </div>
    </div>
  );
};

type SignupFormValues = {
  email: string;
  country: string;
  tosAccepted: string;
  emailTrackingConsent: string;
};

const SellerCredentialsForm: FComponent<
  StepProps<SellerSignupData> & {
    gcaptchaKey: string;
    referralCode?: string;
    setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  }
> = ({ onSuccess, gcaptchaKey, referralCode, setIsFormDirty }) => {
  const {
    request: signup,
    error: apiError,
    isError: isApiError,
    isLoading
  } = useApiRequest<SellerSignupRequest, SellerSignupResponse>({
    method: 'POST',
    endpoint: api.seller.signup
  });
  const captchaTokenRef = React.useRef<CaptchaToken>(null);
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const [captchaError, setCaptchaError] = React.useState<string | null>(null);
  const focusRef = useAutofocusRef();

  const [, setChangeNotice] = useLocalStorageState<ChangeNoticeState>('changeNotice');

  const comboboxProps = useCombobox<string>({
    initialOptions: countryNames,
    setOptionsOnQuery: (query, setOptions) =>
      setOptions(
        countryNames.filter(name => name.toLowerCase().includes(query.toLowerCase()))
      ),
    getOptionLabel: option => option,
    compareBy: (a, b) => a === b
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    toggleCaptcha();
  };

  const submit = async (formValues: SignupFormValues) => {
    setIsWaiting(true);
    const { tosAccepted: tosAcceptedString, emailTrackingConsent, ...rest } = formValues;
    const signupValues = {
      ...rest,
      tosAccepted: tosAcceptedString === 'true',
      emailTrackingConsent: emailTrackingConsent === 'true'
    };

    return signup({
      data: {
        seller: {
          ...signupValues,
          referralCode
        },
        ...captchaTokenRef.current
      }
    })
      .then(() => {
        history.replaceState(null, null, `${window.location.pathname}?status=thankyou`);
        setChangeNotice({
          currentVersion: LATEST_CHANGE.version,
          firstSeen: Date.now(),
          dismissed: true
        });
        track('signup', 'all', { isFrictionless: false });
        onSuccess(signupValues);
        setIsFormDirty(false);
      })
      .catch(() => setIsWaiting(false));
  };

  const { handleSubmit, handleFieldChange, isDisabled, values, errors, isFieldVisited } =
    useForm({
      onSubmit: submit,
      schema: formSchema,
      initialValues: {
        email: '',
        country: '',
        tosAccepted: 'false',
        emailTrackingConsent: 'false'
      }
    });

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setCaptchaError(message);
  };

  return (
    <form onSubmit={onSubmit} className="signup-step">
      <div>
        <div className="step-title">Create your account</div>
      </div>
      <div className="grid-form password-form">
        <Input
          ref={focusRef}
          type="email"
          name="email"
          label="Email"
          disclosure={dataDisclosures['email']}
          onChange={handleFieldChange}
          value={values['email']}
          placeholder="peterparker@bugle.net"
          errors={errors['email']}
          visited={isFieldVisited('email')}
          autoCapitalize={false}
        />
        <Combobox
          name="country"
          label="Select your country"
          disclosure={dataDisclosures['country']}
          onChange={handleFieldChange}
          value={values['country']}
          {...comboboxProps}
          visited={isFieldVisited('country')}
          errors={errors['country']}
        />
        <Checkbox
          name="emailTrackingConsent"
          label={
            <div className="tos-label">
              To maximize your earnings, allow TARTLE to see if you open emails we send
              you.
            </div>
          }
          checked={values['emailTrackingConsent'] === 'true'}
          onChange={handleFieldChange}
          visited={isFieldVisited('emailTrackingConsent')}
          errors={errors['emailTrackingConsent']}
        />
        <div className="tos-section">
          <div>
            Please read and accept the TARTLE terms of service and privacy policy before
            creating your account.
          </div>
          <div className="tos-documents">
            <a href="/terms_of_service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            <a href="/privacy_policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
        </div>
        <Checkbox
          name="tosAccepted"
          label={
            <div className="tos-label">
              I accept TARTLE&apos;s terms of service and privacy policy
            </div>
          }
          checked={values['tosAccepted'] === 'true'}
          onChange={handleFieldChange}
          visited={isFieldVisited('tosAccepted')}
          errors={errors['tosAccepted']}
        />

        <ErrorMessage
          errors={apiError || [captchaError]}
          isShowing={isApiError || !!captchaError}
          defaultError="Could not register user, please try again"
        />
      </div>
      <div className="form-button-bar push-to-bottom">
        <Button type="submit" disabled={isDisabled || isLoading} isWaiting={isWaiting}>
          <span>Create account</span>
        </Button>
      </div>
      <Modal
        className="dialog-custom"
        title=""
        isOpen={isCaptchaOpen}
        toggle={toggleCaptcha}
        isAnimated={false}>
        <SliderCaptcha
          onSuccess={handleCaptchaSuccess}
          onFailure={handleCaptchaFailure}
          gcaptchaKey={gcaptchaKey}
        />
      </Modal>
    </form>
  );
};

const SellerThankYouScreen: FComponent<
  StepProps<Partial<SellerSignupData>> & { email: string }
> = ({ email }) => {
  const { request: requestEmailConfirmation } = useApiRequest<
    RequestEmailConfirmationRequest,
    RequestEmailConfirmationResponse
  >({ method: 'POST', endpoint: api.seller.confirmation });
  const { isOn: isSent, turnOn: toggleSentMessage } = useTimerSwitch(5000);

  const resend = () =>
    requestEmailConfirmation({ data: { seller: { email } } })
      .then(toggleSentMessage)
      .catch(noop);

  return (
    <div className="signup-step">
      <div className="post-registration">
        <EnvelopeIcon />
        <div className="step-title">Thank you for joining TARTLE!</div>
        <div className="step-title">Check your email.</div>
        <div className="thanks-text">
          Click on the link we sent to {email} to verify your account so you can start
          earning with TARTLE.
        </div>

        <div className="resend-text">
          {isSent ? (
            'Email sent'
          ) : (
            <div>
              Did not receive an email?
              <button
                className="text-button"
                onClick={resend}
                aria-label="Resend confirmation email">
                Resend
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(SellerSignup, 'SellerSignup');

const formSchema = {
  tosAccepted: [
    isValueInSet(['true'], 'You must agree to the Terms of Service to continue')
  ],
  country: [isPresent('Please select a country')],
  email: [isPresent('Enter your email'), isEmail()]
};
