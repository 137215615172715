import * as React from 'react';
import { useForm } from '../../hooks/useForm';
import { Validators } from 'tiny-validation';
import { isEmail } from '../../utils/validators';
import type { FComponent } from '../../types/common';
import { noop } from '../../utils';
import { Button } from '../common/Button';
import { Input } from '../common/Form';
import { api, DOMAIN } from '../../lib/api';
import { Alert } from '../common/Alert';
import { paths } from '../../utils/constants';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import type {
  RequestEmailConfirmationRequest,
  RequestEmailConfirmationResponse
} from './SellerSignup';
import { useApiRequest } from '../../hooks/useApiRequest';
const { isPresent } = Validators;

type UserType = 'buyer' | 'seller';

type SendEmailConfirmationProps = {
  userType: UserType;
};

const SendEmailConfirmation: FComponent<SendEmailConfirmationProps> = ({ userType }) => {
  const { request: requestEmailConfirmation, isSuccess } = useApiRequest<
    RequestEmailConfirmationRequest,
    RequestEmailConfirmationResponse
  >({ method: 'POST', endpoint: api[userType].confirmation });

  const submit = async (
    email: string,
    setErrors: (errors: Record<string, string[]>) => void
  ) => {
    return requestEmailConfirmation({ data: { [userType]: { email } } }, setErrors).catch(
      noop
    );
  };

  const {
    handleSubmit,
    handleFieldChange,
    isDisabled,
    values,
    errors,
    isFieldVisited,
    setErrors
  } = useForm({
    onSubmit: ({ email }): Promise<void | RequestEmailConfirmationResponse> =>
      submit(email, setErrors),
    schema: requestConfirmationFormSchema,
    initialValues: {
      email: ''
    }
  });

  if (isSuccess) return <EmailSent userType={userType} />;

  return (
    <div className="reset-password app-card-md">
      <form onSubmit={handleSubmit}>
        <h1 className="title">Email confirmation</h1>
        <div className="subtitle">
          Enter your email address and we&apos;ll resend you a confirmation link.
        </div>
        {errors?.base && <Alert type="warning">{errors.base}</Alert>}

        <Input
          type="email"
          name="email"
          label="Email"
          onChange={handleFieldChange}
          value={values['email']}
          placeholder="peterparker@bugle.net"
          visited={isFieldVisited('email')}
          errors={errors['email']}
        />
        <div className="button-bar">
          <Button
            secondary
            onClick={() =>
              (location.href = `${DOMAIN}/${userType}s${paths.login}${document.location.search}`)
            }>
            Back
          </Button>
          <Button type="submit" disabled={isDisabled} className="login-button">
            Send
          </Button>
        </div>
      </form>
    </div>
  );
};

const EmailSent: FComponent<{ userType: UserType }> = ({ userType }) => (
  <div className="reset-password app-card-md">
    <div className="success-card">
      <h1 className="title">Confirmation Email Requested</h1>
      <div className="subtitle">
        If the email address you entered is associated with an account, you will receive
        an email shortly.
      </div>
      <div className="single-button button-bar">
        <a
          className="primary-button"
          href={`${DOMAIN}/${userType}s${paths.login}${document.location.search}`}>
          Done
        </a>
      </div>
    </div>
  </div>
);

export default withErrorBoundary(SendEmailConfirmation, 'SendEmailConfirmation');

const requestConfirmationFormSchema = {
  email: [isPresent('Enter your email'), isEmail()]
};
