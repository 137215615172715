import * as React from 'react';
import { createPortal } from 'react-dom';
import { FComponent } from '../../types/common';
import { useUserAgent } from '../../hooks/useUserAgent';
import SearchIcon from './Icons/SearchIcon';
import { Button } from './Button';

const ZoomableImage: FComponent<{ src: string; alt: string }> = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = React.useState(false);
  const [holdTimer, setHoldTimer] = React.useState(null);
  const { isMobile: showZoomButton } = useUserAgent();

  const startZoom = () => {
    if (!isZoomed)
      setHoldTimer(
        setTimeout(() => {
          setIsZoomed(true);
        }, 300)
      );
  };

  const stopZoom = () => {
    clearTimeout(holdTimer);
    setIsZoomed(false);
  };

  const handleStart = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>
  ) => {
    if (showZoomButton) return;

    event.preventDefault();
    event.stopPropagation();
    startZoom();
  };

  const handleEnd = () => {
    if (showZoomButton) return;

    stopZoom();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (showZoomButton) return;

    if (event.key === ' ' || event.key === 'Enter') {
      startZoom();
      event.stopPropagation();
      event.preventDefault(); // prevents scroll
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (showZoomButton) return;

    if (event.key === ' ' || event.key === 'Enter') {
      handleEnd();
      event.preventDefault();
    }
  };

  return (
    <div
      className="zoomable-image"
      aria-label="Press and hold or hold space to zoom in."
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      role="button"
      tabIndex={0}>
      <img src={src} alt={alt} />
      {showZoomButton ? (
        <button
          className="zoom-button"
          onClick={e => {
            setIsZoomed(true);
            e.preventDefault();
          }}>
          <SearchIcon />
        </button>
      ) : null}
      {createPortal(
        <div
          className="zoomed-image-container"
          style={{
            display: isZoomed ? 'flex' : 'none'
          }}>
          <div className="zoomed-image-wrapper">
            <img className="zoomed-image" src={src} alt={alt + ' zoomed in'} />
            {showZoomButton ? (
              <Button className="close-zoom-button" onClick={() => setIsZoomed(false)}>
                Close
              </Button>
            ) : null}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export { ZoomableImage };
