import * as React from 'react';
import { FComponent } from '../../../types/common';
import { classNames } from '../../../utils';
import CloseEyeIcon from '../Icons/CloseEyeIcon';
import CloseIcon from '../Icons/CloseIcon';
import OpenEyeIcon from '../Icons/OpenEyeIcon';
import { FieldErrors } from './FieldErrors';
import { DataDisclosure, DataDisclosureTooltip } from '../DataDisclosureTooltip';

type InputProps = {
  name: string;
  label?: string;
  disclosure?: DataDisclosure;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel' | 'url';
  inputMode?: 'text' | 'decimal' | 'numeric';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  clearable?: boolean;
  value?: string | number;
  placeholder?: string;
  svgPrefix?: JSX.Element;
  postfix?: string;
  visited?: boolean;
  errors?: string[];
  autoComplete?: string;
  autoCapitalize?: boolean;
  disabled?: boolean;
  inputClass?: string;
  className?: string;
  ariaLabel?: string;
  maxLength?: number;
  peekPassword?: boolean;
};

// Input will take care of setting up a styled text Input for you (includes password types)
// It may (or not) include an SVG prefix for the input contents and a text postfix.
// If you do include either of the above, you MUST send in a contentStyle css class that specifies
// the padding on the left and right of the input box to set aside for the svg prefix and text postfix.
const WrappedInput: FComponent<InputProps, HTMLInputElement> = (
  {
    name,
    label,
    disclosure,
    type = 'text',
    inputMode,
    onChange,
    onEnter,
    clearable = false,
    value,
    placeholder = '',
    svgPrefix,
    postfix,
    visited,
    errors,
    inputClass = 'text-input',
    autoComplete = 'on',
    autoCapitalize = true,
    disabled = false,
    className = '',
    ariaLabel,
    maxLength,
    peekPassword
  },
  ref
) => {
  const [inputType, setInputType] = React.useState(type);

  const changeInputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  const handleClear = () => {
    onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  if (type === 'password' && !placeholder) {
    placeholder = '••••••••';
  }

  const isEmpty = !value || value.toString().trim() === '';
  const onKeyPressHandler: Pick<
    React.DOMAttributes<HTMLInputElement>,
    'onKeyPress'
  > = onEnter
    ? {
        onKeyPress: event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onEnter(event);
          }
        }
      }
    : {};

  return (
    <div className={classNames('field-container', className)}>
      <div className={classNames('input-label', disclosure ? 'with-disclosure' : '')}>
        <label htmlFor={name}>{label}</label>
        {disclosure ? <DataDisclosureTooltip disclosure={disclosure} /> : null}
      </div>

      <div className={classNames('input-container', disabled ? 'input-disabled' : '')}>
        <div className="input-contents">
          {svgPrefix ? <div className="svg-wrapper">{svgPrefix}</div> : null}
          <input
            maxLength={maxLength}
            ref={ref}
            inputMode={inputMode}
            aria-label={ariaLabel}
            type={inputType}
            name={name}
            id={name}
            onChange={onChange}
            {...onKeyPressHandler}
            value={value ?? ''}
            className={inputClass}
            placeholder={placeholder}
            aria-describedby={postfix ? 'postfix' : undefined}
            autoComplete={autoComplete}
            autoCapitalize={autoCapitalize ? 'on' : 'off'}
            disabled={disabled}
          />
          {postfix ? (
            <div className="postfix">
              <span id="postfix">{postfix}</span>
            </div>
          ) : peekPassword ? (
            <button
              type="button"
              className="postfix-peek-password"
              tabIndex={0}
              onClick={changeInputType}>
              {inputType === 'password' ? <CloseEyeIcon /> : <OpenEyeIcon />}
            </button>
          ) : clearable && !isEmpty ? (
            <button
              type="button"
              className="postfix-clear"
              tabIndex={0}
              onClick={handleClear}>
              <CloseIcon />
            </button>
          ) : null}
        </div>
      </div>
      <FieldErrors visited={visited} errors={errors} />
    </div>
  );
};

const Input = React.forwardRef(WrappedInput);

export { Input };
