import { curry } from 'ramda';
import * as React from 'react';
import { BigNumber } from 'bignumber.js';
import { isDevelopment, Log } from '../lib/logger';
import { mimetypes } from '../lib/mime';
import { ApiErrors, isApiError } from '../lib/api/types';
import { RecursiveToCamel, RecursiveToSnake } from '../types/common';
import { DOMAIN } from '../lib/api';
import { memoize } from './memoize';

const isObject = (input: unknown): input is Record<string, unknown> =>
  input !== null && typeof input === 'object';

export const isBooleanArray = (value: unknown): value is boolean[] => {
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length === 0) {
    return true;
  }
  return typeof value[0] === 'boolean';
};

type StringTransform = (a: string) => string;

const transformKeys =
  (transform: StringTransform) =>
  (input: unknown, isDeep = false, depth = 6): unknown => {
    if (depth === 0)
      throw Error("You're getting too deep, probably an infinite recursion");

    if (Array.isArray(input)) {
      if (isDeep)
        return input.map(item => transformKeys(transform)(item, true, depth - 1));
      else return input;
    }

    if (isObject(input) && !(input instanceof Date)) {
      const newHash: Record<string, unknown> = {};

      Object.keys(input).forEach(key => {
        const newValue = isDeep
          ? transformKeys(transform)(input[key], true, depth - 1)
          : input[key];
        newHash[transform(key)] = newValue;
      });

      return newHash;
    }

    return input;
  };

export const camelToSnake = (str: string): string =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

export const snakeToCamel = (str: string): string =>
  str.replace(/_./g, (letter: string) => `${letter[1].toUpperCase()}`);

const cts = transformKeys(camelToSnake);
const stc = transformKeys(snakeToCamel);

export const toSnakeCase = <T>(
  input: T,
  isDeep = false,
  depth = 6
): RecursiveToSnake<T> => {
  return cts(input, isDeep, depth) as RecursiveToSnake<T>;
};

export const toCamelCase = <T>(
  input: T,
  isDeep = false,
  depth = 6
): RecursiveToCamel<T> => {
  return stc(input, isDeep, depth) as RecursiveToCamel<T>;
};

export const camelToSpace = (str: string): string =>
  str.replace(/[A-Z]/g, (letter: string) => ` ${letter.toLowerCase()}`);

export const snakeToSpace = (str: string): string => str.replace(/_/g, '\u0020');
export const spaceToSnake = (str: string): string => str.replace(/\u0020/g, '_');

export const capitalizeEachWord = (string: string): string => {
  return string.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

// wraps the value for properties of an api error object in an array to transform it into a form validation error object
// keys are the keys we are expecting (fields in a form)
export const apiErrorsToFormErrors = (
  errors: ApiErrors,
  keys: string[] = []
): Record<string, string[]> => {
  if (Array.isArray(errors)) {
    return { base: errors };
  } else {
    return Object.entries(errors).reduce(
      (wrappedObj, [key, value]) => {
        // if we passed keys, we want to associate only those keys with specific errors (this is to associate errors with form fields)
        if (keys.length === 0 || keys.includes(key)) {
          return { [snakeToCamel(key)]: value, ...wrappedObj };
        } else {
          // when passing keys, any errors returned for a non-specified key will be added to the base error array
          // This will be used to show a top-level form error like: "reset_token: is invalid" for example.
          // where "reset_token" can't be associated with a form field but still needs to be shown in the error message
          return {
            ...wrappedObj,
            base: [...(wrappedObj.base || []), ...value]
          };
        }
      },
      {} as Record<string, string[]>
    );
  }
};

// helper function for setting a style attribute that sets a css variables
export const setCSSVar = (varName: string, value: string): React.CSSProperties =>
  ({ [varName]: value }) as React.CSSProperties;

export const classNames = (...classes: string[]): string =>
  classes.filter(Boolean).join(' ');

// Used for select options, where the server sends us the unselected option named "unspecified_*"
export const removeUnspecified = (value: string): string | undefined => {
  if (value?.startsWith('unspecified_')) {
    return undefined;
  } else {
    return value;
  }
};

// Abbreviate number to form "102k", "10M", but only started a particular value.
export const abbreviateNumber = (n: number, precision = 0, beginAt = 1000): string => {
  if (n < beginAt || n < 1000) {
    return `${n}`;
  }

  if (n > 1000000) {
    return `${(n / 1000000).toFixed(precision)}M`;
  }

  if (n > 1000) {
    return `${(n / 1000).toFixed(precision)}k`;
  }
};

// Get a flag for a country code
export const getFlagEmoji = memoize((countryCode: string): string => {
  if (!countryCode) return '';
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
});

// Intended only for displaying simple percentage values on screen.
export const pct = (value: number, total: number, precision = 2): number =>
  Math.round((value / total) * (100 * 10 ** precision)) / 100;

export const strcmp = (a: string, b: string): number =>
  a[0] < b[0] ? -1 : a[0] === b[0] ? 0 : 1;

// handy utils for functional work
export const pluckElement = curry((index: number, arr: unknown[]): unknown => arr[index]);
export const pluck = curry(
  (obj: Record<string, unknown>, key: string): unknown => obj[key]
);

export const bigMultiply = (a: number, b: number): BigNumber =>
  new BigNumber(a).multipliedBy(b);

export const USD_RATE = 0.001;
export const convertTcoinToCurrency: (number: number, rate?: number) => BigNumber = (
  number,
  rate = USD_RATE
) => {
  return new BigNumber(number).multipliedBy(new BigNumber(rate));
};

export const convertCurrencyToTcoin: (number: number, rate?: number) => BigNumber = (
  number,
  rate = USD_RATE
) => {
  return new BigNumber(number).dividedBy(new BigNumber(rate)).dp(0);
};

export const formatUSD: (amount: number, precision?: number) => string = (
  amount,
  precision = 3
) => {
  return convertTcoinToCurrency(amount, USD_RATE).toFixed(precision);
};

// gets a random number between [start, end] (inclusive of both)
export const getRandomNumberInRange = (start: number, end: number): number => {
  return Math.floor(Math.random() * (end - start + 1) + start);
};

export const distanceOfTimeInWords = (miliseconds: number): string => {
  if (!miliseconds) return 'unknown';

  const minute = Math.abs(miliseconds / 1000 / 60);
  const hour = Math.abs(miliseconds / 1000 / 60 / 60);
  const day = Math.abs(miliseconds / 1000 / 60 / 60 / 24);
  const month = Math.abs(miliseconds / 1000 / 60 / 60 / 24 / 30);
  const year = Math.abs(miliseconds / 1000 / 60 / 60 / 24 / 30 / 12);

  const display = (time: number, interval: string) => {
    return `~${Math.floor(time)} ${interval}${Math.floor(time) > 1 ? 's' : ''} ${
      miliseconds < 0 ? 'ago' : ''
    }`;
  };

  if (minute >= 1 && hour >= 1 && day >= 1 && month >= 1 && year >= 1) {
    return display(year, 'year');
  } else if (minute >= 1 && hour >= 1 && day >= 1 && month >= 1) {
    return display(month, 'month');
  } else if (minute >= 1 && hour >= 1 && day >= 1) {
    return display(day, 'day');
  } else if (minute >= 1 && hour >= 1) {
    return display(hour, 'hour');
  } else if (minute >= 1) {
    return display(minute, 'minute');
  } else {
    return 'unknown';
  }
};

// Utility function useful in type guards to check if an object contains a particular property
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const getUserType = (): 'buyers' | 'sellers' | 'moderator' => {
  const userType = window.location.pathname.split('/')[1].toLowerCase();
  if (userType === 'buyers' || userType === 'sellers' || userType === 'moderator') {
    return userType;
  } else {
    throw new Error(`Unknown user type: ${userType}`);
  }
};

export const getFileNameExtension = (filename: string): string =>
  filename?.match(/.*\.([^.?]*)/)?.[1] ?? '';

export const getFileNameExtensionFromUrl = (url: string): string => {
  if (isDevelopment) {
    return getFileNameExtension(url);
  } else {
    const paramsArray = url.match(/([^?=&]+)(=([^&]*))/g) ?? [];
    const v = paramsArray
      .map(p => p.split('='))
      .filter(([h]) => h === 'response-content-type')
      .flat()?.[1];

    if (v) {
      const type = decodeURIComponent(v);
      return mimetypes.find(m => m.mime === type)?.extension ?? 'unknown';
    } else {
      return 'unknown';
    }
  }
};

// Caps the length of a string by adding ellipsis in the middle
export const compact = (str: string, maxChars = 20): string => {
  if (!str) return '';
  if (str.length <= maxChars) return str;
  const cutoff = Math.floor((maxChars - 3) / 2);
  return str.slice(0, cutoff) + '...' + str.slice(-maxChars + 3 + cutoff);
};

// Caps the length of a string by adding ellipsis at the end
export const truncate = (str: string, maxChars = 20): string => {
  if (!str) return '';
  if (str.length <= maxChars) return str;
  return str.slice(0, maxChars) + '...';
};

// Use this reducer in an array.reduce to split an array into a passed[] and failed[] tuple.
// Usage: array.reduce(splitReducer(conditionFunction), [[], []])
export const splitReducer =
  <T>(condition: (item: T) => boolean) =>
  ([passed, failed]: T[][], item: T): [T[], T[]] => {
    if (condition(item)) {
      return [[...passed, item], [...failed]];
    } else {
      return [[...passed], [...failed, item]];
    }
  };

// Converts a hex string of data into base64
export const hexToBase64 = (str: string): string =>
  Buffer.from(str.match(/../g).map(code => parseInt(code, 16))).toString('base64');

// Human readable file size converter
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const redirect = (url: string, sameDomain = false): void => {
  location.href = `${sameDomain ? DOMAIN : ''}${url}`;
};

export const getUrlParam = (param: string): string => {
  const params = new URLSearchParams(document.location.search);
  return params.get(param);
};

export const handleApiErrors =
  (
    logFn: Log,
    setFormErrors?: (errors: Record<string, string[]>) => void,
    callback?: (errors?: unknown) => void
  ) =>
  (errors: unknown): void => {
    if (errors instanceof Error) {
      logFn.exception({ error: errors });
      callback?.();
    } else {
      logFn.error('API Error', errors);
      // This will allow field-specific errors to be displayed under their fields.
      if (isApiError(errors)) {
        setFormErrors?.(apiErrorsToFormErrors(errors));
        callback?.(errors);
      } else {
        callback?.();
      }
    }
  };

export const objectToUrlParams = (
  obj: Record<string, Parameters<typeof encodeURIComponent>[0]>
): string => {
  const paramString = Object.entries(obj)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return paramString ? `?${paramString}` : '';
};

const webviewRules = [
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
  'Android.*Version/[0-9].[0-9]',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  'Android.*wv',
  // old chrome android webview agent
  'Linux; U; Android'
];

export const isWebview = (
  userAgent: string,
  type: 'all' | 'android' | 'ios' = 'all'
): boolean => {
  if (!userAgent) return false;
  if (type === 'android') webviewRules.splice(1, 1);
  else if (type === 'ios') webviewRules.splice(2, 3);

  const webviewRegExp = new RegExp('(' + webviewRules.join('|') + ')', 'ig');
  return !!userAgent.match(webviewRegExp);
};

export const noop = (): void => null;

export const squish = (text: string): string => text.replace(/\s+/g, ' ').trim();

// Creates a hash from an array keyed by the value for a particular property key
export const keyBy = <T>(array: T[] = [], key: string): Record<string, T> =>
  Object.fromEntries(array.map(item => [(item as Record<string, unknown>)[key], item]));

// appends nullable arrays
export const combineNullableArrays = <T>(a?: T[], b?: T[]): T[] => {
  const combined = [...(a ?? []), ...(b ?? [])];
  return combined.length > 0 ? combined : undefined;
};

// simple pluralizer
export const pluralize = (str: string, num: number) => {
  return num === 1 ? str : str + 's';
};

// hash a string, do not use for security, this is just a helper for simple tasks.
export const cheapHash = memoize((input: string) => {
  const prime = 16777619;
  let hash = 2166136261;

  for (let i = 0; i < input.length; i++) {
    hash = (hash ^ input.charCodeAt(i)) * prime;
  }

  return hash.toString(16);
});

// Checks to see if emoji is supported by the browser
export const isEmojiSupported = (emoji: string): boolean => {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext?.('2d');
    if (!ctx) return false;
    ctx.fillText(emoji, 0, 0);
    const pixelData = ctx.getImageData(16, 16, 1, 1).data;
    return pixelData[3] !== 0; // we check the alpha channel fo the pixel
  } catch (e) {
    return false;
  }
};

export const isTouchDevice = 'ontouchstart' in window || navigator?.maxTouchPoints > 0;

// Utility function for using an api endpoint as an action for appsignal. It replaces possible IDs with a number and
// returns the matches in an object that can be used to set tags in the appsignal report.
export const processUrl = memoize(
  (originalUrl: string): { url: string; matches: string } => {
    const basePath = originalUrl.replace(/^(https?:\/\/)?[^/]+\/?/, '');

    let count = 1;
    const matches: { [key: number]: string } = {};

    // Regex to find 13 letter words according to the conditions
    const regex = /\/([a-zA-Z0-9]{13})(?=\/|\.json|\.csv|$)/g;

    // Replace matched patterns with sequential numbers
    const replacedPath = basePath.replace(regex, (_, p1: string) => {
      matches[count] = p1;
      return '/' + count++;
    });

    return {
      url: replacedPath,
      matches: Object.entries(matches)
        .map(([key, value]) => `${key}: "${value}"`)
        .join(', ')
    };
  }
);
