import { Schema, validate, Validation } from 'tiny-validation';
import RegexParser from 'regex-parser';
import { pattern } from 'tiny-validation/build/main/lib/validators';
import { PacketQuestion } from '../../types/packet.schema';
import { SellersPacket } from '../../types/sellersPacket.schema';
import { isFieldValuePresent } from '../../utils/validators';
import { Answer, PacketFormValues } from './useSellersPacketForm';
import { FileInputValue } from '../UseFileInput';

export const getInitialFormValues = (
  answers: Answer[],
  sellersPacket: SellersPacket
): PacketFormValues => {
  return {
    ...Object.fromEntries(answers.map(answer => [answer.id, answer.value])),
    autosell: sellersPacket.autosellSetting !== 'disabled',
    donation: sellersPacket.beneficiaryAccountId ?? undefined
  };
};
export const getFormSchema = (questions: PacketQuestion[], answers: Answer[]) =>
  answers.reduce((acc, answer) => {
    const question = questions.find(question => question.id === answer.questionId);
    const validators = [
      isFieldValuePresent(
        question.htmlInputType === 'select' && question.multiple // temporary solution for multiselect
          ? 'multiselect'
          : question.htmlInputType
      )
    ];
    if (
      question.pattern &&
      !['checkbox', 'select', 'date', 'file'].includes(question.htmlInputType)
    ) {
      const re = RegexParser(question.pattern);
      validators.push(Validation(pattern(re, getValidationMessage(question))));
    }
    return {
      ...acc,
      [answer.id]: validators
    };
  }, {});

export const getValidationMessage = (question: PacketQuestion): string => {
  switch (question.htmlInputType) {
    case 'number': {
      return 'Please enter a number';
    }
    case 'email': {
      return 'Please enter a valid email';
    }
    case 'url': {
      return 'Please enter a valid URL';
    }
    case 'tel': {
      return 'Please enter a valid phone number';
    }
    default: {
      return 'Invalid value.';
    }
  }
};

export const validatePartially = (values: PacketFormValues, schema: Schema): unknown => {
  // this reducer removes the properties that have empty values from both the form values and the schema
  const [partialValues, partialSchema] = Object.entries(values).reduce(
    ([pv, ps], [fieldId, value]) => {
      if (isFieldEmpty(value) || schema[fieldId] === undefined) {
        return [pv, ps];
      } else {
        return [
          { ...pv, [fieldId]: value },
          { ...ps, [fieldId]: schema[fieldId] }
        ];
      }
    },
    [{}, {}] as [PacketFormValues, Schema]
  );
  return validate(partialSchema, partialValues).fold(
    x => x,
    _ => null
  );
};

export const isFieldEmpty = (
  value: string | Date | FileInputValue[] | boolean[] | boolean
): boolean => {
  const emptyValues = ['', null, undefined] as Array<
    string | Date | FileInputValue[] | boolean[] | boolean
  >;
  if (emptyValues.includes(value)) {
    return true;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return true;
    }
    if (typeof value[0] === 'boolean') {
      if ((value as boolean[]).filter(v => v !== false).length === 0) {
        return true;
      }
    }
  }
  return false;
};

export const getQuestionId = (fieldId: string): string =>
  fieldId.match(/^\[([^[\]]*)\]/)?.[1];

/* other utitlies */

export const getReuseFlagValues = (
  questions: PacketQuestion[]
): Record<string, boolean> =>
  questions.reduce(
    (acc, question) =>
      question.matchingQuestionsCount > 0 ? { ...acc, [question.id]: true } : acc,
    {}
  );

export const getPacketStatus = (
  isIntegration: boolean,
  previousStatus: 'published' | 'unpublished',
  submitType: 'publish' | 'save' | 'save-configuration'
) => {
  if (isIntegration) {
    return previousStatus;
  }
  if (submitType === 'publish') {
    return 'published';
  }
  return 'unpublished';
};
