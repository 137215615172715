/* eslint-disable no-console */
// ^^^^ this is the only file where this should be disabled
// Keep this file as independent as possible, only import types, or methods from external packages.
import Appsignal from '@appsignal/javascript';
import { isApiError } from './api/types';
import { getGlobal } from '../utils/global';
import { store } from './store';

const ENVIRONMENT = getGlobal('environment');
export const isProduction = ENVIRONMENT === 'production';
export const isDevelopment = ENVIRONMENT === 'development';

// Change this when testing error reporting to appsignal. Should be changed back when done.
const LOG_DEVELOPMENT_ERRORS_TO_APPSIGNAL = false;
const namespace = 'Frontend';

const appsignal = new Appsignal({
  key: getGlobal('appsignalApiKey'),
  revision: __REVISION_HASH__ || 'unknown'
});

console.log(`👋 Tartle environment: ${getGlobal('environment')}`);
if (!isProduction) {
  console.log('🔒 Environment Variables', {
    ...(window.tartleGlobal?.envConfig || {}),
    revisionHash: __REVISION_HASH__ || 'unknown'
  });
}

const consoleLoggers = {
  error: console.error,
  info: console.info,
  warn: console.warn,
  exception: console.error
};
export type LogType = keyof typeof consoleLoggers;

export type ExceptionOptions = {
  error: unknown; // handling for ApiError, string, Error, or anything else.
  errorType?: string; // Create a custom error type of this name (unless error is an instance of Error)
  tags?: Record<string, string | boolean | number>;
};

// In development/stage, we are able to only log certain types of information based on where it's originating from
const isLoggable = (namespace: string, action: string) => {
  if (!isProduction && window.localStorage) {
    if (
      store.getItem('ALL') ||
      store.getItem(namespace.toLowerCase()) ||
      store.getItem(action.toLowerCase())
    ) {
      return true;
    }
  }
  return false;
};

// Logs postMessage events.
window.addEventListener('message', event => {
  if (isLoggable('', 'message')) {
    consoleLoggers.info('message', event.data);
  }
});

const consoleLogger = (
  logType: LogType,
  namespace: string,
  action: string,
  args: unknown[]
) => {
  if (isLoggable(namespace, action)) {
    consoleLoggers[logType](logType, ...args);
  }
};

const logger = (action: string) => {
  // on development and staging, we use localstorage to define what types of erros to show in the console.
  // Depending on whether a variable with the same name as the action (lowercased) is defined in localstorage
  // Example:
  //   In your code you can do logger("tcoinwallet").error(23, "abc", someobject);
  //   To see this error in the console, on your browser dev tools you would do localStorage.setItem("tcoinwallet", true); (use lowercase)
  //   From then on, all log messages in namespace frontend and action tcoinwallet will be shown on the browser console.
  //
  // IF you wish to see all logging for all namespaces and actions, set "ALL" (all caps) in localStorage to true
  // No console logging works in production.

  return {
    groupCollapsed: (label: string) => {
      if (isLoggable('', action)) {
        console.groupCollapsed(label);
      }
    },
    groupEnd: () => console.groupEnd(),
    info: (...args: unknown[]) => consoleLogger('info', namespace, action, args),
    warn: (...args: unknown[]) => consoleLogger('warn', namespace, action, args),
    error: (...args: unknown[]) => consoleLogger('error', namespace, action, args),
    exception: (options: ExceptionOptions) => {
      if (!isProduction) {
        consoleLogger('exception', namespace, action, [options]);
      }
      if (shouldLogToAppsignal(namespace, action, options)) {
        try {
          options.tags = options.tags || {};
          let error: Error;
          const span = appsignal.createSpan(span => {
            span.setNamespace(namespace.toLowerCase());
            span.setAction(action.toLowerCase());
            if (options.error instanceof Error) {
              error = options.error;
            } else {
              const errorMessage = prepareErrorMessage(options.error);
              if (options.errorType) {
                const CustomError = createCustomError(options.errorType);
                error = new CustomError(errorMessage);
              }
              if (!error || !error.message) {
                error = new Error(errorMessage);
              }
            }

            span.setError(error);
            span.setTags(prepareTags(options.tags));
          });

          appsignal.send(span);
        } catch (error) {
          console.error('Error logging to appsignal', error);
        }
      }
    }
  };
};

export type Log = ReturnType<typeof logger>;

const shouldLogToAppsignal = (
  namespace: string,
  action: string,
  options: ExceptionOptions
) => {
  if (isDevelopment && !LOG_DEVELOPMENT_ERRORS_TO_APPSIGNAL) {
    return false;
  }

  return !isErrorWhitelisted({ namespace, action, options });
};

const prepareErrorMessage = (error: unknown): string => {
  if (!error) {
    return 'An unknown error occurred.';
  }
  if (typeof error === 'string') {
    return error;
  }
  let errorMessage: string;
  try {
    errorMessage = JSON.stringify(error);
  } catch (e) {
    console.error('Error parsing error message', e);
  }
  if ([undefined, null, '""', '{}', '[]', 'undefined', 'null'].includes(errorMessage)) {
    errorMessage = 'An unknown error occurred.';
  }
  return errorMessage;
};

const prepareTags = (tags: Record<string, string | boolean | number>) =>
  Object.keys(tags).reduce<Record<string, string>>((acc, key) => {
    acc[key] = tags[key].toString();
    return acc;
  }, {});

const groupCollapsed = (action: string, label: string): void => {
  if (isLoggable('', action)) {
    console.groupCollapsed(label);
  }
};

const groupEnd = (): void => console.groupEnd();

export { appsignal, groupCollapsed, groupEnd, logger };

export const isErrorWhitelisted = ({
  namespace,
  action,
  options
}: {
  namespace: string;
  action: string;
  options: ExceptionOptions;
}): boolean => {
  if (options.error instanceof Error) {
    return false;
  }
  try {
    const errors: string[] = [];

    if (typeof options.error === 'string' && options.error.length) {
      errors.push(options.error);
    }
    if (isApiError(options.error)) {
      if (Array.isArray(options.error)) {
        errors.push(...options.error);
      } else {
        errors.push(...Object.values(options.error).flat());
      }
    }

    const whitelistedErrors = [...whitelist['unrestricted']];
    if (whitelist[namespace]) {
      whitelistedErrors.push(...whitelist[namespace]);
    }
    if (whitelist[action]) {
      whitelistedErrors.push(...whitelist[action]);
    }

    return whitelistedErrors.some(error => errors.includes(error));
  } catch (error) {
    consoleLoggers.error('Error checking if error is whitelisted', error);
  }
  return false;
};

const whitelist: Record<string, string[]> = {
  login: ['Please confirm your email address.'],
  unrestricted: ['Captcha challenge failed']
};

function createCustomError(name: string) {
  // Return a class that extends Error if supported by the browser
  try {
    return class CustomError extends Error {
      constructor(message: string) {
        super(message); // Call the superclass constructor with the message
        this.name = name; // Set the name property to the custom error's name
        if (typeof Error.captureStackTrace === 'function') {
          Error.captureStackTrace(this, this.constructor);
        }
      }
    };
  } catch (e) {
    return Error;
  }
}
