import * as React from 'react';
import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { FComponent } from '../../../types/common';
import { FieldErrors } from './FieldErrors';
import { classNames } from '../../../utils';

type RadioGroupProps = {
  type?: 'button' | 'simple' | 'tab' | 'tab-rounded';
  name: string;
  label?: string;
  ariaLabel?: string;
  value: string;
  instruction?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  visited?: boolean;
  errors?: string[];
  disabled?: boolean;
  containerClassName?: string;
};

export const RadioGroup: FComponent<RadioGroupProps> = ({
  type = 'simple',
  name,
  label,
  ariaLabel,
  value,
  onChange,
  visited,
  errors,
  disabled = false,
  instruction,
  containerClassName = 'radiogroup',
  className = 'radiogroup-options-column',
  children
}) => {
  if (!label && !ariaLabel) {
    throw new Error('RadioGroup must have a label or ariaLabel (or both)');
  }

  const handleChange = (value: string) => {
    const event: React.ChangeEvent<HTMLInputElement> = {
      target: {
        name,
        type: 'radiogroup',
        value
      }
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };
  return (
    <HeadlessRadioGroup
      value={value}
      onChange={handleChange}
      className={containerClassName}
      disabled={disabled}>
      <HeadlessRadioGroup.Label
        className={classNames('radiogroup-label', label ? '' : 'sr-only')}>
        {label ? label : ariaLabel}
      </HeadlessRadioGroup.Label>
      {instruction ? <div className="instructions">{instruction}</div> : null}
      <div
        className={classNames(
          className,
          type.includes('tab') ? `radiogroup-${type}-container` : null
        )}>
        {React.Children.map(children, child =>
          React.cloneElement(child as React.ReactElement, { type })
        )}
      </div>
      <FieldErrors visited={visited} errors={errors} />
    </HeadlessRadioGroup>
  );
};
