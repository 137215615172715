import * as React from 'react';
import { FComponent } from '../../../types/common';
import { FieldErrors } from './FieldErrors';

type CheckboxProps = {
  name: string;
  label?: string | JSX.Element;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  visited?: boolean;
  errors?: string[];
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel?: string;
};

// for a full length checkbox/label add classname "full"
// to have the checkbox on the right, add classname "right"
// by default the label is bolded, using the "light" classname will make the font lighter
const Checkbox: FComponent<CheckboxProps> = ({
  name,
  label,
  onChange,
  value,
  checked = false,
  visited,
  indeterminate = false,
  errors,
  disabled = false,
  className = 'checkbox-default',
  ariaLabel
}) => {
  if (!ariaLabel && !label) {
    throw new Error("Checkbox: 'ariaLabel' or 'label' is required");
  }

  const checkboxRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <div className={className}>
      <div className="checkbox-component">
        <input
          ref={checkboxRef}
          id={name}
          name={name}
          value={value}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <label htmlFor={name} className="field-label">
          <div>{label}</div>
        </label>
      </div>
      <FieldErrors visited={visited} errors={errors} />
    </div>
  );
};

export { Checkbox };
