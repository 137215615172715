import dayjs from 'dayjs';
import * as React from 'react';
import { Input } from './Input';
import CalendarIcon from '../Icons/CalendarIcon';
import { classNames } from '../../../utils';
// import 'react-day-picker/dist/style.css';
import { usePopper } from 'react-popper';
import { FieldErrors } from './FieldErrors';
import { Popover } from '@headlessui/react';
import { DayPicker } from 'react-day-picker';
import { FComponent } from '../../../types/common';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  createChangeEvent,
  CustomTarget,
  SimulatedChangeEvent
} from '../../../hooks/useForm';
import { DataDisclosure, DataDisclosureTooltip } from '../DataDisclosureTooltip';

// Documentation: https://react-day-picker.js.org/
// Note: Null gives the form error and undefined is the initial value(no errro)

dayjs.extend(customParseFormat);

const ACCEPTABLE_DATE_FORMATS = [
  'YYYY/MM/DD',
  'YYYY/M/DD',
  'YYYY/MM/D',
  'YYYY/M/D',
  'YYYY-MM-DD',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY-M-D',
  'MM/DD/YYYY',
  'M/DD/YYYY',
  'MM/D/YYYY',
  'M/D/YYYY',
  'MM-DD-YYYY',
  'M-DD-YYYY',
  'MM-D-YYYY',
  'M-D-YYYY'
];

const DEFAULT_DATE_FORMAT = ACCEPTABLE_DATE_FORMATS[0];

type DateInputProps = {
  name: string;
  onChange: (event: SimulatedChangeEvent<CustomTarget>) => void;
  label?: string;
  disclosure?: DataDisclosure;
  value?: Date;
  placeholder?: string;
  disabled?: boolean;
  dateInputClass?: string;
  ariaLabel?: string;
  className?: string;
  errors?: string[];
  visited?: boolean;
  fromYear?: number;
  toYear?: number;
  fromDate?: Date;
  toDate?: Date;
  captionLayout?: boolean;
  // A11y Notice: This prop should be avoided unless this element is the logical and focal point of the entire screen.
  // You will have to disable the a11y warning for autofocus when you use it.
  autoFocus?: boolean;
  // TODO: remove 'optional' prop from DateInput component, validation should be handled at the form level.
  optional?: boolean;
};

const DateInput: FComponent<DateInputProps> = ({
  name,
  onChange,
  label,
  disclosure,
  value,
  placeholder = 'YYYY/MM/DD',
  disabled = false,
  dateInputClass = 'date-panel',
  ariaLabel,
  className = '',
  errors,
  visited,
  fromYear = null,
  toYear = null,
  fromDate = null,
  toDate = null,
  captionLayout = null,
  autoFocus = false,
  optional = false
}) => {
  if (!ariaLabel && !label) {
    throw new Error("DateInput: 'ariaLabel' or 'label' is required");
  }

  const [inputValue, setInputValue] = React.useState<string>(
    value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : ''
  );

  const [referenceElement, setReferenceElement] = React.useState<HTMLInputElement>();
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement>();
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    { placement: 'bottom-end' }
  );

  React.useEffect(() => {
    if (autoFocus && referenceElement) {
      referenceElement.focus();
    }
  }, [autoFocus, referenceElement]);

  const handleDateChange = (date: Date) => {
    onChange(createChangeEvent<CustomTarget>({ name, type: 'datepicker', value: date }));
  };

  const updateAllStates = (input: string, newDate: Date) => {
    setInputValue(input);
    handleDateChange(newDate);
  };

  const handleDaySelectChange = (date: Date) => {
    // Unclicking will reset the values to initial state with no form error(undefined)
    if (!date && !optional) {
      updateAllStates('', null);
    } else if (!date) {
      updateAllStates('', undefined);
    } else {
      updateAllStates(dayjs(date).format(DEFAULT_DATE_FORMAT), date);
    }
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const userInput = event.target.value;
    const date = dayjs(userInput, ACCEPTABLE_DATE_FORMATS, true);

    if (date.isValid()) {
      updateAllStates(userInput, date.toDate());
    } else if (userInput === '' && !optional) {
      updateAllStates('', null);
    } else if (userInput === '') {
      updateAllStates('', undefined);
    } else {
      // Raise form error if the input value is not a valid date or
      // not an acceptable format, but still show the calendar.
      updateAllStates(userInput, dayjs(null).toDate());
    }
  };

  const isDateValid = value instanceof Date && !isNaN(value.getTime());

  return (
    <div className={classNames('field-container', className)}>
      {label ? (
        <div className={classNames('input-label', disclosure ? 'with-disclosure' : '')}>
          <label htmlFor={name}>{label}</label>
          {disclosure ? <DataDisclosureTooltip disclosure={disclosure} /> : null}
        </div>
      ) : null}

      <Popover className="date-input-manual">
        <Input
          placeholder={placeholder}
          name={name}
          onChange={handleInputChange}
          value={inputValue}
          disabled={disabled}
          ref={setReferenceElement}
        />
        <FieldErrors visited={visited} errors={errors} />

        <Popover.Button
          className="date-button-dropdown"
          disabled={disabled}
          aria-label={ariaLabel}>
          <CalendarIcon />
        </Popover.Button>

        <Popover.Panel
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
          className={dateInputClass}>
          {({ close }) => (
            <DayPicker
              mode="single"
              selected={isDateValid ? value : null}
              defaultMonth={isDateValid ? value : null}
              captionLayout={captionLayout ? 'dropdown' : null}
              fromYear={fromYear}
              toYear={toYear}
              fromDate={fromDate}
              toDate={toDate}
              onSelect={(date: Date) => {
                handleDaySelectChange(date);
                close();
              }}
            />
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export { DateInput };
