import FingerprintJS, { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import { buildUrl, DOMAIN, Endpoint } from '.';
import { objectToUrlParams, toCamelCase, toSnakeCase } from '../../utils';
import { ExceptionOptions, logger } from '../logger';
import { memoize } from '../../utils/memoize';
import { ApiErrorResult, isApiError } from './types';
const log = logger('api');

const getAuth = () => {
  const csrfParamName = document
    .querySelector('meta[name="csrf-param"]')
    ?.getAttribute('content');
  const csrfTokenValue = document
    .querySelector('meta[name="csrf-token"]')
    ?.getAttribute('content');

  if (csrfParamName && csrfTokenValue) {
    return { [csrfParamName]: csrfTokenValue };
  }
  return {};
};

export async function client<T = unknown>({
  method = 'GET',
  endpoint,
  urlParams = {},
  data,
  headers,
  responseContentType
}: {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  urlParams?: Record<string, string | number>;
  data?: Record<string, unknown>;
  headers?: Record<string, string>;
  responseContentType: ContentType;
}): Promise<T> {
  const { url: action } = processUrl(endpoint);
  const actionLog = logger(action);
  const fullEndpoint = endpoint.startsWith('http')
    ? `${endpoint}${objectToUrlParams(urlParams)}`
    : `${DOMAIN}/${endpoint}${objectToUrlParams(urlParams)}`;

  log.groupCollapsed(`REQUEST: ${fullEndpoint}`);
  if (typeof window === 'undefined') {
    throw new Error('calling Api client on the server');
  }
  const csrfAuth = getAuth();

  let configBody: string;
  if (method !== 'GET') {
    configBody = JSON.stringify({
      ...(data || {}),
      ...csrfAuth
    });
  }

  const config: RequestInit = {
    method,
    headers: { 'content-type': 'application/json', ...headers },
    body: configBody
  };

  log.info({ config, method, data });
  let response;
  try {
    response = await window.fetch(fullEndpoint, config);
  } catch (error) {
    const token = document
      .querySelector('meta[name=csrf-token]')
      ?.getAttribute('content');
    actionLog.error({ error, csrfTokenPresent: !!token });
    log.groupEnd();
    return Promise.reject(['Unable to fetch data, please try again']);
  }

  log.groupEnd();
  return parseResponse(response, fullEndpoint, responseContentType);
}

const parseResponse = async (
  response: Response,
  endpoint: string,
  expectedContentType: ContentType
) => {
  let result;
  const { url: action, matches } = processUrl(endpoint);
  const actionLog = logger(action);
  const actualContentType =
    extractMimeType(response.headers.get('content-type')) || 'no_content_type';

  // Prepare an error object to collect information as we go in case we need it
  const exceptionOptions: ExceptionOptions = {
    error: '',
    errorType: httpStatusCodes[response.status],
    tags: {
      ...matches,
      endpoint,
      status: response.status,
      contentType: actualContentType,
      expectedContentType
    }
  };

  const isBodyEmpty = await checkIsBodyEmpty(response, exceptionOptions);

  // If response has no content type, we'll parse and log an error later, let's add some debug info
  if (actualContentType === 'no_content_type') {
    exceptionOptions.tags = {
      ...exceptionOptions.tags,
      header: response.headers.get('content-type')
    };
  }

  log.info(
    `${endpoint} RESPONSE`,
    response.status,
    response.statusText,
    response.ok ? 'OK' : 'NOT OK'
  );

  if (response.status === 204 || (response.status === 202 && isBodyEmpty)) {
    return Promise.resolve(null);
  }

  // Try to parse the response given its content type header
  if (!isBodyEmpty && isAllowedContentType(actualContentType)) {
    const parser = contentTypeParsers[actualContentType];
    try {
      result = await parser(response);
    } catch (error) {
      // Silence transient network errors on appsignal
      if (error?.message !== 'Failed to fetch') {
        actionLog.exception({
          ...exceptionOptions,
          error: error?.message || 'Unknown error',
          errorType: `[${response.status}] Error parsing response`
        });
      }
      return Promise.reject([`Error parsing response (${response.status})`]);
    }
  }

  log.info('RESPONSE PAYLOAD', result);

  // Resolve with the result if succeeded and the content type is what we expected.
  if (response.ok) {
    if (result && actualContentType !== expectedContentType) {
      actionLog.exception({
        ...exceptionOptions,
        error: result,
        errorType: 'Unexpected content type',
        tags: { isCloudflare: checkForCloudflare(result), ...exceptionOptions.tags }
      });
      return Promise.reject([`Unexpected content type (${response.status})`]);
    }
    return Promise.resolve(result);
  } else {
    // The error is either in a format our components can use or an unknown issue.
    if (isApiErrorResult(result)) {
      if ([400, 403, 406].includes(response.status) || response.status >= 500) {
        actionLog.exception({ ...exceptionOptions, error: result.errors });
      }
      return Promise.reject(result.errors);
    } else {
      const logFn = coalescedErrorCodes.includes(response.status) ? log : actionLog;
      logFn.exception({
        ...exceptionOptions,
        error: result || 'Unexpected error',
        tags: {
          cause: 'Unexpected error format',
          isCloudflare: checkForCloudflare(result),
          ...exceptionOptions.tags
        }
      });

      return Promise.reject([`Server error (${response.status})`]);
    }
  }
};

let visitorId: string;
export const getVisitorId = async () => {
  if (!visitorId) {
    const agent: Agent = await FingerprintJS.load();
    const fingerprint: GetResult = await agent?.get();
    visitorId = fingerprint?.visitorId;
  }

  return visitorId;
};

// Client wrapper for tartle endpoints
export const tartleClient = async <T = unknown>({
  method = 'GET',
  endpoint: [endpointUrl, apiVersion, responseContentType = 'application/json'],
  endpointParams = [],
  urlParams = {},
  authToken,
  data
}: {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: Endpoint;
  endpointParams?: string[];
  urlParams?: Record<string, string | number>;
  authToken?: string;
  data?: Record<string, unknown>;
}): Promise<T> => {
  const visitorId = await getVisitorId();

  return client<T>({
    endpoint: buildUrl(endpointUrl, endpointParams),
    method,
    urlParams: toSnakeCase(urlParams),
    data: toSnakeCase(data, true, 8),
    headers: buildHeaders(apiVersion, authToken, visitorId),
    responseContentType
  }).then(response => toCamelCase(response, true, 8) as T);
};

const buildHeaders = (apiVersion?: string, authToken?: string, visitorId?: string) => {
  if (!apiVersion && !authToken && !visitorId) {
    return undefined;
  }
  const headers: Record<string, string> = {};
  if (apiVersion) {
    headers['X-API-Version'] = apiVersion;
  }
  if (authToken) {
    headers['Authorization'] = `Bearer ${authToken}`;
  }
  if (visitorId) {
    headers['X-Visitor-Id'] = visitorId;
  }
  return headers;
};

export const isApiErrorResult = (value: unknown): value is ApiErrorResult => {
  if (typeof value !== 'object' || !('errors' in value)) {
    return false;
  }

  if (isApiError((value as Record<'errors', unknown>)['errors'])) {
    return true;
  } else {
    return false;
  }
};

// parseCSV takes in a string of CSV data with a comma separator and returns an array of objects
// with the keys being the column headers and the values being the values in the CSV
export const parseCSV = (csv: string) => {
  if (!csv) return [];
  const rows = csv.split('\n');
  const data = rows.map(row => row.split(','));
  const headers = data[0];
  data.shift();
  return data.map(row => {
    const obj: Record<string, string> = {};
    row.forEach((value, index) => {
      obj[headers[index]] = value;
    });
    return obj;
  });
};

// Utility function for using an api endpoint as an action for appsignal. It replaces possible IDs with a number and
// returns the matches in an object that can be used to set tags in the appsignal report.
export const processUrl = memoize(
  (originalUrl: string): { url: string; matches: Record<number, string> } => {
    const basePath = originalUrl.replace(/^(https?:\/\/)?[^/]+|[?#].*$/g, '');

    let count = 1;
    const matches: { [key: number]: string } = {};

    // Regex to find 13 letter words according to the conditions
    const regex = /\/([a-zA-Z0-9]{13})(?=\/|\.json|\.csv|$)/g;

    // Replace matched patterns with sequential numbers
    const replacedPath = basePath.replace(regex, (_, p1: string) => {
      matches[count] = p1;
      return '/' + count++;
    });

    return {
      url: replacedPath,
      matches
    };
  }
);

// Object with numbered keys for every http status code with values of the status message, include all possible status codes
export const httpStatusCodes: Record<number, string> = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy uthentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported'
};

// These errors will all be logged under the same error, all others will be logged separately by endpoint.
const coalescedErrorCodes = [502, 504, 503];

const getBodyText = async (response: Response) => response.text();
const getBodyJson = async (response: Response) => response.json();

const contentTypeParsers = {
  'text/csv': getBodyText,
  'text/html': getBodyText,
  'text/plain': getBodyText,
  'application/json': getBodyJson,
  no_content_type: getBodyText
};
export type ContentType = keyof typeof contentTypeParsers;
const allowedContentTypes = Object.keys(contentTypeParsers) as ContentType[];

const extractMimeType = (contentType?: string) => contentType?.split(';')[0];

// When passed nothing, it will return true for no_content_type
const isAllowedContentType = (
  contentType = 'no_content_type'
): contentType is ContentType =>
  allowedContentTypes.some(act => contentType.includes(act));

// used for setting a tag for appsignal reports that allow us to quickly find cloudflare issues.
const checkForCloudflare = (result: unknown) => {
  if (typeof result !== 'string') {
    return false;
  }
  return result.includes('cloudflare');
};

// checks if the response has a body reliably, cloning the response first
const checkIsBodyEmpty = async (response: Response) => {
  const clone = response.clone();

  return clone
    .blob()
    .then(blob => blob.size === 0)
    .catch(() => {
      log.error("Error getting response size, can't determine if body is empty");
      return false;
    });
};
