import * as React from 'react';
import { buildUrl, tartleClient } from '../lib/api/index';
import { handleApiErrors } from '../utils';
import { useAsync } from './useAsync';
import type { UseAsyncResult } from './useAsync';
import type { Endpoint } from '../lib/api';
import { logger } from '../lib/logger';
import { ApiErrors } from '../lib/api/types';

type UrlParams = Record<string, string | number>;
type FetchData = Record<string, unknown>;
type UseRequestParams<S> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: Endpoint;
  initialData?: S;
  transform?: (data: unknown) => S;
};

export type RequestWrapper<R extends Record<string, unknown>, S> = (
  args?: {
    endpointParams?: string[];
    urlParams?: R;
    data?: R;
    authToken?: string;
  },
  setFormErrors?: (errors: Record<string, string[]>) => void
) => Promise<S>;

export const useApiRequest = <R extends UrlParams | FetchData, S>({
  method,
  endpoint: [url, apiVersion, responseContentType],
  initialData,
  transform = (data: S) => data
}: UseRequestParams<S>): {
  request: RequestWrapper<R, S>;
} & UseAsyncResult<S, ApiErrors> => {
  if (!url) {
    throw new Error('url is required in useApiRequest');
  }
  const [stableTransform] = React.useState(() => transform);
  const { run, ...useAsyncResult } = useAsync<S, ApiErrors>({ data: initialData });

  const request = React.useCallback<RequestWrapper<R, S>>(
    async ({ endpointParams, urlParams, data, authToken } = {}, setFormErrors) =>
      run(
        tartleClient({
          endpoint: [url, apiVersion, responseContentType],
          endpointParams,
          authToken,
          method,
          urlParams: urlParams as UrlParams,
          data
        }).then(stableTransform)
      ).catch((errors: unknown) => {
        const log = logger(buildUrl(url, endpointParams));
        handleApiErrors(log, setFormErrors)(errors);
        return Promise.reject(errors);
      }),
    [run, url, method, apiVersion, responseContentType, stableTransform]
  );

  return { request, run, ...useAsyncResult };
};
