// lightweight function caching
export const memoize = <T>(
  fn: (...args: unknown[]) => T
): ((...args: unknown[]) => T) => {
  const cache: Record<string, T> = {};
  return (...args: unknown[]): T => {
    const argsKey = JSON.stringify(args);
    if (!cache[argsKey]) {
      cache[argsKey] = fn(...args);
    }
    return cache[argsKey];
  };
};
