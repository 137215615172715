import { logger } from '../lib/logger';
import { memoize } from './memoize';

export const SCREEN_SIZES = {
  xs: 420,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

export const paths = {
  login: '/sign_in',
  confirmEmail: '/confirmation/new',
  forgotPassword: '/password/new',
  registration: {
    seller: '/registration',
    buyer: '/buyers/sign_up'
  },
  wallets: {
    seller: '/sellers/wallets',
    buyer: '/buyers/wallets'
  },
  buyerBids: '/buyers/bids',
  sellerWallet: '/sellers/wallet',
  buyerWallet: '/buyers/wallet',
  sellerDiscover: '/sellers/categories',
  sellerSettings: {
    settings: '/sellers/account_settings',
    accountInfo: '/sellers/account_settings/account_info',
    deleteAccount: '/sellers/account_settings/delete_account',
    notification: '/sellers/account_settings/notifications',
    badges: '/sellers/account_settings/badges',
    syncAccounts: '/sellers/sync_accounts',
    socialAccounts: '/sellers/sync_accounts/social_accounts',
    professionalAccounts: '/sellers/sync_accounts/professional_accounts',
    financialAccounts: '/sellers/sync_accounts/financial_accounts',
    healthAccounts: '/sellers/sync_accounts/health_accounts',
    invite: '/sellers/account_settings/invite',
    bank: '/sellers/account_settings/bank',
    changePassword: '/sellers/account_settings/change_password',
    twoFactorAuth: '/sellers/account_settings/two_factor_auth',
    help: '/sellers/help',
    usingTartle: '/sellers/help/using_tartle',
    loginAndTroubleshooting: '/sellers/help/troubleshooting',
    privacyAndSafety: '/sellers/help/privacy_safety',
    tartleWallet: '/sellers/help/wallet',
    withdrawalAndPaypal: '/sellers/help/withdrawals',
    accessibility: '/sellers/account_settings/accessibility',
    signOut: '/sellers/sign_out',
    profileValues: '/sellers/account_settings/market_values',
    dataVault: '/sellers/data_vault',
    editProfile: '/sellers/account_settings/edit_profile'
  },
  sellerProfile: '/sellers/profile',
  sellerProfileBadges: '/sellers/profile#badges',
  sellerPackets: {
    view: (packetId: string): string => `/sellers/packets/${packetId}`,
    edit: (packetId: string): string => `/sellers/questions?packet_id=${packetId}`
  },
  buyerSettings: {
    settings: '/buyers/account_settings',
    editProfile: '/buyers/account_settings/edit_profile',
    accountInfo: '/buyers/account_settings/account_info',
    changePassword: '/buyers/account_settings/change_password',
    twoFactorAuth: '/buyers/account_settings/two_factor_auth',
    help: '/buyers/help',
    bidFilters: '/buyers/help/bid_filters',
    accessibility: '/buyers/account_settings/accessibility',
    signIn: '/buyers/sign_in',
    signOut: '/buyers/sign_out'
  },
  buyersPacket: (packetId: string) => `/buyers/packets/${packetId}`,
  suggestPacket: '/buyers/suggestions/new',
  buyerSearch: '/buyers/packets',
  buyerCohorts: '/buyers/cohorts',
  buyerCohort: (cohortId: string) => `/buyers/cohorts/${cohortId}`,
  buyerFolder: (folderId: string) => `/buyers/cohorts/folder/${folderId}`,
  moderatorBuyers: '/moderator/buyers'
};

export const countriesByCountryCode = {
  AFG: 'Afghanistan',
  ALA: 'Åland Islands',
  ALB: 'Albania',
  DZA: 'Algeria',
  ASM: 'American Samoa',
  AND: 'Andorra',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ATA: 'Antarctica',
  ATG: 'Antigua and Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ABW: 'Aruba',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BHS: 'Bahamas',
  BHR: 'Bahrain',
  BGD: 'Bangladesh',
  BRB: 'Barbados',
  BLR: 'Belarus',
  BEL: 'Belgium',
  BLZ: 'Belize',
  BEN: 'Benin',
  BMU: 'Bermuda',
  BTN: 'Bhutan',
  BOL: 'Bolivia (Plurinational State of)',
  BES: 'Bonaire, Sint Eustatius and Saba',
  BIH: 'Bosnia and Herzegovina',
  BWA: 'Botswana',
  BVT: 'Bouvet Island',
  BRA: 'Brazil',
  IOT: 'British Indian Ocean Territory',
  BRN: 'Brunei Darussalam',
  BGR: 'Bulgaria',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  CPV: 'Cabo Verde',
  KHM: 'Cambodia',
  CMR: 'Cameroon',
  CAN: 'Canada',
  CYM: 'Cayman Islands',
  CAF: 'Central African Republic',
  TCD: 'Chad',
  CHL: 'Chile',
  CHN: 'China',
  CXR: 'Christmas Island',
  CCK: 'Cocos (Keeling) Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  COG: 'Congo',
  COD: 'Congo (Democratic Republic of the)',
  COK: 'Cook Islands',
  CRI: 'Costa Rica',
  CIV: "Côte d'Ivoire",
  HRV: 'Croatia',
  CUB: 'Cuba',
  CUW: 'Curaçao',
  CYP: 'Cyprus',
  CZE: 'Czech Republic',
  DNK: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  SLV: 'El Salvador',
  GNQ: 'Equatorial Guinea',
  ERI: 'Eritrea',
  EST: 'Estonia',
  SWZ: 'eSwatini',
  ETH: 'Ethiopia',
  FLK: 'Falkland Islands (Malvinas)',
  FRO: 'Faroe Islands',
  FJI: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  GUF: 'French Guiana',
  PYF: 'French Polynesia',
  ATF: 'French Southern Territories',
  GAB: 'Gabon',
  GMB: 'Gambia',
  GEO: 'Georgia',
  DEU: 'Germany',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRC: 'Greece',
  GRL: 'Greenland',
  GRD: 'Grenada',
  GLP: 'Guadeloupe',
  GUM: 'Guam',
  GTM: 'Guatemala',
  GGY: 'Guernsey',
  GIN: 'Guinea',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HTI: 'Haiti',
  HMD: 'Heard Island and McDonald Islands',
  VAT: 'Holy See',
  HND: 'Honduras',
  HKG: 'Hong Kong',
  HUN: 'Hungary',
  ISL: 'Iceland',
  IND: 'India',
  IDN: 'Indonesia',
  IRN: 'Iran (Islamic Republic of)',
  IRQ: 'Iraq',
  IRL: 'Ireland',
  IMN: 'Isle of Man',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JPN: 'Japan',
  JEY: 'Jersey',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KIR: 'Kiribati',
  PRK: "Korea (Democratic People's Republic of)",
  KOR: 'Korea (Republic of)',
  XKX: 'Kosovo',
  KWT: 'Kuwait',
  KGZ: 'Kyrgyzstan',
  LAO: "Lao People's Democratic Republic",
  LVA: 'Latvia',
  LBN: 'Lebanon',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libya',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MAC: 'Macao',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malta',
  MHL: 'Marshall Islands',
  MTQ: 'Martinique',
  MRT: 'Mauritania',
  MUS: 'Mauritius',
  MYT: 'Mayotte',
  MEX: 'Mexico',
  FSM: 'Micronesia (Federated States of)',
  MDA: 'Moldova (Republic of)',
  MCO: 'Monaco',
  MNG: 'Mongolia',
  MNE: 'Montenegro',
  MSR: 'Montserrat',
  MAR: 'Morocco',
  MOZ: 'Mozambique',
  MMR: 'Myanmar',
  NAM: 'Namibia',
  NRU: 'Nauru',
  NPL: 'Nepal',
  NLD: 'Netherlands',
  NCL: 'New Caledonia',
  NZL: 'New Zealand',
  NIC: 'Nicaragua',
  NER: 'Niger',
  NGA: 'Nigeria',
  NIU: 'Niue',
  NFK: 'Norfolk Island',
  MKD: 'North Macedonia',
  MNP: 'Northern Mariana Islands',
  NOR: 'Norway',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PLW: 'Palau',
  PSE: 'Palestine, State of',
  PAN: 'Panama',
  PNG: 'Papua New Guinea',
  PRY: 'Paraguay',
  PER: 'Peru',
  PHL: 'Philippines',
  PCN: 'Pitcairn',
  POL: 'Poland',
  PRT: 'Portugal',
  PRI: 'Puerto Rico',
  QAT: 'Qatar',
  REU: 'Réunion',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  BLM: 'Saint Barthélemy',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha',
  KNA: 'Saint Kitts and Nevis',
  LCA: 'Saint Lucia',
  MAF: 'Saint Martin (French part)',
  SPM: 'Saint Pierre and Miquelon',
  VCT: 'Saint Vincent and the Grenadines',
  WSM: 'Samoa',
  SMR: 'San Marino',
  STP: 'Sao Tome and Principe',
  SAU: 'Saudi Arabia',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapore',
  SXM: 'Sint Maarten (Dutch part)',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SLB: 'Solomon Islands',
  SOM: 'Somalia',
  ZAF: 'South Africa',
  SGS: 'South Georgia and the South Sandwich Islands',
  SSD: 'South Sudan',
  ESP: 'Spain',
  LKA: 'Sri Lanka',
  SDN: 'Sudan',
  SUR: 'Suriname',
  SJM: 'Svalbard and Jan Mayen',
  SWE: 'Sweden',
  CHE: 'Switzerland',
  SYR: 'Syrian Arab Republic',
  TWN: 'Taiwan, Province of China',
  TJK: 'Tajikistan',
  TZA: 'Tanzania, United Republic of',
  THA: 'Thailand',
  TLS: 'Timor-Leste',
  TGO: 'Togo',
  TKL: 'Tokelau',
  TON: 'Tonga',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TKM: 'Turkmenistan',
  TCA: 'Turks and Caicos Islands',
  TUV: 'Tuvalu',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  ARE: 'United Arab Emirates',
  GBR: 'United Kingdom of Great Britain and Northern Ireland',
  UMI: 'United States Minor Outlying Islands',
  USA: 'United States of America',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VUT: 'Vanuatu',
  VEN: 'Venezuela (Bolivarian Republic of)',
  VNM: 'Viet Nam',
  VGB: 'Virgin Islands (British)',
  VIR: 'Virgin Islands (U.S.)',
  WLF: 'Wallis and Futuna',
  ESH: 'Western Sahara',
  YEM: 'Yemen',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe'
};

export const countriesByTwoLetterCode = {
  AF: 'Afghanistan',
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia (Plurinational State of)',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  CV: 'Cabo Verde',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo (Democratic Republic of the)',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Côte d'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CW: 'Curaçao',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  SZ: 'eSwatini',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island and McDonald Islands',
  VA: 'Holy See',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran (Islamic Republic of)',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KP: "Korea (Democratic People's Republic of)",
  KR: 'Korea (Republic of)',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia (Federated States of)',
  MD: 'Moldova (Republic of)',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MK: 'North Macedonia',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine, State of',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthélemy',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SX: 'Sint Maarten (Dutch part)',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia and the South Sandwich Islands',
  SS: 'South Sudan',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan, Province of China',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela (Bolivarian Republic of)',
  VN: 'Viet Nam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (U.S.)',
  WF: 'Wallis and Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
};

export const getCountryCode = memoize<string | undefined>(
  (countryName: string): string | undefined =>
    Object.entries(countriesByCountryCode).find(([_, name]) => countryName === name)?.[0]
);

export const getTwoLetterCountryCode = memoize<string | undefined>(
  (countryName: string): string | undefined => {
    if (!countryName) return undefined;
    const result = Object.entries(countriesByTwoLetterCode).find(
      ([_, name]) => countryName === name
    );
    if (result === undefined) {
      logger('Utils').exception({
        error: `Country name not found: ${countryName}`,
        errorType: 'CountryNotFound',
        tags: { countryName }
      });
    }
    return result?.[0];
  }
);

export const countryNames = Object.values(countriesByCountryCode).sort();

export const dataDisclosures = {
  dateOfBirth: {
    usage:
      'TARTLE uses date of birth to verify the identity of new users against Anti Terror \
    and Anti Money Laundering lists as required by law. It will not be sold without your explicit \
    permission, and will not be shared with third parties outside of our service providers used to \
    run this website. Date of birth will not be public.',
    worth:
      'The date of birth is worth between $0.00 USD and $0.05 USD. TARTLE offers the use of the \
    TARTLE platform in lieu of direct payment for this information, with this offer being \
    non-negotiable at this time.',
    safety:
      'TARTLE encrypts date of birth while stored, and maintains access control policies \
    to limit the potential for this data to be transported from our system.'
  },
  country: {
    usage:
      'TARTLE uses country name to verify the identity of new users against Anti Terror \
    and Anti Money Laundering lists as required by law.  It will not be sold without your explicit \
    permission, and will not be shared with third parties outside of our service providers used to \
    run this website. Country will not be public.',
    worth:
      'The country name is worth between $0.00 USD and $0.05 USD. TARTLE offers the use of the \
    TARTLE platform in lieu of direct payment for this information, with this offer \
    being non-negotiable at this time.',
    safety:
      'TARTLE encrypts country name while stored, and maintains access control policies \
     to limit the potential for this data to be transported from our system.'
  },
  gender: {
    usage:
      'TARTLE uses gender to help you receive bids which are appropriate to your demographics. \
    This information will not be sold or shared without your express permission, except with third parties \
    required in the operation of this website. Gender information will not be public.',
    worth:
      'Gender information is worth between $0.10 USD and $0.14 USD. TARTLE offers the use of the TARTLE \
    platform in lieu of direct payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE encrypts gender information while stored, and maintains access control policies to \
     limit the potential for this data to be transported from our system.'
  },
  sex: {
    usage:
      'TARTLE uses sex information to help you receive bids which are appropriate to your \
    demographics. This information will not be sold or shared without your express permission, except \
    with third parties required in the operation of this website. Sex information will not be public.',
    worth:
      'Sex information is worth between $0.10 USD and $0.14 USD. TARTLE offers the \
    use of the TARTLE platform in lieu of direct payment for this information, with this offer being \
    non-negotiable at this time.',
    safety:
      'TARTLE encrypts sex information while stored, and maintains access control policies \
     to limit the potential for this data to be transported from our system.'
  },
  password: {
    usage:
      'TARTLE uses password to authenticate your account in our system, for logging into the system. \
    It will not be shared, sold, or disclosed for any reason.',
    worth:
      'The password is worth $0.00 USD. TARTLE offers the use of the TARTLE platform in lieu of direct \
    payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE hashes passwords using industry recommended one way algorithms (Bcrypt), and ensures \
    that passwords are not exposed in logs.'
  },
  email: {
    usage:
      'TARTLE uses email as a reference to your account in our system, for logging into the system and \
    for communication with you, including marketing communication. It will not be sold without your explicit \
    permission, and will not be shared with third parties outside of our service providers used to run this \
    website. Email will not be public.',
    worth:
      'The email is worth between $0.05 USD and $0.07 USD. TARTLE offers the use of the TARTLE platform \
    in lieu of direct payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE encrypts email while stored, and maintains access control policies to limit the potential \
     for this data to be transported from our system.'
  },
  fullName: {
    usage:
      'TARTLE uses your full name to verify the identity of new users against Anti Terror and Anti Money \
    Laundering lists as required by law.  It will not be sold without your explicit permission, and will not \
    be shared with third parties outside of our service providers used to run this website. Your name will \
    not be public.',
    worth:
      'Your full name is worth between $0.00 USD and $0.10 USD. TARTLE offers the use of the TARTLE \
    platform in lieu of direct payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE encrypts your name while stored, and maintains access control policies \
    to limit the potential for this data to be transported from our system.'
  },
  firstName: {
    usage:
      'TARTLE uses first name to verify the identity of new users against Anti Terror and Anti Money \
    Laundering lists as required by law.  It will not be sold without your explicit permission, and will not \
    be shared with third parties outside of our service providers used to run this website. First name will \
    not be public.',
    worth:
      'The first name is worth between $0.00 USD and $0.05 USD. TARTLE offers the use of the TARTLE \
    platform in lieu of direct payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE encrypts first name while stored, and maintains access control policies \
    to limit the potential for this data to be transported from our system.'
  },
  lastName: {
    usage:
      'TARTLE uses last name to verify the identity of new users against Anti Terror and Anti Money \
    Laundering lists as required by law.  It will not be sold without your explicit permission, and will not be \
    shared with third parties outside of our service providers used to run this website. Last name will not be public.',
    worth:
      'The last name is worth between $0.00 USD and $0.05 USD. TARTLE offers the use of the TARTLE platform in \
    lieu of direct payment for this information, with this offer being non-negotiable at this time.',
    safety:
      'TARTLE encrypts last name while stored, and maintains access control policies to \
    limit the potential for this data to be transported from our system.'
  }
};
