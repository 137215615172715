import * as React from 'react';
import WarningIcon from './WarningIcon';
import QuestionIcon from './QuestionIcon';
import InfoIcon from './InfoIcon';
import CheckmarkIcon from './CheckmarkIcon';

export const feedbackIcons = {
  warning: <WarningIcon />,
  help: <QuestionIcon />,
  info: <InfoIcon />,
  success: <CheckmarkIcon />
};
