import * as React from 'react';
import {
  createChangeEvent,
  CustomTarget,
  SimulatedChangeEvent
} from '../../../hooks/useForm';
import { FComponent } from '../../../types/common';
import { Checkbox } from './Checkbox';
import { FieldErrors } from './FieldErrors';
import { classNames } from '../../../utils';
import { ImageCheckbox } from './ImageCheckbox';
import { useUserAgent } from '../../../hooks/useUserAgent';

type Option = { value: string; label: string; imageUrl?: string; ariaLabel?: string };

export interface CheckBoxGroupTarget extends CustomTarget {
  name: string;
  type: 'checkboxgroup';
  value: boolean[];
}

type CheckboxGroupProps = {
  name: string;
  options: Option[];
  onChange: (event: SimulatedChangeEvent<CheckBoxGroupTarget>) => void;
  checked: boolean[];
  visited: boolean;
  errors: string[];
  disabled?: boolean;
  className?: string;
};

const CheckboxGroup: FComponent<CheckboxGroupProps> = ({
  name,
  options,
  onChange,
  checked,
  visited,
  errors,
  disabled = false,
  className = 'checkbox-default'
}) => {
  const isImagePicker = options.some(option => Boolean(option.imageUrl));
  const { isMobile } = useUserAgent();

  const handleCheckboxChange = (index: number, state: boolean) => {
    const newCheckedBoxes = [...checked];
    newCheckedBoxes[index] = state;
    onChange(
      createChangeEvent({
        type: 'checkboxgroup',
        name,
        value: newCheckedBoxes
      })
    );
  };

  return (
    <div
      className={classNames(isImagePicker ? 'image-checkbox-group' : 'checkbox-group')}>
      {isImagePicker && !isMobile ? (
        <div className="instructions">Press and hold the image to zoom in</div>
      ) : null}
      <div
        className={classNames(
          isImagePicker ? 'option-group-grid' : 'checkbox-group-list'
        )}>
        {options.map(({ value, label, ariaLabel, imageUrl }, index) =>
          imageUrl ? (
            <ImageCheckbox
              key={value + label}
              className={className}
              name={value}
              label={label}
              ariaLabel={ariaLabel}
              value={value}
              imageUrl={imageUrl}
              checked={checked[index]}
              onChange={event => handleCheckboxChange(index, event.target.checked)}
              disabled={disabled}
            />
          ) : (
            <Checkbox
              key={value + label}
              className={className}
              name={value}
              label={label}
              value={value}
              checked={checked[index]}
              onChange={event => handleCheckboxChange(index, event.target.checked)}
              disabled={disabled}
            />
          )
        )}
        <FieldErrors visited={visited} errors={errors} />
      </div>
    </div>
  );
};

export { CheckboxGroup };
