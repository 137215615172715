/* eslint-disable react/display-name */
import * as React from 'react';
import { ErrorBoundary } from '../components/common/ErrorBoundary';
import { appsignal } from '../lib/logger';
import { feedbackIcons } from '../components/common/Icons';
import { Button } from '../components/common/Button';

const withErrorBoundary =
  (Component, action, isSilent = false) =>
  props => {
    let tags = { framework: 'React', source: 'boundary' };
    if (isSilent) {
      tags.silent = 'true';
    }

    return (
      <ErrorBoundary
        instance={appsignal}
        action={action}
        tags={tags}
        fallback={() => (isSilent ? null : <FallbackComponent />)}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

export default withErrorBoundary;

export const FallbackComponent = () => {
  const [showDialog, toggleDialog] = React.useState(false);
  return (
    <div>
      <button onClick={toggleDialog} className="error-state">
        {feedbackIcons.warning}
      </button>
      {showDialog ? (
        <div className="dialog">
          <div className="dialog-container">
            <div className="dialog-overlay" />
            <span className="dialog-center-trick" aria-hidden="true">
              &#8203;
            </span>
            <div className="dialog-window">
              {feedbackIcons.warning}
              <h2>Oops, something went wrong</h2>
              <div className="dialog-description">
                Our apologies! We encountered an error from which we couldn&apos;t
                recover. The team has been alerted of this problem. If you wish to retry,
                click &apos;Reload&apos; below to refresh the page. Thanks!
              </div>
              <div className="form-button-bar">
                <Button secondary onClick={() => location.reload()}>
                  Reload
                </Button>
                <Button onClick={() => toggleDialog(false)}>Close</Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
