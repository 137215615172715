import type { ModeratorTableEndpoint } from '../../hooks/useModeratorTable';
export * from './client';
import { ContentType } from './client';
import {
  ModeratorGraphNames,
  ModeratorGraphTabs
} from '../../components/moderator/ModeratorDataGraphs/ModeratorDataGraphs';

const DOMAIN = window.location.origin;
export type EndpointUrl = string | ((...args: string[]) => string);
export type Endpoint = readonly [EndpointUrl, string?, ContentType?];

// Endpoints are in the format [url, version?]. When adding a version (e.g [<some url>, '3.0.2']),
// the useApiRequest hook will add the { 'X-API-Version': version } header to the request.
const api = {
  seller: {
    login: ['sellers/signin.json'],
    signup: ['sellers/register.json'],
    confirmation: ['sellers/confirmation.json'],
    password: ['sellers/password.json'],
    walletBalance: ['api/v5/wallet.json'],
    createWallet: ['sellers/create_tcoin_wallet.json'],
    receiveUnpocketed: ['sellers/receive_unpocketed_tcoin.json'],
    withdrawalMethods: ['sellers/withdrawal_methods.json'],
    withdrawTcoin: ['sellers/withdraw_tcoin.json'],
    walletTransactions: ['api/v5/wallet/transactions.json'],
    packets: ['api/v5/packets.json'],
    packet: [(id: string) => `api/v5/packets/${id}`],
    bids: ['api/v5/bids.json'],
    sellBid: [(bidId: string) => `api/v5/bids/${bidId}/sell`],
    sellersPacket: [(packetId: string) => `api/v5/packets/${packetId}/sellers_packet`],
    createSellersPacketAttachment: [
      (packetId: string) => `api/v5/packets/${packetId}/sellers_packet/create_attachment`
    ],
    updateNotification: ['sellers/account_settings/notification_settings.json'],
    inviteFriends: ['sellers/account_settings/send_invite.json'],
    updateAccountInfo: ['sellers/update_account_info.json'],
    sellerMarketValues: ['api/v5/market_values.json'],
    sellerCurrentMarketValue: ['api/v5/market_values/current.json'],
    enable2fa: ['sellers/2fa/post_verify_installation.json'],
    disable2fa: ['sellers/2fa/disable_2fa_settings.json'],
    changePassword: ['sellers.json'],
    deleteAccount: ['sellers/account_settings/post_delete_account.json'],
    logout: ['sellers/sign_out.json'],
    updateProfile: ['sellers/account_settings/update_profile.json'],
    createProfilePhotoAttachment: ['sellers/account_settings/create_profile_photo.json'],
    completeRegistration: ['sellers/complete_registration.json'],
    badges: ['api/v5/badges.json'],
    badge: [(badgeId: string) => `api/v5/badges/${badgeId}`],
    notifications: ['api/v5/notifications.json'],
    getScore: ['sellers/user_action_completion_score.json'],
    packetsPacketGroup: [(id: string) => `api/v5/packet_groups/${id}.json`],
    getKeys: ['api/v5/keys.json']
  },
  buyer: {
    login: ['buyers/signin.json'],
    signup: ['buyers/register.json'],
    confirmation: ['buyers/confirmation.json'],
    password: ['buyers/password.json'],
    walletBalance: ['api/v4/wallet.json'],
    createWallet: ['buyers/create_tcoin_wallet.json'],
    receiveUnpocketed: ['buyers/receive_unpocketed_tcoin.json'],
    walletTransactions: ['api/v4/wallet/transactions.json'],
    userDeposits: ['api/v4/user_deposits.json'],
    bids: ['api/v4/bids.json'],
    bidCsv: [(bidId: string): string => `api/v4/bids/${bidId}/data.csv`, '', 'text/csv'],
    bidData: [(bidId: string): string => `api/v4/bids/${bidId}/data.json`],
    bidById: [(bidId: string): string => `api/v4/bids/${bidId}.json`],
    bidTransactions: [
      (bidId: string): string => `api/v4/bids/${bidId}/transactions.json`
    ],
    bidTransactionById: [
      (bidId: string, transactionID: string): string =>
        `api/v4/bids/${bidId}/transactions/${transactionID}.json`
    ],
    bidPacketQuestions: [
      (bidId: string): string => `api/v4/bids/${bidId}/packet_questions.json`
    ],
    extendBid: [(bidId: string): string => `api/v4/bids/${bidId}/extend`],
    bidPreview: ['api/v4/bids/preview.json'],
    packetInfo: [(packetID: string) => `buyers/packets/${packetID}/info.json`],
    packetStats: [(packetUUID: string) => `buyers/packets/${packetUUID}/stats`],
    apiKeys: ['buyers/api_keys.json'],
    deleteApiKey: [(apiKeyID: string): string => `buyers/api_keys/${apiKeyID}.json`],
    enable2fa: ['buyers/2fa/post_verify_installation.json'],
    disable2fa: ['buyers/2fa/disable_2fa_settings.json'],
    packets: ['api/v4/packets.json'],
    packet: [(id: string) => `api/v4/packets/${id}`],
    categories: ['api/v4/categories.json'],
    inputTypes: ['api/v4/input_types.json'],
    inputType: [(id: string) => `api/v4/input_types/${id}`],
    userStats: ['buyers/users/stats.json'],
    allPacketStats: ['buyers/packets/stats.json'],
    packetGraphStats: [(packetUUID: string) => `api/v4/packets/${packetUUID}/stats`],
    paymentIntent: ['api/v4/payment_intents'],
    updateProfile: ['buyers/account_settings/update_profile.json'],
    getProfile: ['buyers/account_settings/profile.json'],
    changePassword: ['buyers.json'],
    referrals: ['api/v4/referral_codes.json'],
    cohorts: ['api/v4/cohorts.json'],
    cohort: [(id: string) => `api/v4/cohorts/${id}`],
    cohortMembers: [(id: string) => `api/v4/cohorts/${id}/members`],
    addCohortMembers: [(id: string) => `api/v4/cohorts/${id}/append`],
    removeCohortMembers: [(id: string) => `api/v4/cohorts/${id}/remove`],
    mergeCohort: [(id: string) => `api/v4/cohorts/${id}/merge`],
    cohortAutomations: ['api/v4/cohort_automations.json'],
    createCohortAutomation: [(id: string) => `api/v4/cohorts/${id}/automations`],
    notificationsRequests: [(id: string) => `api/v4/bids/${id}/notifications`],
    bidAvailability: [`api/v4/bids/availability.json`],
    cohortFolders: ['api/v4/cohort_folders.json'],
    cohortFolder: [(id: string) => `api/v4/cohort_folders/${id}`],
    cohortFolderAddCohorts: [(id: string) => `api/v4/cohort_folders/${id}/add_cohorts`],
    cohortFolderRemoveCohort: [
      (id: string) => `api/v4/cohort_folders/${id}/remove_cohort`
    ]
  },
  moderator: {
    login: ['moderators/signin.json'],
    password: ['moderators/password.json'],
    transferTcoin: ['moderator/transfer_tcoin.json'],
    tartleDataGraph: [
      (tab: ModeratorGraphTabs, graph: ModeratorGraphNames) =>
        `api/v6/tartle_data/${tab}/${graph}`
    ],
    getModeratorTableSchema: [
      (tableEndpoint: ModeratorTableEndpoint) =>
        `moderator/${tableEndpoint}/index_schema.json`
    ],
    getModeratorTable: [
      (tableEndpoint: ModeratorTableEndpoint) => `moderator/${tableEndpoint}.json`
    ],
    packets: ['api/v6/packets.json'],
    packet: [(id: string) => `api/v6/packets/${id}`],
    categories: ['api/v6/categories.json'],
    inputTypes: ['api/v6/input_types.json'],
    inputType: [(id: string) => `api/v6/input_types/${id}`],
    createAttachment: ['api/v6/packets/create_attachment'],
    dashboardData: ['moderator/dashboard/data.json'],
    updateServiceFee: ['moderator/dashboard/service_fee.json'],
    updatePercentServiceFee: ['moderator/dashboard/percent_service_fee.json'],
    postModeratorBulkReindex: [
      (tableEndpoint: ModeratorTableEndpoint): string =>
        `moderator/${tableEndpoint}/bulk_reindex.json`
    ],
    enable2fa: ['moderator/2fa/post_verify_installation.json'],
    disable2fa: ['moderator/2fa/disable_2fa_settings.json'],
    emailAutomations: [`api/v6/marketing_email_automations.json`],
    emailAutomation: [(id: string) => `api/v6/marketing_email_automations/${id}.json`],
    emailAutomationReactivate: [
      (id: string) => `api/v6/marketing_email_automations/${id}/reactivate.json`
    ],
    emailAutomationsEventKeys: ['api/v6/marketing_email_automations/event_keys.json'],
    sendBidNotifications: [
      (id: string) => `moderator/bids/${id}/send_notifications.json`
    ],
    packetGroup: [(id: string) => `api/v6/packet_groups/${id}`],
    packetGroups: ['api/v6/packet_groups.json'],
    badges: ['moderator/badges.json'],
    badge: [(id: string) => `moderator/badges/${id}.json`],
    createBadgeAttachment: ['moderator/badges/create_icon'],
    rewards: ['moderator/rewards.json'],
    reward: [(id: string) => `moderator/rewards/${id}.json`],
    resendBidNotifications: [
      (id: string) => `moderator/bids/${id}/resend_notifications.json`
    ],
    referrals: ['api/v6/referral_codes.json'],
    cohorts: ['api/v6/cohorts.json'],
    cohort: [(id: string) => `api/v6/cohorts/${id}`],
    bids: ['api/v6/bids.json'],
    generateBuyerReferralCode: [
      (id: string) => `moderator/buyers/${id}/generate_referral_code.json`
    ],
    createBoundedChoiceAttachment: ['api/v6/packets/create_bounded_choice_attachment'],
    buyer: [(id: string) => `moderator/buyers/${id}.json`],
    restoreBuyer: [(id: string) => `moderator/buyers/${id}/restore?user=${id}.json`],
    switchBuyerToSeller: [(id: string) => `moderator/buyers/${id}/switch_to_seller.json`],
    resendConfirmationEmail: [
      (id: string) => `moderator/sellers/${id}/resend_confirmation?user=${id}.json`
    ],
    seller: [(id: string) => `moderator/sellers/${id}.json`],
    restoreUser: [(id: string) => `moderator/sellers/${id}/restore.json?user=${id}`],
    resendConfirmation: [
      (id: string) => `moderator/sellers/${id}/resend_confirmation.json?user=${id}`
    ],
    resendCompleteRegistration: [
      (id: string) => `moderator/sellers/${id}/resend_complete_registration.json`
    ],
    resetSellerPin: [(id: string) => `moderator/sellers/${id}/reset_pin.json?user=${id}`],
    resetSellerDoB: [(id: string) => `moderator/sellers/${id}/reset_date_of_birth.json`],
    suspendSeller: [(id: string) => `moderator/sellers/${id}/suspend_account.json`],
    unsuspendSeller: [(id: string) => `moderator/sellers/${id}/unsuspend_account.json`],
    calculateSellerAvgReferralsTuringScore: [
      (id: string) => `moderator/sellers/avg_referrals_turing_score.json?id=${id}`
    ],
    getTuringScore: [(id: string) => `moderator/sellers/turing_score.json?id=${id}`],
    getItaInfo: [(id: string) => `moderator/sellers/ita_info.json?id=${id}`]
  },
  unauthenticated: {
    verifyCaptcha: ['api/v3/captcha/verify.json'],
    exchangeCaptchaToken: ['api/v3/captcha/exchange.json'],
    redeemInvite: ['redeem_invite.json'],
    checkEmailAvailability: ['api/v3/email_availability.json'],
    packet: [(id: string) => `api/v3/packets/${id}`],
    marketingEmailsUnsubscribe: ['unsubscribe'],
    exchangeToken: ['sellers/exchange_token.json']
  }
} as const;

const buildUrl = (url: EndpointUrl, params?: string[]) => {
  if (url instanceof Function) {
    if (!params) {
      throw new Error('Missing params for url function');
    }
    return url(...params);
  }
  return url;
};

export { api, buildUrl, DOMAIN };
