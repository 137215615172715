import * as React from 'react';
import { UAParser } from 'ua-parser-js';

export const useUserAgent = () => {
  const [agentInfo] = React.useState(() => {
    const uaParser = new UAParser();
    return uaParser.getResult();
  });

  // check for iOS (including simulators)
  const isIos = agentInfo.os.name === 'iOS';

  // check for iPads
  const isIpad = agentInfo.device.model === 'iPad';

  // check for android devices
  const isAndroid = agentInfo.os.name === 'Android';
  const isMobile = isIos || isAndroid || isIpad;

  return { agentInfo, isIos, isIpad, isAndroid, isMobile };
};
