import * as React from 'react';
import { Listbox } from '@headlessui/react';
import CheckIcon from '../Icons/CheckIcon';
import SelectorIcon from '../Icons/SelectorIcon';
import { FComponent } from '../../../types/common';
import { FieldErrors } from './FieldErrors';
import { classNames } from '../../../utils';

type Option = { value: string; label: string | JSX.Element };
type SelectOptionGroup<T extends Option | string> = Record<string, T[]>;
export type SelectOptions = string[] | Option[] | SelectOptionGroup<Option | string>;

type SelectProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: SelectOptions;
  value: string;
  disabled?: boolean;
  label?: string | JSX.Element;
  name: string;
  visited?: boolean;
  errors?: string[];
  className?: string;
  undefinedOption?: string;
  ariaLabel?: string;
};

// Select options can be an array of strings, in which case the value and the label are the same.
// to pass option groups, send a record instead.
// This component will throw if sent options in the wrong format (string or Option type)
const WrappedSelect: FComponent<SelectProps, HTMLButtonElement> = (
  {
    onChange,
    options: selectOptions,
    value,
    label,
    name,
    disabled = false,
    visited,
    errors,
    className = '',
    ariaLabel,
    undefinedOption
  },
  ref
) => {
  const [options, setOptions] = React.useState(processOptions(selectOptions));

  React.useEffect(() => {
    setOptions(processOptions(selectOptions));
  }, [selectOptions]);

  const handleChange = (value: string) => {
    const event: React.ChangeEvent<HTMLInputElement> = {
      target: {
        name,
        value
      }
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

  const ariaLabelProp = ariaLabel
    ? { 'aria-label': `${ariaLabel} ${value ?? 'empty'}` }
    : {};

  return (
    <Listbox
      as="div"
      placeholder="Select one"
      className={classNames('select-container', className)}
      value={value}
      disabled={disabled}
      onChange={handleChange}>
      <div className="select">
        {label ? (
          <label htmlFor={name} className="input-label">
            {label}
          </label>
        ) : (
          ''
        )}

        <div className="select-button-container" id={name}>
          <Listbox.Button ref={ref} className="select-button" {...ariaLabelProp}>
            <span className="select-selected">
              {getLabelForValue(options, value, undefinedOption)}
            </span>
            <span className="select-selector-icon-wrapper">
              <SelectorIcon className="select-selector-icon" aria-hidden="true" />
            </span>
          </Listbox.Button>
        </div>
        <FieldErrors
          className="field-errors select-errors"
          visited={visited}
          errors={errors}
        />
        <div className="select-options-container">
          <Listbox.Options className="select-list">
            {undefinedOption ? (
              <OptionGroup options={[{ value: undefined, label: undefinedOption }]} />
            ) : null}
            {Array.isArray(options) ? (
              <OptionGroup options={options} />
            ) : (
              Object.entries(options).map(([group, options]) => (
                <div key={group}>
                  <div className="option-group-name">{group}</div>
                  <OptionGroup key={group} options={options} />
                </div>
              ))
            )}
          </Listbox.Options>
        </div>
      </div>
    </Listbox>
  );
};

const OptionGroup: FComponent<{ options: Option[] }> = ({ options }) => {
  return (
    <>
      {options.map(option => {
        return (
          <Listbox.Option key={option.value ?? 'undefined'} value={option.value}>
            {({ selected, active }) => (
              <div className={`${active ? 'select-option-hover' : 'select-option'}`}>
                {selected && (
                  <span className="selected-option-icon">
                    <CheckIcon className="check-icon" aria-hidden="true" />
                  </span>
                )}
                <span className={`${selected ? 'selected-option' : ''}`}>
                  {option.label}
                </span>
              </div>
            )}
          </Listbox.Option>
        );
      })}
    </>
  );
};

const Select = React.forwardRef(WrappedSelect);

export { Select };

// Takes options of type string and returns them in the { value: string, label: string } format, same with groups.
const processOptions = (options: SelectOptions) => {
  if (isSelectOptionGroup(options)) {
    return normalizeOptionGroup(options);
  }

  return options.map(normalizeOption);
};

const isSelectOptionGroup = (
  options: unknown
): options is SelectOptionGroup<Option | string> => {
  return typeof options === 'object' && !Array.isArray(options);
};

const normalizeOptionGroup = (options: SelectOptionGroup<string | Option>) =>
  Object.entries(options)
    .map(([groupName, options]): [string, Option[]] => [
      groupName,
      options.map(normalizeOption)
    ])
    .reduce(
      (acc, [groupName, options]) => ({ ...acc, [groupName]: options }),
      {} as Record<string, Option[]>
    );

const normalizeOption = (option: string | Option) => {
  if (typeof option === 'string') {
    return { value: option, label: option };
  } else if (isOption(option)) {
    return option;
  }

  throw new Error(`Invalid option type ${JSON.stringify(option)}`);
};

const isOption = (option: unknown): option is Option =>
  typeof option === 'object' &&
  'label' in option &&
  'value' in option &&
  (typeof (option as Option).label === 'string' ||
    React.isValidElement((option as Option).label)) &&
  typeof (option as Option).value === 'string';

const getLabelForValue = (
  options: Option[] | SelectOptionGroup<Option>,
  value: string,
  undefinedValue: string
) => {
  if (value === undefined && undefinedValue) {
    return undefinedValue;
  }
  if (Array.isArray(options)) {
    return options.find(option => option.value === value)?.label ?? value;
  }
  return (
    Object.entries(options)
      .map(([, options]) => options)
      .flat()
      .find(option => option.value === value)?.label ?? value
  );
};
