export const getComponentFromIdentifier = (identifier, moduleMap) => {
  if (moduleMap.has(`${identifier}/index`)) {
    return moduleMap.get(`${identifier}/index`).default;
  }

  if (moduleMap.has(identifier)) {
    return moduleMap.get(identifier).default;
  }

  const [filename, componentExport] = identifier.split('/');

  if (moduleMap.has(filename)) {
    const module = moduleMap.get(filename);

    if (componentExport in module) {
      return module[componentExport];
    } else {
      throw Error(`Component export ${componentExport} not found in ${filename}`);
    }
  } else {
    throw Error(`Component ${filename} not found`);
  }
};
