import * as React from 'react';
import { Modal } from '../common/Modal';
import { Button } from '../common/Button';
import { Checkbox, Input, Select } from '../common/Form';
import CheckmarkIcon from '../common/Icons/CheckmarkIcon';
import { Validators } from 'tiny-validation';
import { useForm } from '../../hooks/useForm';
import { paths } from '../../utils/constants';
import type { FComponent, RecursiveToCamel } from '../../types/common';
import { SliderCaptcha } from './SliderCaptcha';
import { useLogger } from '../../hooks/useLogger';
import { useToggle } from '../../hooks/useToggle';
import { isValueInSet, passwordValidators } from '../../utils/validators';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { DOMAIN, api } from '../../lib/api';
import type { CaptchaToken } from './SliderCaptcha';
import { ErrorMessage } from '../common/Form/ErrorMessage';
import { handleApiErrors } from '../../utils';
import { track } from '../../lib/analytics';
import { useApiRequest } from '../../hooks/useApiRequest';
import ChevronLeftMiniIcon from '../common/Icons/ChevronLeftMiniIcon';
import TartleLogoMark from '../common/Icons/TartleLogoMark';
import { ChangeNoticeState, LATEST_CHANGE } from '../common/ChangeNotices';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

const { equals, isEmail, isPresent } = Validators;

type RedeemInviteSignupRequest = RecursiveToCamel<
  {
    invite: {
      buyer_or_seller: 'seller' | 'buyer';
      invite_code: string;
    };
    buyer: {
      email: string;
      password: string;
      password_confirmation: string;
      tos_accepted: boolean;
    };
  } & CaptchaToken
>;

export type RedeemInviteSignupFormData = Omit<
  RecursiveToCamel<
    RedeemInviteSignupRequest['invite'] & RedeemInviteSignupRequest['buyer']
  >,
  'tosAccepted'
> & { tosAccepted: string };

export type RedeemInviteSignupResponse = {
  redirect?: string;
  error?: Record<string, string>;
};

type BuyerSignupProps = {
  gcaptchaKey: string;
};

const RedeemInviteSignup: FComponent<BuyerSignupProps> = ({ gcaptchaKey }) => {
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [redirect, setRedirect] = React.useState<string | null>(null);
  const [captchaError, setCaptchaError] = React.useState<string | null>(null);
  const captchaTokenRef = React.useRef<CaptchaToken>(null);

  const log = useLogger('redeemsignup');
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const [isThankYouScreenOpen, toggleThankYouScreen] = useToggle(false);
  const {
    request: redeemInviteSignup,
    error: apiError,
    isError: isApiError,
    isLoading
  } = useApiRequest<RedeemInviteSignupRequest, RedeemInviteSignupResponse>({
    method: 'POST',
    endpoint: api.unauthenticated.redeemInvite
  });

  const [, setChangeNotice] = useLocalStorageState<ChangeNoticeState>('changeNotice');

  const submit = async ({
    buyerOrSeller,
    inviteCode,
    tosAccepted: tosAcceptedString,
    ...credentials
  }: RedeemInviteSignupFormData) => {
    setIsWaiting(true);

    return redeemInviteSignup({
      data: {
        invite: { buyerOrSeller, inviteCode },
        buyer: {
          tosAccepted: tosAcceptedString === 'true' ? true : false,
          ...credentials
        },
        ...captchaTokenRef.current
      }
    })
      .then(({ redirect }) => {
        setChangeNotice({
          currentVersion: LATEST_CHANGE.version,
          firstSeen: Date.now(),
          dismissed: true
        });
        setRedirect(redirect);
        if (buyerOrSeller === 'seller') {
          history.replaceState(null, null, `${window.location.pathname}?status=thankyou`);
          track('signup', 'all', { isFrictionless: false });
        }
        toggleThankYouScreen();
      })
      .catch(handleApiErrors(log, null, () => setIsWaiting(false)));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    toggleCaptcha();
  };

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setCaptchaError(message);
  };

  const { handleSubmit, handleFieldChange, isDisabled, values, errors, isFieldVisited } =
    useForm({
      onSubmit: (values: RedeemInviteSignupFormData): Promise<void> => submit(values),
      schema: redeemFormSchema,
      initialValues: {
        password: '',
        passwordConfirmation: '',
        email: '',
        buyerOrSeller: 'seller' as 'buyer' | 'seller',
        tosAccepted: 'false',
        inviteCode: ''
      },
      beforeUnload: true
    });

  return (
    <>
      <div className="app-card-drawer-mobile signup">
        <div className="signup-header">
          <a href={`${DOMAIN}/sellers${paths.login}`} title="back to login">
            <ChevronLeftMiniIcon />
          </a>
          <TartleLogoMark className="sheet-tartle-logo" />
        </div>
        <div className="signup-sheet">
          {isThankYouScreenOpen ? (
            <ThankYouScreen
              redirect={redirect}
              userType={values.buyerOrSeller}
              email={values['email']}
            />
          ) : (
            <>
              <form className="signup-step" onSubmit={onSubmit}>
                <div>
                  <div className="step-title">Create an Account</div>
                </div>

                <div className="grid-form password-form">
                  <Select
                    name="buyerOrSeller"
                    ariaLabel="buying data or selling data?"
                    options={[
                      { label: 'Buying Data', value: 'buyer' },
                      { label: 'Selling Data', value: 'seller' }
                    ]}
                    value={values['buyerOrSeller']}
                    onChange={handleFieldChange}
                    visited={isFieldVisited('buyerOrSeller')}
                    errors={errors['buyerOrSeller']}
                    label="Are you buying data or selling data?"
                  />

                  <Input
                    type="email"
                    label="Email"
                    name="email"
                    onChange={handleFieldChange}
                    value={values['email']}
                    visited={isFieldVisited('email')}
                    errors={errors['email']}
                    placeholder="ilovetartle@gmail.com"
                    autoCapitalize={false}
                  />
                  <Input
                    type="password"
                    label="Password"
                    name="password"
                    onChange={handleFieldChange}
                    value={values['password']}
                    visited={isFieldVisited('password')}
                    errors={errors['password']}
                    peekPassword
                  />
                  <Input
                    type="password"
                    label="Confirm password"
                    name="passwordConfirmation"
                    onChange={handleFieldChange}
                    value={values['passwordConfirmation']}
                    visited={isFieldVisited('passwordConfirmation')}
                    errors={errors['passwordConfirmation']}
                    peekPassword
                  />
                  <Input
                    label="Invite Code"
                    name="inviteCode"
                    onChange={handleFieldChange}
                    value={values['inviteCode']}
                    visited={isFieldVisited('inviteCode')}
                    errors={errors['inviteCode']}
                  />
                  <div className="tos-section">
                    <div>
                      Please read and accept the TARTLE terms of service and privacy
                      policy before creating your account.
                    </div>
                    <div className="tos-documents">
                      <a href="/terms_of_service" target="_blank" rel="noreferrer">
                        {/* <RoundedDocumentIcon /> */}
                        Terms of Service
                      </a>
                      <a href="/privacy_policy" target="_blank" rel="noreferrer">
                        {/* <RoundedDocumentIcon /> */}
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <Checkbox
                    name="tosAccepted"
                    label={
                      <div className="tos-label">
                        I accept TARTLE&apos;s terms of service and privacy policy
                      </div>
                    }
                    checked={values['tosAccepted'] === 'true'}
                    onChange={handleFieldChange}
                    visited={isFieldVisited('tosAccepted')}
                    errors={errors['tosAccepted']}
                  />
                </div>

                <ErrorMessage
                  errors={apiError || [captchaError]}
                  isShowing={isApiError || !!captchaError}
                  defaultError="Could not register user, please try again"
                />

                <div className="form-button-bar push-to-bottom">
                  <Button
                    type="submit"
                    disabled={isDisabled || isLoading}
                    isWaiting={isWaiting}>
                    <span>Create account</span>
                  </Button>
                </div>

                <Modal
                  className="dialog-custom"
                  title=""
                  isOpen={isCaptchaOpen}
                  toggle={toggleCaptcha}
                  isAnimated={false}>
                  <SliderCaptcha
                    onSuccess={handleCaptchaSuccess}
                    onFailure={handleCaptchaFailure}
                    gcaptchaKey={gcaptchaKey}
                  />
                </Modal>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="login-link">
        <span>
          Already have a TARTLE account?{' '}
          <a href={`${DOMAIN}/buyers${paths.login}${document.location.search}`}>Login.</a>
        </span>
      </div>
    </>
  );
};

type ThankYouScreenProps = {
  redirect: string;
  userType: 'buyer' | 'seller';
  email: string;
};

const ThankYouScreen: FComponent<ThankYouScreenProps> = ({
  redirect,
  userType,
  email
}) => {
  const onRedirect = () => {
    location.href = `${DOMAIN}${redirect}`;
  };

  return (
    <div className="signup-step">
      <div className="post-registration">
        <CheckmarkIcon />
        <div className="step-title">Thank you!</div>
        <div className="thanks-text">
          Thank you for signing up! Your email is {email}.{' '}
          {userType === 'buyer'
            ? 'Log in to your account to start buying data!'
            : 'Log in to your account to start selling data!'}
        </div>

        <div className="resend-text">
          <Button onClick={onRedirect} ariaLabel={`Go to ${userType}`}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(RedeemInviteSignup, 'RedeemInviteSignup');

const redeemFormSchema = {
  tosAccepted: [
    isValueInSet(['true'], 'You must agree to the Terms of Service to continue')
  ],
  email: [isPresent('Enter your email'), isEmail()],
  password: passwordValidators,
  passwordConfirmation: [equals('password', "Passwords don't match")],
  buyerOrSeller: [isPresent('Please select one')],
  inviteCode: [isPresent('Enter your invite code')]
};
