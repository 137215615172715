// In case of old browsers, privacy restrictions or eventual SSR, return null.
export const store = {
  isAvailable: () => {
    try {
      const hasLocalStorage =
        window && typeof window.localStorage !== 'undefined' && window.localStorage;
      return hasLocalStorage;
    } catch {
      // catches security/privacy errors
      return false;
    }
  },
  handleOperation: (operation: (storage: Storage) => void | string) => {
    if (!store.isAvailable()) {
      // eslint-disable-next-line no-console
      console.error('localStorage is not available');
      return null;
    }
    return operation(window.localStorage);
  },
  getItem: (key: string) => store.handleOperation(storage => storage.getItem(key)),
  setItem: (key: string, value: string) =>
    store.handleOperation(storage => storage.setItem(key, value)),
  removeItem: (key: string) => store.handleOperation(storage => storage.removeItem(key))
};
