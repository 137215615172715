import * as React from 'react';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import {
  createChangeEvent,
  CustomTarget,
  SimulatedChangeEvent
} from '../../../hooks/useForm';
import CheckIcon from '../Icons/CheckIcon';
import SelectorIcon from '../Icons/SelectorIcon';
import { ComboboxOption, UseComboboxResult } from '../../../hooks/useCombobox';
import { FComponent } from '../../../types/common';
import { classNames } from '../../../utils';
import { DataDisclosure, DataDisclosureTooltip } from '../DataDisclosureTooltip';

export interface ComboboxTarget<T> extends CustomTarget {
  name: string;
  type: 'combobox';
  value: T;
}

type ComboboxProps<T extends ComboboxOption> = UseComboboxResult<T> & {
  onChange: (event: SimulatedChangeEvent<ComboboxTarget<T>>) => void;
  value: T;
  disabled?: boolean;
  label?: string;
  disclosure?: DataDisclosure;
  name: string;
  visited?: boolean;
  errors?: string[];
  hideSelectedOptions?: boolean;
  ariaLabel?: string;
  className?: string;
};

const WrappedCombobox = <T extends ComboboxOption>(
  {
    options,
    value,
    onChange,
    label,
    disclosure,
    query,
    onQueryChange,
    name,
    getOptionLabel,
    optionEquals,
    isLoadingOptions = false,
    totalOptions,
    hideSelectedOptions = false,
    ariaLabel,
    className = null
  }: ComboboxProps<T>,
  ref: React.MutableRefObject<HTMLInputElement>
): JSX.Element => {
  if (!ariaLabel && !label) {
    throw new Error("Combobox: 'ariaLabel' or 'label' is required");
  }

  const handleComboboxChange = (newValue: T) => {
    onChange(
      createChangeEvent({
        type: 'combobox',
        name,
        value: newValue
      })
    );
  };

  const visibleOptions = hideSelectedOptions
    ? options.filter(option => !optionEquals(option, value))
    : options;

  return (
    <div className={classNames('combobox-simple', className)}>
      <HeadlessCombobox
        // when value is undefined, the combobox thinks it's uncontrolled, so we use null instead
        value={value || null}
        by={optionEquals}
        onChange={handleComboboxChange}
        nullable>
        {label ? (
          <div className={classNames('input-label', disclosure ? 'with-disclosure' : '')}>
            <HeadlessCombobox.Label>{label}</HeadlessCombobox.Label>
            {disclosure ? <DataDisclosureTooltip disclosure={disclosure} /> : null}
          </div>
        ) : null}
        <div className="input-area">
          <HeadlessCombobox.Input
            aria-label={ariaLabel}
            ref={ref}
            displayValue={getOptionLabel}
            placeholder="Type to narrow down options"
            onChange={onQueryChange}
          />
          <HeadlessCombobox.Button>
            <SelectorIcon />
          </HeadlessCombobox.Button>
        </div>
        <div className="select-options-container">
          <HeadlessCombobox.Options className="select-list">
            {visibleOptions.map(option => {
              const label = getOptionLabel(option);
              return (
                <HeadlessCombobox.Option key={label} value={option}>
                  {({ selected, active }) => (
                    <div
                      className={`${active ? 'select-option-hover' : 'select-option'}`}>
                      {selected ? (
                        <span className="selected-option-icon">
                          <CheckIcon aria-hidden="true" />
                        </span>
                      ) : null}
                      <span className={`${selected ? 'selected-option' : ''}`}>
                        {label}
                      </span>
                    </div>
                  )}
                </HeadlessCombobox.Option>
              );
            })}
            <InfoRows
              query={query}
              visibleOptionCount={visibleOptions.length}
              isLoadingOptions={isLoadingOptions}
              totalOptions={totalOptions}
            />
          </HeadlessCombobox.Options>
        </div>
      </HeadlessCombobox>
    </div>
  );
};

const Combobox = React.forwardRef(WrappedCombobox);

export { Combobox };

type InfoRowsProps = {
  query: string;
  visibleOptionCount: number;
  isLoadingOptions: boolean;
  totalOptions: number;
};

export const InfoRows: FComponent<InfoRowsProps> = ({
  query,
  visibleOptionCount,
  isLoadingOptions,
  totalOptions
}) => {
  const infoRows = getInfoRowText(
    query,
    visibleOptionCount,
    isLoadingOptions,
    totalOptions
  );

  return (
    <>
      {infoRows.map(text => (
        <div key={text} className="information-row">
          {text}
        </div>
      ))}
    </>
  );
};

export const getInfoRowText = (
  query: string,
  optionCount: number,
  isLoadingOptions: boolean,
  totalOptions: number
): string[] => {
  const querySuffix = query ? ` for '${query}'` : '';
  if (isLoadingOptions) {
    return ['Searching...'];
  }
  if (optionCount === 0) {
    return [`No options found${querySuffix}`];
  }
  if (totalOptions && totalOptions - optionCount > 0) {
    return [
      `+ ${totalOptions - optionCount} more options${querySuffix} `,
      'Type more to narrow search'
    ];
  }

  return [`${optionCount} options${querySuffix}`];
};
