// Custom field validators. Docs: https://github.com/fractal-ly/tiny-validation/blob/main/README.md
import { Validation, Success, Fail, Validators } from 'tiny-validation';
import dayjs from 'dayjs';
import RegexParser from 'regex-parser';
import { getValidationMessage } from '../hooks/useSellersPacketForm/formUtils';

const { pattern } = Validators;

// checks to see if string input is an integer
export const isStringAnInteger = Validation((key, x) =>
  !isNaN(x) && /^[0-9]+$/.test(x)
    ? Success()
    : Fail({ [key]: ['Must be a whole number'] })
);

// same as above but the input is optional
export const isStringAnIntegerOptional = Validation((key, x) =>
  /^[+-]?([0-9]+)$/.test(x) || x === ''
    ? Success()
    : Fail({ [key]: ['Must be a whole number'] })
);

// checks to see if string input is a float/decimal
// Acceptable format: 0.5 and .5
export const isStringAFloat = Validation((key, x) =>
  !isNaN(x) && (/^\d+(\.\d+)?$/.test(x) || /^\.(\d+)?$/.test(x))
    ? Success()
    : Fail({ [key]: ['Must be a valid number'] })
);

// When used with numbers in string form, combine with a numerical check like isValidTcoinAmount
export const minVal = (minVal, errorMessage) =>
  Validation((key, x) =>
    parseFloat(x) < minVal ? Fail({ [key]: [errorMessage] }) : Success()
  );
// When used with numbers in string form, combine with a numerical check like isValidTcoinAmount
export const maxVal = (maxVal, errorMessage) =>
  Validation((key, x) =>
    parseFloat(x) > maxVal ? Fail({ [key]: [errorMessage] }) : Success()
  );

export const maxChar = maxCharVal =>
  Validation((key, x) =>
    (x?.length || 0) > maxCharVal
      ? Fail({ [key]: [`Maximum ${maxCharVal} characters`] })
      : Success()
  );

// Only allow up to __ decimals
const countDecimals = number => {
  if (Math.floor(number) === number) return 0;
  const str = Math.abs(number).toString();
  return str.split('.')[1]?.length;
};
export const upToDecimal = value =>
  Validation((key, x) =>
    countDecimals(x) > value
      ? Fail({ [key]: [`Only up to ${value} decimals`] })
      : Success()
  );

export const isAlphaNumeric = Validation((key, x) =>
  /^[a-zA-Z0-9]*$/.test(x) ? Success() : Fail({ [key]: ['Only letters and numbers'] })
);

export const isDefaultSelected = value =>
  Validation((key, x) =>
    value === x ? Fail({ [key]: ['Please select an option'] }) : Success()
  );

export const isToday = Validation((key, x) =>
  x && dayjs(x).format('MMDDYYYY') === dayjs().format('MMDDYYYY')
    ? Success()
    : Fail({ [key]: ['Must be today'] })
);

export const isValidDate = Validation((key, x) =>
  x === undefined || dayjs(x).isValid()
    ? Success()
    : Fail({ [key]: ['Please select or type a valid date'] })
);

export const isBeforeDate = date =>
  Validation((key, x) =>
    x === undefined || dayjs(x).isBefore(dayjs(date))
      ? Success()
      : Fail({
          [key]: [`Must be before ${dayjs(date).format('MMM D, YYYY')}`]
        })
  );

export const isAfterDate = date =>
  Validation((key, x) =>
    x === undefined || dayjs(x).isAfter(dayjs(date))
      ? Success()
      : Fail({
          [key]: [`Must be after ${dayjs(date).format('MMM D, YYYY')}`]
        })
  );

export const containsUppercaseLetter = Validation(
  pattern(/[A-Z]+/, 'Must contain 1 uppercase letter')
);

export const containsLowercaseLetter = Validation(
  pattern(/[a-z]+/, 'Must contain 1 lowercase letter')
);

export const isValueInSet = (strs, errorMessage) =>
  Validation((key, x) =>
    strs.includes(x) ? Success() : Fail({ [key]: [errorMessage ?? 'Invalid value'] })
  );

export const containsNumber = Validation(pattern(/[0-9]+/, 'Must contain 1 number'));

export const isEmail = errorMessage =>
  Validation(
    pattern(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[ ]*$/,
      errorMessage ?? 'Bad email format'
    )
  );

export const passwordValidators = [
  Validation(pattern(/\d/, 'Must contain a number')),
  Validation(pattern(/[A-Z]/, 'Must contain an uppercase letter')),
  Validation(pattern(/[a-z]/, 'Must contain lowercase letter')),
  Validators.minChars(8, 'Must be at least 8 characters')
];

export const onlyPositiveNumbers = Validation(
  pattern(/^[0-9]+$/, 'Positive numbers only')
);
export const onlyNumbers = Validation(pattern(/^-?[0-9]+$/, 'Numbers only'));

const inputTypeErrors = {
  file: 'Please select a file',
  date: 'Please select or type a valid date',
  checkbox: 'Please check at least one option',
  radio: 'Please pick at least one option',
  select: 'Please select an option',
  text: 'Please answer above',
  number: 'Please enter a number above',
  tel: 'Please enter a telephone number',
  url: 'Please enter a url',
  email: 'Please enter a valid email',
  range: 'Please select a value with the slider'
};

export const isFieldValuePresent = htmlInputType =>
  Validation((key, x) => {
    // File input "empty" state is an empty array
    if (htmlInputType === 'file') {
      if (x.length > 0) return Success();
    } else if (htmlInputType === 'checkbox' || htmlInputType === 'multiselect') {
      if (x.find(x => x === true)) return Success();
    } else if (htmlInputType === 'date') {
      if (dayjs(x).isValid()) return Success();
    } else if (!['', undefined].includes(x)) return Success();

    return Fail({ [key]: [inputTypeErrors[htmlInputType] ?? 'Please answer above'] });
  });

export const isRangeValueValid = Validation((key, _, meta) => {
  const { inputRangeMin, inputRangeMax, inputRangeDefault, inputRangeStep } = meta;
  const [min, max, def, step] = [
    inputRangeMin,
    inputRangeMax,
    inputRangeDefault,
    inputRangeStep
  ].map(s => parseInt(s));
  switch (key) {
    case 'inputRangeMax':
      if (max <= min) return Fail({ [key]: ['Max be greater than min'] });
      if (max > 1000000) return Fail({ [key]: ['Max range value is 1,000,000'] });
      break;
    case 'inputRangeMin':
      if (min >= max) return Fail({ [key]: ['Min be less than max'] });
      break;
    case 'inputRangeStep':
      if (step <= 0) return Fail({ [key]: ['Step must be greater than 0'] });
      if (step > max - min)
        return Fail({ [key]: ['Increment is larger than the range'] });
      break;
    case 'inputRangeDefault':
      if (def < min || def > max)
        return Fail({ [key]: ['Default value is out of range'] });
      break;
    default:
      throw new Error('Invalid range key');
  }
  return Success();
});

export const minArrayLength = (minLength, errorMessage) =>
  Validation((key, x) => {
    if (x.length >= minLength) return Success();
    return Fail({ [key]: [errorMessage || `Must choose at least ${minLength} items`] });
  });

export const maxArrayLength = (maxLength, errorMessage) =>
  Validation((key, x) => {
    if (x.length <= maxLength) return Success();
    return Fail({ [key]: [errorMessage || `Must choose at most ${maxLength} items`] });
  });

// Sorted list validators

export const sortedListMinLength = (minLength, errorMessage) =>
  Validation((key, x) => {
    if (x.orderedItemIds.length >= minLength) return Success();
    return Fail({ [key]: [errorMessage || `Must have at least ${minLength} items`] });
  });

export const noDuplicateItems = (propertyKey, errorMessage) =>
  Validation((key, x) => {
    const labeLSet = new Set(x.orderedItemIds.map(id => x.itemHash[id][propertyKey]));

    if (x.orderedItemIds.length !== labeLSet.size) return Fail({ [key]: [errorMessage] });
    return Success();
  });

const urlRegex = new RegExp(
  '^(https?|ftp):\\/\\/' + // protocol
    '(?:(?:[a-z0-9-]+\\.)+[a-z]{2,6}' + // domain name and extension
    '|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})' + // OR IP address
    '(?::\\d{1,5})?' + // optional port
    '(?:\\/\\S*)?' + // path
    '(?:\\?\\S*)?' + // query
    '(?:#\\S*)?$', // fragment
  'i' // case-insensitive
);
export const isUrl = Validation(pattern(urlRegex, 'Enter a valid url'));

// Filter validators

export const isFilterComplete = Validation((key, x) => {
  if (!x.field) return Fail({ [key]: ['Please select a field name'] });
  if (!x.operator) return Fail({ [key]: ['Please select an operator'] });
  if (!x.value) return Fail({ [key]: ['Please enter a value'] });
  return Success();
});

export const isFilterValueValid = (questions, hashFn) => {
  const questionsObj = questions.reduce((acc, q) => {
    acc[hashFn(q.text)] = q;
    return acc;
  }, {});

  return Validation((key, x) => {
    const question = questionsObj[hashFn(x.field)];
    if (!question) return Success();
    if (
      !question.pattern ||
      ['checkbox', 'select', 'file'].includes(question.htmlInputType)
    ) {
      return Success();
    }
    if (question.htmlInputType === 'date') {
      if (x.value === undefined || dayjs(x.value).isValid()) {
        return Success();
      } else {
        return Fail({ [key]: ['Please select or type a valid date'] });
      }
    }
    const re = RegexParser(question.pattern);
    return re.test(x.value)
      ? Success()
      : Fail({ [key]: [getValidationMessage(question)] });
  });
};

export const isFilterValueSha256Hash = Validation((key, x) =>
  x.value.match(/^([0-9]|[a-z]){64}$/g) || x.value === ''
    ? Success()
    : Fail({ [key]: ['Must be a SHA256 Hash'] })
);

export const isFilterValueBase64ShortHash = Validation((key, x) =>
  x.value?.match(/^([0-9a-zA-Z+/]{13})$/g) || x.value === ''
    ? Success()
    : Fail({ [key]: ['Must be a Base64 Short Hash'] })
);

export const isFilterValueBeforeDate = date =>
  Validation((key, x) =>
    x.value === undefined || dayjs(x.value).isBefore(dayjs(date))
      ? Success()
      : Fail({
          [key]: [`Must be before ${dayjs(date).format('MMM D, YYYY')}`]
        })
  );

export const isFilterValueAfterDate = date =>
  Validation((key, x) =>
    x.value === undefined || dayjs(x.value).isAfter(dayjs(date))
      ? Success()
      : Fail({
          [key]: [`Must be after ${dayjs(date).format('MMM D, YYYY')}`]
        })
  );

export const filterMinVal = (minVal, errorMessage) =>
  Validation((key, x) =>
    parseFloat(x.value) < minVal ? Fail({ [key]: [errorMessage] }) : Success()
  );

export const filterMaxVal = (maxVal, errorMessage) =>
  Validation((key, x) =>
    parseFloat(x.value) > maxVal ? Fail({ [key]: [errorMessage] }) : Success()
  );

export const isFilterValueAnIntegerOptional = Validation((key, x) =>
  /^[+-]?([0-9]+)$/.test(x.value) || x.value === ''
    ? Success()
    : Fail({ [key]: ['Must be a whole number'] })
);

export const isFilterValueAFloatOptional = Validation((key, x) =>
  /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(x.value) || x.value === ''
    ? Success()
    : Fail({ [key]: ['Must be a valid number'] })
);

export const isFilterValueValidDate = Validation((key, x) =>
  x.value === undefined || dayjs(x.value).isValid()
    ? Success()
    : Fail({ [key]: ['Please select or type a valid date'] })
);

export const filterValueUpToDecimal = value =>
  Validation((key, x) =>
    countDecimals(x.value) > value
      ? Fail({ [key]: [`Only up to ${value} decimals`] })
      : Success()
  );

export const ariaPresentIfImagePresent = Validation((key, x, meta) => {
  if (meta.fileInputValues.length > 0) {
    return x && x.length > 0
      ? Success()
      : Fail({ [key]: [`Aria label is required when image is present`] });
  } else {
    return Success();
  }
});
