import * as React from 'react';
import { ApiErrors } from '../../../lib/api/types';
import { FComponent } from '../../../types/common';
import { splitReducer } from '../../../utils';
import { Alert } from '../Alert';

type ErrorMessageProps = {
  errors: ApiErrors;
  isShowing?: boolean;
  count?: number;
  defaultError?: string;
  // Use this when you want to display the field name along with the error message. Most of the time
  // we assign these to those fields after submit, but in some instances (like nested forms) we can't.
  includeFieldNames?: boolean;
  // You can use this to avoid printing errors that will be shown elsewhere (like field errors on a form)
  keysToFilter?: string[];
};

// from an ApiErrors object, prioritizing "base" errors, display an Alert with the first n errors in the object
const ErrorMessage: FComponent<ErrorMessageProps> = ({
  errors,
  count = 1,
  isShowing = true,
  defaultError = 'We encountered an error, please try again.',
  includeFieldNames = false,
  keysToFilter = [],
  className = ''
}) => {
  if (!isShowing || !errors) return null;
  let errorMessages: string[] = [defaultError];
  if (!Array.isArray(errors)) {
    const errorsArray = Object.entries(errors).filter(
      ([key]) => !keysToFilter.includes(key)
    );
    if (errorsArray.length) {
      const [baseErrors, fieldErrors] = errorsArray.reduce(
        splitReducer(([field, _]: [string, string[]]) => field === 'base'),
        [[], []]
      );
      errorMessages = [...baseErrors, ...fieldErrors]
        .map(errors => prepareErrorMessages(errors, includeFieldNames))
        .flat();
    } else {
      // This happens when we delegate all error messages received to the form fields.
      errorMessages = [];
    }
  } else {
    errorMessages = errors;
  }

  return (
    <>
      {errorMessages.slice(0, count).map((error, index) => (
        <Alert key={`error${index}`} className={className} type="warning">
          {error}
        </Alert>
      ))}
    </>
  );
};

const prepareErrorMessages = (
  [field, errors]: [string, string[]],
  includeFieldNames = false
) => {
  if (!includeFieldNames || field === 'base') {
    return errors;
  }
  return errors.map(error => `${field}: ${error}`);
};

export { ErrorMessage };
