import * as React from 'react';

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
// It prevents the user from navigating away from the page.
// It's a good idea to use this hook to warn the user if they have unsaved changes.
// To use:
// Whenever you want to warn the user:
// useBeforeUnload(
//   React.useCallback(() => isFormDirty, [isFormDirty])
// );

const useBeforeUnload = (stableCallback: () => boolean) => {
  const cancelRef = React.useRef<() => void>(null);

  React.useEffect(() => {
    const onUnload = (event: BeforeUnloadEvent) => {
      if (stableCallback()) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onUnload);

    cancelRef.current = () => {
      window.removeEventListener('beforeunload', onUnload);
    };

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [stableCallback]);

  return cancelRef;
};

export { useBeforeUnload };
