import * as React from 'react';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import type { FComponent } from '../../types/common';
import InfoIcon from '../common/Icons/InfoIcon';
import TartleLogoMark from '../common/Icons/TartleLogoMark';
import { Tooltip } from '../common/Tooltip';
import { LoginForm } from './Login';

type ModeratorLoginProps = {
  flash: [string, string][];
  gcaptchaKey: string;
};

const ModeratorLogin: FComponent<ModeratorLoginProps> = ({ flash, gcaptchaKey }) => (
  <div className="login">
    <div className="moderator-login">
      <TartleLogoMark className="tartle-logo" />
      <div className="tartle-tooltip">
        <p>TARTLE</p>
        <Tooltip
          text={<InfoIcon />}
          placement="top-end"
          styleType="warning"
          trigger="mouseenter click">
          <p>
            <b>Warning:</b> By accessing and using this computer system, you are
            consenting to system monitoring, interception, recording, reading, copying or
            capturing by authorized personnel of all activities, including detection and
            prevention of any unauthorized use of this system. The system you are
            accessing contains confidential information and is designed exclusively for
            use by authorized persons to interact with TARTLE using only their own
            account. Any other use of this system that is inconsistent with the intended
            purposes of the system is an unauthorized use of the system and strictly
            prohibited.
            <br></br>
            <b>
              Do not create or access an account in this system for anyone other than
              yourself.{' '}
            </b>
            You may not use another person&apos;s information to create or access an
            account on behalf of and in the name of that person, even if that person
            provided their information to you to create or access an account for them or
            even if that person consented to your use of their information to create or
            access the account.Unauthorized use of this system is prohibited and subject
            to criminal and civil penalties, including, but not limited to, penalties
            applicable to knowingly or intentionally accessing a computer without
            authorization or exceeding authorized access
          </p>
        </Tooltip>
      </div>
    </div>
    <div className="app-card-md">
      <LoginForm userType="moderator" flash={flash} gcaptchaKey={gcaptchaKey} />
    </div>
  </div>
);

export default withErrorBoundary(ModeratorLogin, 'ModeratorLogin');
