import * as React from 'react';
import { objectToUrlParams, snakeToCamel, toSnakeCase } from '../utils';

// cleanParams is used during the initialization of state to do validations and provide a valid initial state.
const useUrlParamState = <T extends Record<string, string>>(
  cleanParams?: (urlParams: Record<string, string>) => T
): [T, React.Dispatch<React.SetStateAction<Record<string, string>>>] => {
  const [state, setState] = React.useState<T>(() => getInitialState<T>(cleanParams));

  React.useEffect(() => {
    history.replaceState(
      null,
      null,
      `${window.location.pathname}${objectToUrlParams(toSnakeCase(state))}${
        window.location.hash
      }`
    );
  }, [state]);

  return [state, setState];
};

export { useUrlParamState };

const getInitialState = <T extends Record<string, string>>(
  cleanParams?: (urlParams: Record<string, string>) => T
) => {
  const state: Record<string, string> = {};
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.forEach((value, key) => {
    state[snakeToCamel(key)] = value;
  });

  if (cleanParams) {
    return cleanParams(state);
  }
  return state as T;
};
