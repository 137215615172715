export type ApiErrorResult = {
  errors: [string] | Record<string, string[]>;
};
export type ApiErrors = ApiErrorResult['errors'];

export const isApiError = (value: unknown): value is ApiErrors => {
  if (typeof value !== 'object') {
    return false;
  }

  let values: unknown[] = [];

  if (Array.isArray(value)) {
    values = value;
  } else {
    for (const v of Object.values(value)) {
      if (!Array.isArray(v)) {
        return false;
      }
      values.push(v);
    }
  }
  return !values.flat().some(v => typeof v !== 'string');
};
