import * as React from 'react';

// This Hook should be used sparingly, since autofocusing elements is most often detrimental to accessibility. The only exception is
// when the element to be focused is the main purpose of the page and has good use of aria label or described by. For a good
// acceptable use case look at the signup flow.
const useAutofocusRef = (): React.MutableRefObject<HTMLInputElement> => {
  const fieldRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  }, []);

  return fieldRef;
};

export { useAutofocusRef };
