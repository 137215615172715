import * as React from 'react';
import '../../../lib/resize-observer';
import 'intersection-observer';
import { Dialog as DialogComponent } from '@headlessui/react';
import { FComponent } from '../../../types/common';
import { Button } from '../Button';
import classNames from 'classnames';
import { feedbackIcons } from '../Icons';
import { AnimatePresence as AnimatePresenceComponent, motion } from 'framer-motion';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import ReactConfetti from 'react-confetti';

export type ModalProps = {
  title: string;
  description?: string;
  isOpen: boolean;
  toggle?: () => void;
  iconType?: 'success' | 'warning' | 'info';
  withButton?: boolean;
  containerClassname?: string;
  isAnimated?: boolean;
  responsive?: boolean;
  withConfetti?: boolean;
};

const Dialog = withErrorBoundary(DialogComponent, 'Dialog');
const DialogTitle = withErrorBoundary(DialogComponent.Title, 'DialogTitle');
const DialogDescription = withErrorBoundary(
  DialogComponent.Description,
  'DialogDescription'
);
const DialogPanel = withErrorBoundary(DialogComponent.Panel, 'DialogPanel');
const AnimatePresence = withErrorBoundary(AnimatePresenceComponent, 'AnimatePresence');

export type ModalFrameProps = Omit<
  ModalProps,
  'title' | 'description' | 'withButton' | 'iconType' | 'toggle'
> & { toggle: () => void; responsive?: boolean };

export const ModalFrame: FComponent<ModalFrameProps> = ({
  children,
  isOpen,
  className,
  toggle,
  containerClassname,
  responsive = false,
  isAnimated,
  withConfetti = false
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen ? (
        <Dialog
          as="div"
          open={isOpen}
          onClose={toggle}
          className={classNames(
            'dialog',
            responsive ? 'responsive-layout' : 'always-centered'
          )}>
          <div className={classNames('dialog-container', containerClassname)}>
            <ModalOverlay isAnimated={isAnimated} withConfetti={withConfetti} />
            <DialogPanel>
              <span className="dialog-center-trick" aria-hidden="true">
                &#8203;
              </span>

              <ModalContent isAnimated={isAnimated} className={className}>
                {children}
              </ModalContent>
            </DialogPanel>
          </div>
        </Dialog>
      ) : null}
    </AnimatePresence>
  );
};

const Modal: FComponent<ModalProps> = ({
  title,
  description,
  isOpen,
  toggle = () => null,
  withButton = false,
  iconType,
  className = 'dialog-window',
  containerClassname,
  isAnimated = true,
  responsive = true,
  children
}) => {
  if (withButton && !toggle) {
    throw new Error('Must define a toggle function for the modal with default button');
  }

  return (
    <ModalFrame
      isOpen={isOpen}
      className={classNames(className, 'responsive-layout')}
      isAnimated={isAnimated}
      toggle={toggle}
      responsive={responsive}
      containerClassname={classNames(containerClassname)}>
      {iconType ? feedbackIcons[iconType] : null}
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      {description ? (
        <DialogDescription className="dialog-description">
          <span>{description}</span>
        </DialogDescription>
      ) : null}
      {children}
      {withButton ? (
        <div className="single-button">
          <Button onClick={toggle}>Close</Button>
        </div>
      ) : null}
    </ModalFrame>
  );
};

export { Modal };

type ModelMotionProps = {
  isAnimated?: boolean;
  withConfetti?: boolean;
};

const ModalOverlayComponent: FComponent<ModelMotionProps> = ({
  isAnimated = true,
  withConfetti = false,
  children
}) => {
  if (isAnimated) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.1 } }}
        // the delay here allow the overlay to fade in before the contents, feels better than all at once.
        exit={{ opacity: 0, transition: { delay: 0.1, duration: 0.1 } }}
        transition={{ duration: 0.1 }}
        className="dialog-overlay">
        {withConfetti ? (
          <div className="confetti">
            <ReactConfetti
              className="confetti"
              numberOfPieces={600}
              recycle={true}
              gravity={0.05}
            />
          </div>
        ) : null}
        {children}
      </motion.div>
    );
  }

  return <div className="dialog-overlay">{children}</div>;
};

const ModalOverlay = withErrorBoundary(ModalOverlayComponent, 'ModalOverlay');

const ModalContentComponent: FComponent<ModelMotionProps> = ({
  isAnimated = true,
  className,
  children
}) => {
  if (isAnimated) {
    return (
      <motion.div
        key="contents"
        initial={{ opacity: 0, scale: 0.9, y: 100 }}
        // using 0.15 instead of 0.1 here gives it a hmmmpf feel after/before the overlay fades in/out
        animate={{
          opacity: 1,
          scale: 1,
          y: 0,
          transition: { duration: 0.1, delay: 0.15 }
        }}
        exit={{ opacity: 0, scale: 0.8, y: 100, transition: { duration: 0.15 } }}
        transition={{ duration: 0.15, delay: 0.1 }}
        className={className}>
        {children}
      </motion.div>
    );
  }

  return <div className={className}>{children}</div>;
};

const ModalContent = withErrorBoundary(ModalContentComponent, 'ModalContent');

// Modal for special cases with custom non-window type styling.
export const CustomModal: FComponent<ModalFrameProps> = ({
  isOpen,
  className,
  isAnimated = true,
  withConfetti = false,
  toggle = () => null,
  children,
  responsive = false,
  containerClassname
}) => {
  return (
    <ModalFrame
      isOpen={isOpen}
      toggle={toggle}
      className={className}
      responsive={responsive}
      containerClassname={containerClassname}
      isAnimated={isAnimated}
      withConfetti={withConfetti}>
      {children}
    </ModalFrame>
  );
};
