import * as React from 'react';
import { FComponent } from '../../../types/common';
import { ZoomableImage } from '../ZoomableImage';

type ImageCheckboxProps = {
  name: string;
  label?: string;
  value: string;
  imageUrl: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  visited?: boolean;
  errors?: string[];
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  ariaLabel: string;
};

const ImageCheckbox: FComponent<ImageCheckboxProps> = ({
  name,
  label,
  onChange,
  value,
  imageUrl,
  checked = false,
  disabled = false,
  className = 'image-checkbox-container',
  ariaLabel
}) => {
  if (!imageUrl) {
    throw new Error("ImageCheckbox: 'image' is required");
  }
  if (!ariaLabel) {
    throw new Error("ImageCheckbox: 'ariaLabel' is required");
  }

  const checkboxRef = React.useRef<HTMLInputElement>();

  return (
    <div className={className}>
      <div className="image-checkbox-component">
        <input
          ref={checkboxRef}
          id={name}
          name={name}
          value={value}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <label htmlFor={name} className="field-label">
          <ZoomableImage src={imageUrl} alt={label} />
          <div className="styled-checkbox" />
          {label ? <div className="cell-label">{label}</div> : null}
        </label>
      </div>
    </div>
  );
};

export { ImageCheckbox };
