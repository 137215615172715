import * as React from 'react';
import { FComponent } from '../../types/common';
import { DOMAIN } from '../../lib/api';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import CloseIcon from './Icons/CloseIcon';

export const LATEST_CHANGE = {
  version: '2',
  message: (
    <span>
      We have updated our Privacy Policy to include compliance with the EU-U.S. Data
      Privacy Framework, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data
      Privacy Framework. To learn more about these updates and how they may affect you,
      please review our updated{' '}
      <a target="_blank" rel="noreferrer" href={`${DOMAIN}/privacy_policy`}>
        Privacy Policy
      </a>
      .
    </span>
  )
};

export type ChangeNoticeState = {
  currentVersion: string;
  firstSeen: number;
  dismissed: boolean;
};

export const isChangeNoticeState = (obj: unknown): obj is ChangeNoticeState => {
  return (
    obj &&
    typeof obj === 'object' &&
    'currentVersion' in obj &&
    'firstSeen' in obj &&
    'dismissed' in obj &&
    typeof (obj as ChangeNoticeState).currentVersion === 'string' &&
    typeof (obj as ChangeNoticeState).firstSeen === 'number' &&
    typeof (obj as ChangeNoticeState).dismissed === 'boolean'
  );
};

export const ChangeNotice: FComponent = () => {
  const [changeNotice, setChangeNotice] = useLocalStorageState<ChangeNoticeState>(
    'changeNotice',
    {
      currentVersion: '0',
      firstSeen: Date.now(),
      dismissed: false
    },
    isChangeNoticeState
  );

  React.useEffect(() => {
    // Track the first time the user sees this version and dismiss it after one week
    if (changeNotice?.currentVersion !== LATEST_CHANGE.version) {
      setChangeNotice({
        currentVersion: LATEST_CHANGE.version,
        firstSeen: Date.now(),
        dismissed: false
      });
    } else if (Date.now() - changeNotice.firstSeen > 1000 * 60 * 60 * 24 * 7) {
      setChangeNotice(prev => ({
        ...prev,
        dismissed: true
      }));
    }
  }, [changeNotice.firstSeen, changeNotice.currentVersion, setChangeNotice]);

  return changeNotice.dismissed ? null : (
    <div className="change-notice">
      <div className="change-notice-content">
        <div className="change-notice-message">{LATEST_CHANGE.message}</div>
        <button
          className="change-notice-dismiss"
          onClick={() => setChangeNotice(prev => ({ ...prev, dismissed: true }))}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};
