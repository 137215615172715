import * as React from 'react';
import { logger } from '../lib/logger';
import { store } from '../lib/store';
const log = logger('useLocalStorageState');

export const useLocalStorageState = <T>(
  key: string,
  defaultValue?: T | (() => T),
  typeChecker?: (value: unknown) => value is T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = React.useState<T>(() => {
    const value = store.getItem(key);
    let parsedValue: T;
    if (value) {
      try {
        parsedValue = JSON.parse(value);
        if (!typeChecker || typeChecker(parsedValue)) {
          return parsedValue;
        }
      } catch (e) {
        log.exception({
          error: e,
          tags: { key, value, valueType: typeof value, source: 'useLocalStorageState' }
        });
      }
    }
    return defaultValue instanceof Function ? defaultValue() : defaultValue;
  });

  React.useDebugValue(`${key}: ${JSON.stringify(state)}`);

  React.useEffect(() => {
    if (state !== undefined) {
      store.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
};
