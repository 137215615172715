import * as React from 'react';
import { useForm } from '../../hooks/useForm';
import { Validators } from 'tiny-validation';
import { isEmail } from '../../utils/validators';
import type { FComponent, RecursiveToCamel } from '../../types/common';
import { noop } from '../../utils';
import { Button } from '../common/Button';
import { Input } from '../common/Form';
import { api } from '../../lib/api';
import type { CaptchaToken } from './SliderCaptcha';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import { ErrorMessage } from '../common/Form/ErrorMessage';
import { useToggle } from '../../hooks/useToggle';
import { SliderCaptcha } from './SliderCaptcha';
import { Modal } from '../common/Modal';
import { useApiRequest } from '../../hooks/useApiRequest';
const { isPresent } = Validators;

export type UnsubscribeMarketingEmailsRequest = RecursiveToCamel<
  {
    email: string;
  } & CaptchaToken
>;
export type UnsubscribeMarketingEmailsResponse = Record<string, unknown>;

type RequestUnsubscribeMarketingEmailsProps = {
  gcaptchaKey: string;
};

const RequestUnsubscribeMarketingEmails: FComponent<
  RequestUnsubscribeMarketingEmailsProps
> = ({ gcaptchaKey }) => {
  const {
    request: requestUnsubscribeMarketingEmails,
    error: requestError,
    isError: isRequestError
  } = useApiRequest<
    UnsubscribeMarketingEmailsRequest,
    UnsubscribeMarketingEmailsResponse
  >({ method: 'PUT', endpoint: api.unauthenticated.marketingEmailsUnsubscribe });
  const [requestSent, setRequestSent] = React.useState(false);
  const [isCaptchaOpen, toggleCaptcha] = useToggle(false);
  const [captchaError, setCaptchaError] = React.useState(null);
  const captchaTokenRef = React.useRef<CaptchaToken>(null);

  const submit = async (email: string) => {
    return requestUnsubscribeMarketingEmails({
      data: { email, ...captchaTokenRef.current }
    })
      .then(() => setRequestSent(true))
      .catch(noop);
  };

  const { handleSubmit, handleFieldChange, isDisabled, values, isFieldVisited, errors } =
    useForm({
      onSubmit: ({ email }): Promise<void> => submit(email),
      schema: requestUnsubscribeMarketingEmailsSchema,
      initialValues: {
        email: ''
      }
    });

  const handleCaptchaSuccess = (token: CaptchaToken) => {
    captchaTokenRef.current = token;
    toggleCaptcha();
    handleSubmit(null as React.FormEvent<HTMLFormElement>);
  };

  const handleCaptchaFailure = (message = "Could not verify you're not a robot") => {
    toggleCaptcha();
    setCaptchaError(message);
  };

  if (requestSent) return <UnsubscribeRequestSent />;

  return (
    <div className="reset-password app-card-md">
      <form
        onSubmit={event => {
          event.preventDefault();
          toggleCaptcha();
        }}>
        <h1 className="title">Marketing Email Preferences</h1>
        <div className="subtitle">
          Enter your email and we&apos;ll stop sending you marketing emails. You will
          still receive transactional emails from us for notifications and account
          maintenance, including notifications for new bids.
        </div>
        <ErrorMessage
          errors={requestError || [captchaError]}
          isShowing={isRequestError || !!captchaError}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          onChange={handleFieldChange}
          value={values['email']}
          placeholder="peterparker@bugle.net"
          autoCapitalize={false}
          visited={isFieldVisited('email')}
          errors={errors['email']}
        />
        <div className="button-bar">
          <Button type="submit" disabled={isDisabled} className="login-button">
            Unsubscribe
          </Button>
        </div>
      </form>
      <Modal
        className="dialog-custom"
        title=""
        isOpen={isCaptchaOpen}
        toggle={toggleCaptcha}
        isAnimated={false}>
        <SliderCaptcha
          onSuccess={handleCaptchaSuccess}
          onFailure={handleCaptchaFailure}
          gcaptchaKey={gcaptchaKey}
        />
      </Modal>
    </div>
  );
};

const UnsubscribeRequestSent: FComponent = () => (
  <div className="reset-password app-card-md">
    <div className="success-card">
      <h1 className="title">Successfully Unsubscribed!</h1>
      <div className="subtitle">
        If the user email you entered exists in our system, we will no longer send you
        marketing emails.
      </div>
    </div>
  </div>
);

export default withErrorBoundary(
  RequestUnsubscribeMarketingEmails,
  'RequestUnsubscribeMarketingEmails'
);

const requestUnsubscribeMarketingEmailsSchema = {
  email: [isPresent('Enter your email'), isEmail()]
};
