// Sniffs the mime type of a file
// Background: https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
// Adapted from: https://stackoverflow.com/a/42983450/11908555

import { logger } from './logger';

const log = logger('mimesniffer');

export const mimetypes = [
  {
    // "BM" - Bitmap file header
    extension: 'bmp',
    mime: 'image/bmp',
    pattern: [0x42, 0x4d],
    mask: [0xff, 0xff]
  },
  {
    // "GIF" - Graphics Interchange Format header
    extension: 'gif',
    mime: 'image/gif',
    pattern: [0x47, 0x49, 0x46],
    mask: [0xff, 0xff, 0xff]
  },
  {
    // "ÿØÿ" - JPEG file header
    extension: 'jpg',
    mime: 'image/jpeg',
    pattern: [0xff, 0xd8, 0xff],
    mask: [0xff, 0xff, 0xff]
  },
  {
    // "‰PNG" - Portable Network Graphics header
    extension: 'png',
    mime: 'image/png',
    pattern: [0x89, 0x50, 0x4e, 0x47],
    mask: [0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ID3" - MPEG Audio Layer III file header
    extension: 'mp3',
    mime: 'audio/mpeg',
    pattern: [0x49, 0x44, 0x33],
    mask: [0xff, 0xff, 0xff]
  },
  {
    // "ftypMSNV" - MPEG-4 video/QuickTime file header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x4d, 0x53, 0x4e, 0x56],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ftypisom" - ISO Base Media file format header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x6d],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ftypmp41" - MP4 v1 [ISO 14496-1:ch13] file header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x31],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ftypmp42" - MP4 v2 [ISO 14496-14] file header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ftypmp4v" - MP4 v1 [ISO 14496-1:ch13] file header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x76],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // ftypheic - High Efficiency Image Format file header
    extension: 'heic',
    mime: 'image/heic',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x63],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // ftypiso4 - ISO Base Media file format header
    extension: 'mp4',
    mime: 'video/mp4',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x34],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "ftypM4V " - M4V file header
    extension: 'm4v',
    mime: 'video/x-m4v',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x4d, 0x34, 0x56, 0x20],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // BEGIN:VCARD - VCard file header
    extension: 'vcard',
    mime: 'text/x-vcard',
    pattern: [0x42, 0x45, 0x47, 0x49, 0x4e, 0x3a],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // %PDF - PDF file header
    extension: 'pdf',
    mime: 'application/pdf',
    pattern: [0x25, 0x50, 0x44, 0x46],
    mask: [0xff, 0xff, 0xff, 0xff]
  },
  {
    // "RIFFXXXXWEBPVP" - WebP file header
    extension: 'webp',
    mime: 'image/webp',
    pattern: [
      0x52, 0x49, 0x46, 0x46, 0x00, 0x00, 0x00, 0x00, 0x57, 0x45, 0x42, 0x50, 0x56, 0x50
    ],
    mask: [
      0xff, 0xff, 0xff, 0xff, 0x00, 0x00, 0x00, 0x00, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff
    ]
  },
  {
    // "XXXXftypqt  " - QuickTime file header
    extension: 'mov',
    mime: 'video/quicktime',
    offset: 4,
    pattern: [0x66, 0x74, 0x79, 0x70, 0x71, 0x74, 0x20, 0x20],
    mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff]
  },
  {
    // "PK" - ZIP file header
    extension: 'zip',
    mime: 'application/zip',
    pattern: [0x50, 0x4b, 0x03, 0x04],
    mask: [0xff, 0xff, 0xff, 0xff] // Exact match required for "PK\x03\x04"
  },
  {
    // if it doesn't have any headers, we need to identify the beginning of a frame
    // Keep this pattern at the bottom of the array
    extension: 'mp3',
    mime: 'audio/mpeg',
    pattern: [0xff, 0xe0], // Looking for 11111111 111xxxxx
    mask: [0xff, 0xe0] // ignores the lower 5 bits of the second byte
  }
];

const textMimeTypes = [
  { extension: 'csv', mime: 'text/csv' },
  { extension: 'txt', mime: 'text/plain' },
  { extension: 'json', mime: 'application/json' }
];

export type Mimetype = (typeof mimetypes)[0];

const maxPatternLength = Math.max(...mimetypes.map(mime => mime.pattern.length));

const unknownMimeType: Mimetype = {
  extension: undefined,
  mime: 'text/plain',
  pattern: undefined,
  mask: undefined
};

export const getFileType = (file: File): Promise<Mimetype | undefined> =>
  new Promise(resolve => {
    try {
      const blob = file.slice(0, maxPatternLength);
      const reader = new FileReader();

      reader.onloadend = function (e) {
        if (e.target.readyState === FileReader.DONE) {
          const bytes = new Uint8Array(e.target.result as ArrayBuffer);

          for (const mime of mimetypes) {
            if (checkBytes(bytes, mime)) {
              return resolve(mime);
            }
          }

          // For textual types, the extension must match the mime type
          for (const { extension, mime } of textMimeTypes) {
            if (file.type === mime && file.name.endsWith(`.${extension}`)) {
              return resolve({
                extension,
                mime,
                pattern: undefined,
                mask: undefined
              });
            }
          }

          log.exception({
            error: 'No mime pattern matched the header',
            errorType: 'UnknownFileType',
            tags: {
              fileName: file.name,
              header: getStringFromBytes(bytes),
              fileType: file.type
            }
          });

          return resolve(unknownMimeType);
        }
      };
      reader.readAsArrayBuffer(blob);
    } catch (e) {
      log.exception({ error: e, tags: { fileName: file.name } });
      return resolve(unknownMimeType);
    }
  });

const checkBytes = (bytes: Uint8Array, mime: Mimetype) => {
  const { pattern, mask, offset = 0 } = mime;
  const b = bytes.slice(offset, offset + mask.length);
  for (let i = 0; i < mask.length; i++) {
    if ((b[i] & mask[i]) !== pattern[i]) {
      return false;
    }
  }
  return true;
};

const getStringFromBytes = (bytes: Uint8Array) =>
  Array.prototype.map
    .call(bytes || [], (char: number) => String.fromCharCode(char))
    .join('');
